<template>
    <!-- 送货记录单详情 -->
    <el-container class="table-parent">
        <!-- 表头 -->
        <el-header class="table-header" style="height:84px">
            <el-row style="text-align: left;padding-left:22px;margin-top:5px">
                <el-col :span="24">
                    <el-button size="mini" type="primary" icon="el-icon-printer" @click="printBrowsOrder('arrival', 'Arrival', 'print')">打印</el-button>
                    <el-button size="mini" type="success" icon="el-icon-s-order" @click="printBrowsOrder('arrival', 'Arrival', 'preview')">浏览</el-button>
                </el-col>
            </el-row>
            <el-row style="margin-top:0px">
                <el-col :span="8" style="padding-left:22px">
                    <p style="text-align: left;height: 18px;line-height: 18px;margin-top:5px;margin-bottom:5px;">送货单号：{{deliveryDet.cCode}}</p>
                    <p style="text-align: left;height: 18px;line-height: 18px;margin-top:5px;margin-bottom:5px;">快递单号：{{deliveryDet.cDefine14}}</p>
                </el-col>
                <el-col :span="8" style="padding-left:22px">
                    <p style="text-align: left;height: 18px;line-height: 18px;margin-top:5px;margin-bottom:5px;">送货日期：{{deliveryDet.dDate}}</p>
                    <p style="text-align: left;height: 18px;line-height: 18px;margin-top:5px;margin-bottom:5px;">备注：{{deliveryDet.cMemo}}</p>
                </el-col>
                <el-col :span="8" style="padding-left:22px">
                    <p style="text-align: left;height: 18px;line-height: 18px;margin-top:5px;margin-bottom:5px;">供应商：{{deliveryDet.cVenName}}</p>
                    <p style="text-align: left;height: 18px;line-height: 18px;margin-top:5px;margin-bottom:5px;">是否审核：<el-tag size="mini" :type="isExamine==false?'danger':'success'">{{isExamine==false?'否':'是'}}</el-tag></p>
                </el-col>
            </el-row>
        </el-header>
        <el-main class="table-body">
            <!-- 送货单详情 -->
            <el-main class="delivery-order">
                <el-table
                    ref="singleTable"
                    :data="deliveryData"
                    :height="singleTableHeight"
                    border
                    class="deliDet-table"
                    size="mini"
                    v-loading="loading"
                    element-loading-text="正在加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                    highlight-current-row
                    @current-change="handleCurrentChange"
                    style="width: 100%">
                    <el-table-column
                    type="index"
                    show-overflow-tooltip
                    min-width="45px">
                    </el-table-column>
                    <el-table-column
                    property="ivouchrowno"
                    label="行号"
                    show-overflow-tooltip
                    min-width="45px">
                    </el-table-column>
                    <el-table-column
                    property="cOrderCode"
                    show-overflow-tooltip
                    :label="deliveryDet.cBusType=='普通采购'?'采购单号':'委外单号'"
                    sortable
                    min-width="100px">
                    </el-table-column>
                    <el-table-column
                    property="cInvCode"
                    label="存货编码"
                    sortable
                    show-overflow-tooltip
                    min-width="100px">
                    </el-table-column>
                    <el-table-column
                    property="cInvName"
                    label="存货名称"
                    sortable
                    show-overflow-tooltip
                    min-width="100px">
                    </el-table-column>
                    <el-table-column
                    property="cInvStd"
                    show-overflow-tooltip
                    label="规格型号"
                    sortable
                    min-width="100px">
                    </el-table-column>
                    <el-table-column
                    property="iQuantity"
                    show-overflow-tooltip
                    label="发货数量"
                    sortable
                    min-width="100px">
                    </el-table-column>
                    <el-table-column
                    property="bgift"
                    show-overflow-tooltip
                    label="赠品"
                    min-width="50px">
                        <template slot-scope="scope">
                            <el-tag size="mini" :type="scope.row.bgift==1?'':'success'">{{scope.row.bgift==true?'是':'否'}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                    property="printQty"
                    show-overflow-tooltip
                    label="已生成数量"
                    sortable
                    min-width="100px">
                    </el-table-column>
                    <el-table-column
                    property="cComUnitName"
                    show-overflow-tooltip
                    label="单位"
                    min-width="50px">
                    </el-table-column>
                    <el-table-column
                    property="cBatch"
                    show-overflow-tooltip
                    label="批号"
                    sortable
                    min-width="100px">
                    </el-table-column>
                    <el-table-column
                    property="dPDate"
                    show-overflow-tooltip
                    label="生产日期"
                    sortable
                    min-width="100px">
                    </el-table-column>
                    <el-table-column
                    property="dVDate"
                    show-overflow-tooltip
                    label="失效日期"
                    sortable
                    min-width="100px">
                    </el-table-column>
                    <el-table-column
                    property="cbMemo"
                    show-overflow-tooltip
                    label="备注"
                    min-width="100px">
                    </el-table-column>
                </el-table>
            </el-main>
            <!-- 打包数量 -->
            <el-main class="pack-number" v-if="selectInventory.cInvCode!=''" style="padding-left:22px;padding-right:22px;margin-top:5px;">
                <el-form :inline="true" :model="selectInventory" class="packing-quantity">
                    <el-row class="pack-row">
                        <el-col :span="8" class="pack-row">
                            <el-form-item label="行号：">
                                {{selectInventory.cSourceRowNo}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="5" class="pack-row">
                            <el-form-item label="存货编码：">
                                {{selectInventory.cInvCode}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" class="pack-row">
                            <el-form-item label="存货名称：">
                                {{selectInventory.cInvName | cInvCodeEllipsis}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="5" class="pack-row" style="text-align: center;">
                            <el-button size="mini" v-if="venUser.iAdmin!=1" type="primary" :disabled="isExamine" icon="el-icon-edit" @click="generateBarcode" style="margin-top:5px">生成条码</el-button>
                            <el-button size="mini" type="warning" icon="el-icon-printer" @click="openPrintBarcode('Label', 'BarcodeFree', 'print')" style="margin-left:10px;margin-top:5px">条码打印</el-button>
                        </el-col>
                    </el-row>
                    <el-row class="pack-row">
                        <el-col :span="8" class="pack-row">
                            <el-form-item label="规格型号：" class="title-bable">
                                {{selectInventory.cInvStd | cInvStdEllipsis}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="5" class="pack-row" >
                            <el-form-item label="未包装数量：">
                                {{selectInventory.unpackedQuantity}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<el-tag size="mini" type="danger">{{selectInventory.cUnit}}</el-tag>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" class="pack-row">
                            <el-form-item label="每包包装数量：">
                                <el-input-number size="mini" v-model="selectInventory.iQuantity" :min="0" :max="selectInventory.unpackedQuantity" label="本次包装数量："></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5" class="pack-row" style="text-align: center;">
                            <el-popconfirm title="确定要删除？" @confirm="delBatchBarcode">
                                <el-button size="mini" v-if="venUser.iAdmin!=1" type="primary" :disabled="isExamine" icon="el-icon-document-delete" style="margin-top:5px" slot="reference">删除条码</el-button>
                            </el-popconfirm>
                            <el-button size="mini" type="warning" icon="el-icon-s-order" @click="openPrintBrowsBarcode('Label', 'BarcodeFree', 'preview')" style="margin-left:10px;margin-top:5px">条码浏览</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </el-main>
            <!-- 生成条码展示区域 -->
            <el-main class="generate-bar-code" v-if="selectInventory.cInvCode!=''&&BarCodeData.length!=0" style="height:33%-100px">
                <el-table
                    ref="checkTable"
                    :data="BarCodeData.slice((queryInline.pageIndex-1)*queryInline.pageSize,queryInline.pageIndex*queryInline.pageSize)"
                    :height="generateTableHeight"
                    border
                    size="mini"
                    @selection-change="handleSelectionChange($event)"
                    style="width: 100%">
                    <el-table-column
                    type="selection"
                    min-width="5%">
                    </el-table-column>
                    <el-table-column
                    type="index"
                    width="50"
                    min-width="5%">
                    </el-table-column>
                    <el-table-column
                    property="autoid"
                    label="序号"
                    show-overflow-tooltip
                    min-width="5%">
                    </el-table-column>
                    <el-table-column
                    property="cBarcode"
                    label="条码"
                    show-overflow-tooltip
                    min-width="45%">
                    </el-table-column>
                    <el-table-column
                    property="iQuantity"
                    label="数量"
                    show-overflow-tooltip
                    min-width="10%">
                        <template slot-scope="scope">
                            <span v-if="scope.row.setState">
                                <el-input-number size="mini" :disabled="!scope.row.setState" v-model="scope.row.iQuantity" controls-position="right" :min="0" :max="selectInventory.unpackedQuantity+scope.row.restQuantity" style="width:100px"></el-input-number>
                            </span>
                            <span v-else>{{scope.row.iQuantity}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="20%">
                        <template slot-scope="scope">
                            <el-button type="primary" v-if="venUser.iAdmin!=1" :disabled="isExamine" :icon="!scope.row.setState?'el-icon-edit':'el-icon-edit-outline'" size="mini" @click="!scope.row.setState?edit(scope.row,scope.$index):updChange(scope.row,scope.$index)" style="margin-right:10px">
                                {{!scope.row.setState?'编辑':"修改"}}
                            </el-button>
                            <el-popconfirm :title="scope.row.setState?'确定要取消？':'确定要删除？'" @confirm="!scope.row.setState?delChange(scope.row,scope.$index):cancelChange(scope.row,scope.$index)">
                                <el-button size="mini" v-if="venUser.iAdmin!=1" :disabled="isExamine" :icon="!scope.row.setState?'el-icon-document-delete':'el-icon-caret-left'" :type="!scope.row.setState?'danger':'warning'" slot="reference">
                                    {{!scope.row.setState?'删除':"取消"}}
                                </el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页查询区 -->
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                    :current-page="this.queryInline.pageIndex"
                    :page-sizes="this.queryInline.pageSizes"
                    :page-size="this.queryInline.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="this.queryInline.total">
                </el-pagination>
            </el-main>
        </el-main>
    </el-container>
</template>

<script>
import {webapp_ws_ajax_run} from '../../../static/js/grwebapp'
// import {css} from '../../static/css.css'
// import {Divs} from '../../static/js/Divs.js'
export default {
    props:["delivery"],
    data(){
        return{
            deliveryDet:{},//要查询的送货记录对象
            deliveryData: [],//送货记录详情数据
            //选中的数据
            selectInventory: {
                cSourceDetailId:'',//采购到货单子表标识
                partnerName:'',//供应商名称
                partnerCode:'',//供应商编码
                cInvCode:'',//存货编码
                cInvName:'',//存货名称
                cInvStd:'',//规格型号
                unpackedQuantity:'',//未包装数量
                iQuantity:'',//本次包装数量
                cUnit:'',//单位
                cBatch:'',//批号
                cSourceCode:'',//采购到货单号
                cSourceRowNo:'',//单据行号
                dPRODate:'',//生产日期
                cSourceId:'',//采购到货单主表标识
            },
            oneSet:false,//是否有一个条码处在修改状态的属性
            BarCodeData:[],//根据选中存货查询到的对应条码数组
            selectDelivery:{},//选中的送货记录对象（用于生成条码后重新刷新条码）
            selectBarcode:[],//选中的条形码数组
            ipStr:'',//重新组装的U8接口Ip地址
            //条码分页参数
            queryInline:{
                pageSizes:[10, 50, 100],//页面容量数组
                pageSize:10,//每页容量
                pageIndex:1,//当前页码
                total:0//查询结果总量
            },
            venUser:{},//登录的供应商对象
            isExamine:false,//送货记录是否已经被审核
            loading:false,//是否显示加载界面的属性
        }
    },
    filters: {
        // 存货名称内容显示15位，超过15位显示...
        cInvCodeEllipsis:function(value){
            if (!value) return "";
            if (value.length > 15) {
                return value.slice(0, 15) + "...";
            }
            return value;
        },
        // 存货规格内容显示25位，超过25位显示...
        cInvStdEllipsis: function(value) {
            if (!value) return "";
            if (value.length > 35) {
                return value.slice(0, 35) + "...";
            }
            return value;
        }
    },
    computed:{
        //计算送货单详情数据区域自适应高度
        singleTableHeight(){
            return document.documentElement.clientHeight/2-200;
        },
        //计算条码数据区域自适应高度
        generateTableHeight(){
            return document.documentElement.clientHeight/2-130;
        }
    },
    created(){
        this.deliveryDet={},//先清空之前查询到的送货记录对象
        this.deliveryDet=this.delivery;//设置要查询的送货记录对象
        //根据采购送货单主表标识查找送货记录详情
        this.getDeliveryDet();
        //查询该到货记录是否已经审核
        this.checkDeliveryExamine();
        //获取java配置文件中c#的U8接口地址
        this.getU8Address();
        //调用设置查询中供应商的参数
        this.setVenUser();
    },
    methods: {
        /**
         * 设置查询中供应商的参数
         */
        setVenUser(){
            //获取上级父组件传进来的参数并赋值
            this.venUser=this.$store.state.UserToken;//路由中获取到的是json字符串，要转化为json对象
        },
        /**
         * 查询该到货记录是否已经审核
         */
        checkDeliveryExamine(){
            let that=this;
            let queryDeliveryParam={
                cCode:this.deliveryDet.cCode,//采购到货单号
            }
            this.$axios.post("/u8/delivery/checkDeliveryExamine",queryDeliveryParam)
            .then((resp)=>{
                console.log('查询是否审核返回：',resp);
                if(resp.data.success=="true"){
                    //如果返回的审核标识是0或者是null
                    if(resp.data.data.iverifystateex==0||resp.data.data.iverifystateex==null){
                        this.isExamine=false;//标识未审核
                    }else{//如果返回的审核标识是2
                        this.isExamine=true;//标识已经审核
                    }
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('查询到货记录审核状态服务器出错了');
                    that.$message.error('查询到货记录审核状态服务器出错了');
                }
            );
        },
        /**
         * 获取java配置文件中c#的U8接口地址
         */
        getU8Address(){
            this.$axios.post("/u8/delivery/getU8Address")
            .then((resp)=>{
                this.ipStr=resp.data.msg;//设置根据java配置文件获取到的u8地址
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('读取U8接口服务器出错了');
                    this.$message('读取U8接口服务器出错了')
                }
            );
        },
        /**
         * 打印浏览送货记录详情的方法（主表浏览）
         * @param report 报表文件名称
         * @param data
         * @param type 打印或者浏览的参数（preview：浏览；print：打印）
         */
        printBrowsOrder(report, data, type){
            var args = {
                type: type
            };
            // var arrId = document.getElementById('arrId').value;
            if (data) {
                // args.report = "http://192.168.16.103:8000/grf/"+report+".grf";
                // args.data = "http://192.168.16.103:8000/data/DataCenter.ashx?data="+data+"&ID="+this.deliveryDet.id;
                args.report = this.ipStr+"/grf/"+report+".grf";
                args.data = this.ipStr+"/data/DataCenter.ashx?data="+data+"&ID="+this.deliveryDet.id;
                webapp_ws_ajax_run(args);
            }
            else {
                args.report = "grf/" + report + ".grf";
                args.data = "data/SQLParam.ashx";
                args.baseurl = window.rootURL;
                args.selfsql = true;
                webapp_ws_run(args);
            }
            // if (data) {
            //     args.report = "http://192.168.16.184:8000/grf/"+report+".grf";
            //     args.data = "http://192.168.16.184:8000/data/DataCenter.ashx?data="+data+"&ID="+this.deliveryDet.id;
            //     webapp_ws_ajax_run(args);
            // }
            // else {
            //     args.report = "grf/" + report + ".grf";
            //     args.data = "data/SQLParam.ashx";
            //     args.baseurl = window.rootURL;
            //     args.selfsql = true;
            //     webapp_ws_run(args);
            // }
        },
        /**
         * 打开浏览条码打印的方法（浏览）
         * @param report 报表文件名称
         * @param data
         * @param type 打印或者浏览的参数（preview：浏览；print：打印）
         */
        openPrintBrowsBarcode(report, data, type){
            if(this.oneSet==true){
                this.$message('请先保存当前修改的条码');
            }else{
                if(this.selectBarcode.length==0){
                    this.$message('请选择条码');
                }else{
                    this.printBrowsBarcode(report, data, type)
                }
            }
        },
        /**
         * 打开打印条码的方法（打印）
         * @param report 报表文件名称
         * @param data
         * @param type 打印或者浏览的参数（preview：浏览；print：打印）
         */
        openPrintBarcode(report, data, type){
            if(this.selectBarcode.length==0){
                this.$message('请选择条码');
            }else{
                if(this.oneSet==true){
                    this.$message('请先保存当前修改的条码');
                }else{
                    //调用打印浏览送货记录详情的方法（打印）
                    this.printBrowsBarcode(report, data, type)
                }
                
            }
        },
        /**
         * 打印浏览送货记录详情的方法（打印）
         * @param report 报表文件名称
         * @param data
         * @param type 打印或者浏览的参数（preview：浏览；print：打印）
         */
        printBrowsBarcode(report, data, type){
            var args = {
                type: type
            };
            let txt='';
            for (let i = 0; i < this.selectBarcode.length; i++) {
                if (txt != ''){
                    txt += ',';
                }  
                txt += "'" + this.selectBarcode[i].autoid + "'";
            }
            if (data) {
                // args.report = "http://192.168.16.103:8000/grf/"+report+".grf";
                // args.data = "http://192.168.16.103:8000/data/DataCenter.ashx?data="+data+"&ID=" + txt;
                args.report = this.ipStr+"/grf/"+report+".grf";
                args.data = this.ipStr+"/data/DataCenter.ashx?data="+data+"&ID=" + txt;
                webapp_ws_ajax_run(args);
            }
            else {
                args.report = "grf/" + report + ".grf";
                args.data = "data/SQLParam.ashx";
                args.baseurl = window.rootURL;
                args.selfsql = true;
                webapp_ws_run(args);
            }
            // if (data) {
            //     args.report = "http://192.168.16.184:8000/grf/"+report+".grf";
            //     args.data = "http://192.168.16.184:8000/data/DataCenter.ashx?data="+data+"&ID=" + txt;
            //     webapp_ws_ajax_run(args);
            // }
            // else {
            //     args.report = "grf/" + report + ".grf";
            //     args.data = "data/SQLParam.ashx";
            //     args.baseurl = window.rootURL;
            //     args.selfsql = true;
            //     webapp_ws_run(args);
            // }
        },
        /**
         * 根据采购送货单主表标识查找送货记录详情
         */
        getDeliveryDet(){
            //清空之前查询到的数据
            this.deliveryData=[];
            let queryDeliveryDetParam={
                id:this.deliveryDet.id//采购送货单主表标识
            }
            this.loading=true;//打开加载界面
            this.$axios.post("/u8/delivery/getDeliveryDet",queryDeliveryDetParam)
            .then((resp)=>{
                //送货记录详情数据
                this.deliveryData=resp.data.data;
                this.loading=false;//关闭加载界面
            })
            .catch(
                function(error){
                    console.log(error)
                    console.log('采购订单服务器出错了')
                    this.$message('采购订单服务器出错了')
                }
            );
        },
        /**
         * 清空选中存货的方法
         */
        clearSelectInventory(){
            this.selectInventory.cSourceDetailId='';//采购到货单子表标识
            this.selectInventory.partnerName='';//供应商名称
            this.selectInventory.partnerCode='';//供应商编码
            this.selectInventory.cInvCode='';//存货编码
            this.selectInventory.cInvName='';//存货名称
            this.selectInventory.cInvStd='';//规格型号
            this.selectInventory.unpackedQuantity='';//未包装数量
            this.selectInventory.iQuantity='';//本次包装数量
            this.selectInventory.cUnit='';//单位
            this.selectInventory.cBatch='';//批号
            this.selectInventory.cSourceCode='';//采购到货单号
            this.selectInventory.cSourceRowNo='';//单据行号
            this.selectInventory.dPRODate='';//生产日期
            this.selectInventory.dVDate='';//失效日期
            this.selectInventory.cSourceId='';//采购到货单主表标识
        },
        /**
         * 选中存货的方法
         */
        handleCurrentChange(val) {
            if(val==null){//如果选中的存货为空，则直接返回空
                return;
            }
            this.selectInventory.cSourceDetailId=val.autoid;//采购到货单子表标识
            this.selectInventory.partnerName=this.deliveryDet.cVenName;//供应商名称
            this.selectInventory.partnerCode=this.deliveryDet.cVenCode;//供应商编码
            this.selectInventory.cInvCode=val.cInvCode;//存货编码
            this.selectInventory.cInvName=val.cInvName;//存货名称
            this.selectInventory.cInvStd=val.cInvStd;//规格型号
            this.selectInventory.unpackedQuantity=val.iQuantity-val.printQty;//未包装数量(发货数量-已生成数量)
            this.selectInventory.cUnit=val.cComUnitName;//单位
            this.selectInventory.cBatch=val.cBatch;//批号
            this.selectInventory.cSourceCode=this.deliveryDet.cCode;//采购到货单号
            this.selectInventory.cSourceRowNo=val.ivouchrowno;//单据行号
            this.selectInventory.dPRODate=val.dPDate;//生产日期
            this.selectInventory.dVDate=val.dVDate;//失效日期
            this.selectInventory.cSourceId=val.cSourceId;//采购到货单主表标识
            console.log('选中的是：',this.selectInventory);
            //调用向后端根据查询采购到货单子表标识查询对应的条码数组的方法
            this.toQueryBarCode(this.selectInventory);
        },
        /**
         * 向后端根据查询采购到货单子表标识查询对应的条码数组
         */
        toQueryBarCode(selectInventory){
            //清空之前选中货物的条码数组
            this.BarCodeData=[];
            this.queryInline.total=0;//清空之前查询到的条码数据
            this.queryInline.pageIndex=1;//重置当前页为1
            let queryBarCodeParam={
                cSourceDetailId:selectInventory.cSourceDetailId,//采购到货单子表标识
                cInvCode:selectInventory.cInvCode,//存货编码
                cSourceRowNo:selectInventory.cSourceRowNo,//到货子表单据行号
            }
            this.$axios.post("/u8/barcodeFree/getBarCode",queryBarCodeParam)
            .then((resp)=>{
                if(resp.data.success=="false"){
                    this.$message(resp.data.msg);
                }
                if(resp.data.success=="true"){
                    this.BarCodeData=resp.data.data;//设置查询到的条码集合
                    this.queryInline.total=resp.data.data.length;//条码总数
                }
            })
            .catch(
                function(error){
                    console.log(error)
                    console.log('采购订单服务器出错了')
                    this.$message('采购订单服务器出错了')
                }
            );
        },
        /**
         * 生成条码
         */
        generateBarcode(){
            if(this.selectInventory.unpackedQuantity==0){
                return this.$message('该存货已经生成完条码');
            }
            if(this.selectInventory.iQuantity==''){
                this.$message('请输入本次条码每包包装数量');
            }else{
                if(this.oneSet==true){
                    this.$message('请先保存当前修改的条码');
                }else{
                    //打开加载页面
                    const loading = this.$loading({
                        lock: true,
                        text: '生成条码中，请稍后....',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    this.$axios.post("/u8/barcodeFree/addBarcode",this.selectInventory)
                    .then((resp)=>{
                        this.$message(resp.data.msg);
                        if(resp.data.success=="true"){
                            //未打包数量设置为0
                            this.selectInventory.unpackedQuantity=0;
                            //本次打包数量设置为0
                            this.selectInventory.iQuantity=0;
                            //重新调用根据采购送货单主表标识查找送货记录详情
                            this.getDeliveryDet();
                            //重新调用根据查询采购到货单子表标识查询对应的条码数组的方法
                            this.toQueryBarCode(this.selectInventory);
                            //关闭加载页面
                            loading.close();
                        }
                    })
                    .catch(
                        function(error){
                            console.log(error);
                            console.log('采购订单服务器出错了');
                            this.$message('采购订单服务器出错了');
                        }
                    );
                }
            }
        },
        /**
         * 打开编辑条码的方法
         */
        edit(row){
            if(this.oneSet==true){
                this.$message('请保存当前修改的条码');
            }else{
                row.setState=true;
                this.oneSet=true;//设置已经有一个条码处在修改状态
            }
            
        },
        /**
         * 修改条码的方法
         */
        updChange(row){
            this.$axios.post("/u8/barcodeFree/updBarCode",row)
            .then((resp)=>{
                this.$message(resp.data.msg);
                if(resp.data.success=="true"){
                    if(row.restQuantity>row.iQuantity){//如果用于重置打包的数量大于修改后的打包数量
                        //该存货的未打包数量要加上减少了的打包数量
                        this.selectInventory.unpackedQuantity=this.selectInventory.unpackedQuantity+(row.restQuantity-row.iQuantity);
                    }else{//如果用于重置打包的数量小于修改后的打包数量
                        //该存货的未打包数量要减去增加了的打包数量
                        this.selectInventory.unpackedQuantity=this.selectInventory.unpackedQuantity-(row.iQuantity-row.restQuantity);
                    }
                    //用于重置打包的数量设置为更改后的打包数量
                    row.restQuantity=row.iQuantity;
                    row.setState=false;
                    this.oneSet=false;//设置有一条条码处在修改状态为关闭
                    //重新调用根据采购送货单主表标识查找送货记录详情
                    this.getDeliveryDet();
                    //重新调用根据查询采购到货单子表标识查询对应的条码数组的方法
                    this.toQueryBarCode(this.selectInventory);
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('采购订单服务器出错了');
                    this.$message('采购订单服务器出错了');
                }
            );
        },
        /**
         * 取消修改条码的方法
         */
        cancelChange(row){
            row.iQuantity=row.restQuantity;//重置条码打包的数量
            row.setState=false;
            this.oneSet=false;//设置有一条条码处在修改状态为关闭
        },
        /**
         * 删除条码的方法
         */
        delChange(row){
            if(this.oneSet==true){
                this.$message('请先保存当前修改的条码');
            }else{
                this.$axios.post("/u8/barcodeFree/delBarCode",row)
                .then((resp)=>{
                    this.$message(resp.data.msg);
                    if(resp.data.success=="true"){
                        //该存货的未打包数量要加上删除的条码打包数量
                        this.selectInventory.unpackedQuantity=this.selectInventory.unpackedQuantity+row.iQuantity;
                        //重新根据采购到货单子表标识查询对应的条码集合
                        this.toQueryBarCode(this.selectInventory);
                        if(this.BarCodeData.length==1){
                            this.BarCodeData=[];
                        }
                    }
                    //重新调用根据采购送货单主表标识查找送货记录详情
                    this.getDeliveryDet();
                    //重新调用根据查询采购到货单子表标识查询对应的条码数组的方法
                    this.toQueryBarCode(this.selectInventory);
                })
                .catch(
                    function(error){
                        console.log(error);
                        console.log('采购订单服务器出错了');
                        this.$message('采购订单服务器出错了');
                    }
                );
            }
        },
        /**
         * 多选条码的方法
         */
        handleSelectionChange(val,index){
            this.selectBarcode=val;//设置选中的条码数组
        },
        /**
         * 批量删除条码
         */
        delBatchBarcode(){
            if(this.selectBarcode.length==0){
                this.$message('请选中要删除的条码');
            }else{
                if(this.oneSet==true){
                    this.$message('请先保存当前修改的条码');
                }else{
                    let params={
                        delBarCodeParamList:this.selectBarcode//选中的条码集合
                    }
                    //打开加载页面
                    const loading = this.$loading({
                        lock: true,
                        text: '删除条码中，请稍后....',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    this.$axios({
                        url:'/u8/barcodeFree/delBatchBarcode',
                        method: 'post',
                        contentType: "application/json; charset=utf-8",
                        dataType: "json",
                        data: params
                    })
                    .then((resp)=>{
                        this.$message(resp.data.msg);
                        if(resp.data.success=="true"){
                            for (let i = 0; i < this.selectBarcode.length; i++) {
                                //把每个删除的条码打包数量重新加回到未打包数量上
                                this.selectInventory.unpackedQuantity=this.selectInventory.unpackedQuantity+this.selectBarcode[i].iQuantity
                            }
                            //重新根据采购到货单子表标识查询对应的条码集合
                            this.toQueryBarCode(this.selectInventory);
                            this.selectBarcode=[];//如果批量删除条码成功，则设置选中的条码数组为空数组
                            if(params.delBarCodeParamList.length==this.BarCodeData.length){
                                this.BarCodeData=[];
                            }
                        }
                        //重新调用根据采购送货单主表标识查找送货记录详情
                        this.getDeliveryDet();
                        //重新调用根据查询采购到货单子表标识查询对应的条码数组的方法
                        this.toQueryBarCode(this.selectInventory);
                        //关闭加载页面
                        loading.close();
                    })
                    .catch(
                        function(error){
                            console.log(error);
                            console.log('采购订单服务器出错了');
                            this.$message('采购订单服务器出错了');
                        }
                    );
                }
            }
        },
         //分页查询
        /**
         * 改变每页显示的数量
         */
        handleSizeChange(val) {
            this.queryInline.pageSize=val;//设置每页容量
            this.queryInline.pageIndex=1;//改总量后，初始页面改为1
        },
        /**
         * 改变开始页码
         */
        handlePageChange(val) {
            this.queryInline.pageIndex=val;//设置当前页码
        },
    }
}
</script>

<style>

    /* 解决标题和内容单元格错位 */
    body .el-table th.gutter {
        display: table-cell!important;
    }
    /* 页面外框整体样式 */
    .table-parent{
        height: 100%;
    }

    /* 窗口内框的样式 */
    .el-dialog__body{
        padding:0px 20px;
    }

    /* 表头样式 */
    .table-header{
        height: 25%;
        padding:0px;
        /* background-color: blue; */
    }

    /* 表体样式 */
    .table-body{
        height: 75%;
        margin: 5px;
        padding: 0px;
        /* background-color: yellow; */
    }
    /* 送货单详情 */
    .delivery-order{
        height: 33%;
        padding: 0px 20px;
        /* background-color: pink; */
    }

    /* 送货详情边框线 */
    .deliDet-table{
        border: 1px solid rgb(235,238,245);
    }

    /* 打包数量 */
    .pack-number{
        /* height: 33%; */
        width: 100%;
        padding:0px;
        /* background-color: chartreuse; */
    }
    /* 打包数量表单样式 */
    .packing-quantity{
        height: 75px;
        width: 100%;
        color: rgb(255,255,255);
        background-color: rgb(84,92,100);
    }
    /* 打包框的每一行样式 */
    .pack-row{
        height:35px;
        text-align: left;
        padding-left: 20px;
    }
    /* 打包框每一行内容样式 */
    .pack-row .el-form-item__label{
        height: 35px;
        color: rgb(255,255,255);
    }
    /* 生成条码 */
    .generate-bar-code{
        height: 33%;
        padding-top:5px;
        padding-bottom:0px;
        /* background-color: grey; */
    }
</style>