<template>
    <!-- 发票对账单页（融贤专版） -->
    <el-container>
        <!-- 查询条件区域 -->
        <el-header style="height:40px">
            <el-form :inline="true" :model="queryInvoiceParam" class="demo-form-inline">
                <el-row style="height:40px;">
                    <el-col :span="24" class="query-col">
                        <el-form-item label="日期范围">
                            <el-date-picker
                                v-model="queryInvoiceParam.dateRange"
                                type="daterange"
                                align="right"
                                unlink-panels
                                size="mini"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                style="width:220px"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                        <el-button type="warning" icon="el-icon-refresh" size="mini" @click="restQuery()" style="margin-top:5px;">重置</el-button>
                        <el-button type="primary" icon="el-icon-search" size="mini" @click="queryInvoiceCheck()" style="margin-top:5px;">查询</el-button>
                        <el-button @click="drawer = true" size="mini" type="primary" style="margin-left: 16px;margin-top:6px">导出excel表格</el-button>
                        <el-drawer
                            title="导出excel页"
                            :visible.sync="drawer"
                            :with-header="false"
                            size="340px">
                            <h3 style="text-align: center;margin-top:30px">导出excel表格</h3>
                            <el-input size="mini" placeholder="当前页excel表格名称" v-model="excelName" style="width:300px;margin-top:30px;margin-left:20px">
                                <el-button size="mini" type="primary" slot="append" icon="el-icon-download" @click="exportOneExcel()">导出当前页excel</el-button>
                            </el-input>
                            <el-input size="mini" placeholder="全部内容excel表格名称" v-model="excelAllName" style="width:300px;margin-top:30px;margin-left:20px">
                                <el-button size="mini" type="primary" slot="append" icon="el-icon-download" @click="exportAllExcel()">导出全部excel</el-button>
                            </el-input>
                        </el-drawer>
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <el-main class="invoice-check-show">
            <el-table
                :data="invoiceCheckDatas.slice((queryInvoiceParam.pageIndex-1)*queryInvoiceParam.pageSize,queryInvoiceParam.pageIndex*queryInvoiceParam.pageSize)"
                stripe
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :height="tableHeight"
                border
                id="invoiceCheck-table"
                ref="invoiceCheckTable"
                size="mini"
                @selection-change="handleSelInvoiceCheckChange"
                style="width: 100%;margin-bottom:5px"
                class="invoiceCheckShow">
                <el-table-column
                    type="index"
                    min-width="50px">
                </el-table-column>
                <el-table-column
                    prop="dDate"
                    label="入库日期"
                    sortable
                    show-overflow-tooltip
                    min-width="95px">
                </el-table-column>
                <el-table-column
                    prop="cDefine10"
                    label="送货单号"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="cordercode"
                    label="采购订单号"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="cinvcode"
                    label="物料编码"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="cinvname"
                    label="物料名称"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="cinvstd"
                    label="物料规格"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="cinvmUnit"
                    label="单位"
                    sortable
                    show-overflow-tooltip
                    min-width="70px">
                </el-table-column>
                <el-table-column
                    prop="ipbvquantity"
                    label="数量"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="ioritaxcost"
                    label="单价"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="isum"
                    label="金额"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="ipbvtaxrate"
                    label="税率"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="dpbvdate"
                    label="会计月"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="dpbvdate"
                    label="发票日期"
                    sortable
                    show-overflow-tooltip
                    min-width="95px">
                </el-table-column>
                <el-table-column
                    prop="cvencode"
                    label="供应商编码"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="cvenabbname"
                    label="供应商"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="cvenperson"
                    label="供应商联系人"
                    sortable
                    show-overflow-tooltip
                    min-width="120px">
                </el-table-column>
                <el-table-column
                    prop="cvenphone"
                    label="供应商电话"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="cvenpuomprotocolname"
                    label="付款协议"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="ccode"
                    label="入库单号"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="cbmemo"
                    label="备注"
                    sortable
                    show-overflow-tooltip
                    min-width="110px">
                </el-table-column>
                <el-table-column
                    prop="cdefine37"
                    label="计划到货日期"
                    sortable
                    show-overflow-tooltip
                    min-width="120px">
                </el-table-column>
            </el-table>
            <!-- 分页查询区 -->
            <el-pagination
                @size-change="handleInvoiceCheckSizeChange"
                @current-change="handleCurrentInvoiceCheckChange"
                :current-page="this.queryInvoiceParam.pageIndex"
                :page-sizes="this.queryInvoiceParam.pageSizes"
                :page-size="this.queryInvoiceParam.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.queryInvoiceParam.total">
            </el-pagination>
        </el-main>
    </el-container>
</template>

<script>
export default {
    data(){
        return{
            //日历快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            venUser:{},//登录的供应商对象
            //查询发票对账单参数
            queryInvoiceParam:{
                dateRange:[],//日期范围
                pageSizes:[10,,20, 50, 100, 200,300],//页面容量数组
                pageSize:20,//每页容量
                pageIndex:1,//当前页码
                total:0//查询结果总量
            },
            //向后端查询发票对账单的参数
            invoiceParam:{
                cVenCode:'',//供应商编码
                dateRange:[],//日期范围
            },
            invoiceCheckDatas:[],//查询到的发票对账单数据
            loading:false,//是否显示加载界面
            invoiceCheckSelection:[],//选中的发票对账单
            excelName:'',//导出excel表格的名称
            excelAllName:'',//导出全部内容excel表格的名称
            drawer:false,//弹出导出excel页面的属性
        }
    },
    watch:{
        //监听日期范围
        "queryInvoiceParam.dateRange"(newVal){
            if (newVal==null||newVal.length==0) {
                this.queryInvoiceParam.dateRange=[];
                //调用初始化日期范围的方法
                this.initDateRange();
            }
        }
    },
    computed:{
        //计算发票对账数据区域高度
        tableHeight(){
            return window.innerHeight - 240;
        }
    },
    created(){
        //调用初始化登录供应商用户的方法
        this.getVenUser();
        //调用初始化日期范围的方法
        this.initDateRange();
        //调用向后端查询发票对账单的方法
        this.toQueryInvoiceCheck();
    },
    methods:{
        /**
         * 初始化登录供应商用户的方法
         */
        getVenUser(){
            //获取登录供应商对象
            this.venUser=this.$store.state.UserToken;//路由中获取到的是json字符串，要转化为json对象
            this.invoiceParam.cVenCode=this.venUser.cVenCode;//设置登录供应商用户编码
        },
        /**
         * 初始化日期范围的方法
         */
        initDateRange(){
            this.queryInvoiceParam.dateRange=[];//清空之前的查询日期范围
            let today=new Date();
            today.setHours(0,0,0,0);
            //开始查询日期
            let startDate=new Date(today.getTime()-(1000*60*60*24*30));//设置为当前的前一个月
            this.queryInvoiceParam.dateRange.push(startDate);//设置查询范围开始日期
            this.queryInvoiceParam.dateRange.push(today);//设置查询范围结束日期
            this.invoiceParam.dateRange=this.queryInvoiceParam.dateRange;//设置向后端的查询日期范围
        },
        /**
         * 重置查询条件的方法
         */
        restQuery(){
            //调用初始化日期范围的方法
            this.initDateRange();
        },
        /**
         * 查询发票对账单的方法
         */
        queryInvoiceCheck(){
            //设置向后端查询发票对账单的条件
            this.invoiceParam.dateRange=this.queryInvoiceParam.dateRange;
            //向后端查询发票对账单的方法
            this.toQueryInvoiceCheck();
        },
        /**
         * 向后端查询发票对账单的方法
         */
        toQueryInvoiceCheck(){
            let that=this;
            this.invoiceCheckDatas=[];//先清空之前查询到的发票对账数据
            this.queryInvoiceParam.total=0;//先清空之前查询到的发票对账数据数量
            this.queryInvoiceParam.pageIndex=1;//重置当前页为1
            this.loading=true;//显示加载界面
            this.$axios.post("/u8/invoiceCheck/getInvoiceCheck",this.invoiceParam)
            .then((resp)=>{
                if (resp.data.success=="true") {
                    this.invoiceCheckDatas=resp.data.data;
                    this.queryInvoiceParam.total=this.invoiceCheckDatas.length;
                }else{
                    this.$message({
                        showClose:true,
                        type:'warning',
                        message:resp.data.msg
                    });
                }
                this.loading=false;//关闭加载界面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('查询发票对账单服务器出错了');
                    that.$message.error('查询发票对账单服务器出错了');
                }
            );
        },
        //分页查询
        /**
         * 改变每页显示的数量
         */
        handleInvoiceCheckSizeChange(val) {
            this.queryInvoiceParam.pageSize=val;//设置每页容量
            this.queryInvoiceParam.pageIndex=1;//改总量后，初始页面改为1
        },
        /**
         * 改变开始页码
         */
        handleCurrentInvoiceCheckChange(val) {
            this.queryInvoiceParam.pageIndex=val;//设置当前页码
        },
        /**
         * 选中发票对账单的方法
         */
        handleSelInvoiceCheckChange(val) {
            this.invoiceCheckSelection = val;
            console.log('选中的发票对账单是：',this.invoiceCheckSelection);
        },
        /**
         * 导出当前页excel表格
         */
        exportOneExcel(){
            if(this.excelName==''){
                this.$message('请输入要导出excel表格的名字');
            }else{
                let fix = document.querySelector('.el-table__fixed');
                let tables = document.getElementById("invoiceCheck-table");
                let table_book;
                if(fix){//判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
                    table_book = this.$XLSX.utils.table_to_book(tables.removeChild(fix),{raw:true});
                    tables.appendChild(fix);
                }else{
                    table_book = this.$XLSX.utils.table_to_book(tables,{raw:true});
                }
                var table_write = this.$XLSX.write(table_book, {
                    bookType: "xlsx",
                    bookSST: true,
                    type: "array"
                });
                let excelNameStr=this.excelName+'.xlsx'
                try {
                    this.$FileSaver.saveAs(
                    new Blob([table_write], { type: "application/octet-stream" }),
                    excelNameStr
                    );
                } catch (e) {
                    if (typeof console !== "undefined") console.log(e, table_write);
                }
                this.excelName='';//表格名设置为空
                return table_write;
            }
        },
        /**
         * 导出查询到的全部内容excel表格
         */
        exportAllExcel(){
            if(this.excelAllName==''){
                this.$message('请输入要导出全部内容excel表格的名字');
            }else{
                let defaultPageSize=this.queryInvoiceParam.pageSize;//原本选择的页面容量
                this.queryInvoiceParam.pageSize=this.queryInvoiceParam.total;//将表格长度变成数据总长度
                this.queryInvoiceParam.pageIndex = 1//显示第一页
                this.$nextTick(function () {
                    let fix = document.querySelector('.el-table__fixed');
                    let tables = document.getElementById("invoiceCheck-table");
                    let wb;
                    if(fix){//判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
                        wb = this.$XLSX.utils.table_to_book(tables.removeChild(fix),{raw:true});
                        tables.appendChild(fix);
                    }else{
                        wb = this.$XLSX.utils.table_to_book(tables,{raw:true});
                    }
                    let wbout = this.$XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
                    let excelAllNameStr=this.excelAllName+'.xlsx'
                    try {
            　　　　　　 //Blob 对象表示一个不可变、原始数据的类文件对象。它的数据可以按文本或二进制的格式进行读取，也可以转换成 ReadableStream 来用于数据操作
                        this.$FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), excelAllNameStr)
                    } catch (e) {
                        if (typeof console !== 'undefined') console.log(e, wbout)
                    }
                    this.queryInvoiceParam.pageSize = defaultPageSize;//表格长度还原
                    this.excelAllName='';//表格名设置为空
                    return wbout;
                })
            }
        },
    }
}
</script>

<style>

</style>