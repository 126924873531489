<template>
    <!-- 合并发货选择页 -->
    <el-container class="sent-dialog">
        <!-- 查询条件区域 -->
        <el-header class="query-condition" style="height:100px">
            <el-form :inline="true" :model="queryInline" class="demo-form-inline">
                <el-row class="query-row">
                    <el-col :span="6" class="query-col">
                        <el-form-item label="日期范围：">
                            <el-date-picker
                            v-model="queryInline.orderDate"
                            type="daterange"
                            align="right"
                            unlink-panels
                            size="mini"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width:220px"
                            :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="query-col">
                        <el-form-item label="采购单号：">
                            <el-input v-model="queryInline.proOrderNum" clearable size="mini" style="width:130px" placeholder="模糊查询采购单号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="query-col">
                        <el-form-item label="存货编码：">
                            <el-input v-model="queryInline.inventoryCode" clearable size="mini" style="width:130px" placeholder="模糊查询存货编码"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="query-col">
                        <el-button type="warning" icon="el-icon-refresh" size="mini" @click="restQuery()">重置</el-button>
                        <el-button type="primary" icon="el-icon-search" size="mini" @click="queryOrder()">查询</el-button>
                    </el-col>
                </el-row>
                <el-row class="query-row">
                    <el-col :span="6" class="query-col">
                        <el-form-item label="存货名称：">
                            <el-input v-model="queryInline.inventoryName" clearable size="mini" style="width:130px" placeholder="模糊查询存货名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="query-col">
                        <el-form-item label="规格型号：">
                            <el-input v-model="queryInline.speci" clearable size="mini" style="width:130px" placeholder="模糊查询规格型号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="query-col">
                        <el-form-item label="单据类型：">
                            <el-select v-model="queryInline.cSourceType" size="mini" style="width:130px" placeholder="请选择">
                                <el-option
                                v-for="item in cSourceTypeOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="query-col">
                        <el-button type="success" icon="el-icon-receiving" size="mini" @click="toSent()">发货</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <!-- 采购（委外）订单数据展示区域 -->
        <el-main class="data-show">
            <el-table
                :data="OrderData.slice((queryInline.pageIndex-1)*queryInline.pageSize,queryInline.pageIndex*queryInline.pageSize)"
                stripe
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :height="tableHeight"
                border
                ref="purchaseTable"
                size="mini"
                style="width: 100%"
                @selection-change="handleSelectionChange"
                class="purchaseShow">
                <el-table-column
                type="selection"
                show-overflow-tooltip
                width="55">
                </el-table-column>
                <el-table-column
                type="index"
                width="50px">
                </el-table-column>
                <el-table-column
                prop="dDate"
                label="日期"
                sortable
                show-overflow-tooltip
                min-width="80px">
                </el-table-column>
                <el-table-column
                prop="cID"
                label="采购（委外）单号"
                sortable
                show-overflow-tooltip
                min-width="80px">
                </el-table-column>
                <el-table-column
                prop="cVenName"
                label="供应商名称"
                sortable
                show-overflow-tooltip
                min-width="100px">
                </el-table-column>
                <el-table-column
                prop="cPersonName"
                sortable
                label="采购员"
                show-overflow-tooltip
                min-width="80px">
                </el-table-column>
                <el-table-column
                prop="cSourceType"
                sortable
                label="来源单类型"
                show-overflow-tooltip
                min-width="60px">
                    <template slot-scope="scope">
                        <el-tag size="mini" :type="scope.row.cSourceType=='PO'?'success':''">{{scope.row.cSourceType=='PO'?'采购订单':'委外订单'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                prop="cMemo"
                sortable
                label="备注"
                show-overflow-tooltip
                min-width="100px">
                </el-table-column>
                <el-table-column
                label="操作"
                min-width="100px">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页查询区 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="this.queryInline.pageIndex"
                :page-sizes="this.queryInline.pageSizes"
                :page-size="this.queryInline.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.queryInline.total">
            </el-pagination>
            <!-- 采购（委外）订单详情框 -->
            <el-dialog append-to-body :title="selectpPurOrOut.cSourceType=='PO'?'采购订单详情':'委外订单详情'" :visible.sync="showPurOrOutDet" @close='closePurOrOutDet' width="90%" class="purDetailsForm">
                <CombinedSentDetails @showPurOrOutDetails="changShowpurOrOutDet" v-if="isRefresh" :purOrOut="selectpPurOrOut" :venUser="venUser"></CombinedSentDetails>
                <el-row style="margin-top:10px">
                    <el-col :span="24" class="close-col">
                        <el-button type="danger" icon="el-icon-circle-close" @click="closePurOrOutDet()">关闭</el-button>
                    </el-col>
                </el-row>
            </el-dialog>
            <!-- 合并发货框 -->
            <!-- <el-dialog append-to-body title="合并发货" :visible.sync="showSentDet" @close='closeSentDet' width="90%" class="purDetailsForm">
                <ToComSentDetails @showSentDetails="changShowSentet" v-if="isRefresh" :sents="selectpSents"></ToComSentDetails>
                <el-row style="margin-top:10px">
                    <el-col :span="24" class="close-col">
                        <el-button type="danger" icon="el-icon-circle-close" @click="closeSentDet()">关闭</el-button>
                    </el-col>
                </el-row>
            </el-dialog> -->
        </el-main>
    </el-container>
</template>

<script>
import CombinedSentDetails from '../draft/CombinedSentDetailsPage'
export default {
    data(){
        return{
            //日历快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            venUser:{},//登录的供应商对象
            //查询参数
            queryInline:{
                orderDate:[],//日期范围数组
                proOrderNum:'',//采购（委外）单号
                inventoryCode:'',//存货编码
                inventoryName:'',//存货名称
                speci:'',//规格型号
                cSourceType:'',//来源单类型（单据类型）
                pageSizes:[10, 32, 64, 100, 200,300],//页面容量数组
                pageSize:10,//每页容量
                pageIndex:1,//当前页码
                total:0//查询结果总量
            },
            //向后端查询采购订单的参数
            queryParam:{
                orderDate:[],//日期范围数组
                proOrderNum:'',//采购（委外）单号
                inventoryCode:'',//存货编码
                inventoryName:'',//存货名称
                speci:'',//规格型号
                cSourceType:'',//来源单类型（单据类型）
                iAdmin:'',//供应商权限
                cVenCode:'',//供应商编码
            },
            tableHeight:window.innerHeight - 500,//数据展示区域自适应高度
            loading:false,//是否显示加载图标
            //来源单类型下拉框
            cSourceTypeOpt:[
                {
                    value: 'PO',
                    label: '采购订单'
                },
                {
                    value: 'OM',
                    label: '委外订单'
                }
            ],
            OrderData:[],//查询到采购（委外）订单数据
            multipleSelection: [],//选中要合并发货的采购（委外）订单数组
            showPurOrOutDet:false,//是否显示合并发货页采购订单或委外订单详情页的属性
            selectpPurOrOut:'',//选中要查看采购订单详情的采购订单主表对象
            isRefresh:true,//是否刷新采购订单详情窗口子组件的属性
            showSentDet:false,//是否显示合并发货页的属性
            selectpSents:[],//最后要合并发货的采购（委外）订单数组
        }
    },
    watch:{
        "queryInline.orderDate"(newVal){//监控日期范围条件的绑定值
            if(newVal==null){//如果日期范围的绑定值为null
                this.queryInline.orderDate=[];//该日期范围绑定值设置为[]
            }
        }
    },
    created(){
        //调用设置查询中供应商的参数
        this.setVenUser();
        //来源单类型默认选中项
        this.queryInline.cSourceType='PO';
        this.queryParam.cSourceType=this.queryInline.cSourceType;//设置来源单类型（单据类型）查询参数
        if( this.queryParam.cSourceType!=''){
            //调用向后端查询采购(委外)订单的方法
            this.toQueryOrder();
        }
    },
    methods:{
        /**
         * 设置查询中供应商的参数
         */
        setVenUser(){
            //获取上级父组件传进来的参数并赋值
            this.venUser=this.$store.state.UserToken;//路由中获取到的是json字符串，要转化为json对象
            this.queryParam.iAdmin=this.venUser.iAdmin;//供应商权限
            this.queryParam.cVenCode=this.venUser.cVenCode;//供应商编码
        },
        /**
         * 清空查询条件的方法
         */
        restQuery(){
            this.queryInline.orderDate=[],//日期范围数组
            this.queryInline.proOrderNum='',//采购单号
            this.queryInline.inventoryCode='',//存货编码
            this.queryInline.inventoryName='',//存货名称
            this.queryInline.speci=''//规格型号
            this.queryInline.cSourceType=this.cSourceTypeOpt[0].value;//来源单类型
        },
        /**
         * 点击查询采购（委外）订单的方法
         */
        queryOrder(){
            //查询之前清空数据
            this.OrderData=[];
            this.queryInline.pageIndex=1;//查询前重置为查询第一页
            this.queryInline.total=0;//重置查询到的采购（委外）订单总数
            //设置向后端查询的查询参数
            this.queryParam.orderDate=this.queryInline.orderDate;//日期范围数组
            this.queryParam.proOrderNum=this.queryInline.proOrderNum;//采购单号
            this.queryParam.inventoryCode=this.queryInline.inventoryCode;//存货编码
            this.queryParam.inventoryName=this.queryInline.inventoryName;//存货名称
            this.queryParam.speci=this.queryInline.speci;//规格型号
            this.queryParam.cSourceType=this.queryInline.cSourceType;////来源单类型
            //调用向后端查询采购（委外）订单的方法
            this.toQueryOrder();
        },
        /**
         * 向后端查询采购（委外）订单的方法
         */
        toQueryOrder(){
            this.loading=true;//显示加载页面
            this.$axios.post("/u8/delivery/getPurOrOutSou", this.queryParam)
            .then((resp)=>{
                if(resp.data.success=="false"){
                    this.$message(resp.data.msg);
                }
                if(resp.data.success=="true"){
                    this.OrderData=resp.data.data;
                    this.queryInline.total=this.OrderData.length;//查询到采购（委外）订单的总数
                }
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('采购或委外订单服务器出错了');
                    this.$message('采购或委外订单服务器出错了');
                }
            );
        },
        //分页查询
        /**
         * 改变每页显示的数量
         */
        handleSizeChange(val) {
            this.queryInline.pageSize=val;//设置每页容量
            this.queryInline.pageIndex=1;//改总量后，初始页面改为1
        },
        /**
         * 改变开始页码
         */
        handleCurrentChange(val) {
            this.queryInline.pageIndex=val;//设置当前页码
        },
        /**
         * 绑定多选采购（委外）订单的方法
         */
        handleSelectionChange(val) {
            this.multipleSelection = val;//设置多选选中的采购（委外）订单
        },
        /**
         * 合并发货的方法(打开合并发货页)
         */
        toSent(){
            if(this.multipleSelection.length<1){
                return this.$message('请选中要合并发货的订单');
            }
            this.selectpSents=this.multipleSelection;//设置要合并发货的订单
            let retDate={
                showSentDet:false,//关闭合并发货选择窗口
                cSourceType:this.queryParam.cSourceType,//来源单类型
                selectpSents:this.selectpSents//要合并发货的订单
            }
            this.$emit('showSent',retDate)//向父组件传值关闭合并发货选择窗口
        },
        /**
         * 打开采购订单或委外订单详情窗口的方法
         */
        handleClick(row){
            this.selectpPurOrOut=row;//设置选中的采购（委外）订单主表对象
            this.isRefresh=false;//是否刷新采购订单详情窗口子组件的属性
            this.$nextTick(() => {
                this.isRefresh= true;//强制刷新采购订单详情页子组件数据
            });
            this.showPurOrOutDet=true;//打开采购（委外）订单详情框
        },
        /**
         * 关闭采购订单或委外订单详情窗口的方法
         */
        closePurOrOutDet(){
            this.showPurOrOutDet=false;//关闭合并发货的采购订单或委外订单详情页
        },
        /**
         * 子组件传值关闭详情窗口的方法
         */
        changShowpurOrOutDet(data){
            this.showPurOrOutDet=data;//关闭显示窗口
        }
        
    },
    components:{
        //引入合并发货采购订单或委外订单详情页
        CombinedSentDetails:CombinedSentDetails,
    }
}
</script>

<style>
    /* 弹框样式 */
    .purDetailsForm{
        margin-top: 20px;
    }

    /* 合并发货详情页关闭按钮样式 */
    .close-col{
        margin-bottom:10px;
        text-align:center;
    }

    /* 合并发货详情页弹框标题样式 */
    .el-dialog__header{
        text-align:center;
    }
</style>