<template>
    <el-container class="indexParent">
        <el-header style="text-align: right; font-size: 12px;height: 40px;line-height: 40px">
          <el-row>
            <el-col :span="8">
              <div style="float: left; cursor:pointer" @click="isOpen">
                  <i :class="{'el-icon-s-fold': isCollapse == false?true:false, 'el-icon-s-unfold': isCollapse == true?true:false}"></i>
              </div>
            </el-col>
            <el-col :span="8" style="font-size:1.8em" align="middle">
              <span>U8-供应商平台</span>
            </el-col>
            <el-col :span="8">
              <el-dropdown>
                <i class="el-icon-setting" style="margin-right: 15px"></i>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="removeLogin()">安全退出</el-dropdown-item>
                    <el-dropdown-item @click.native="openUpdPass()">密码修改</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <span>{{venUser.iAdmin==1?'超级用户':venUser.cVenName}}</span>
            </el-col>
          </el-row>
        </el-header>
        <el-container :style="{height: setHeight+'px'}">
            <el-aside style="width: auto">
                <el-menu :default-active="String(activeNav)" :default-openeds="openeds" router class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff" :collapse="isCollapse">
                    <el-submenu index="1">
                        <template slot="title">
                            <i class="el-icon-s-tools"></i>
                            <span slot="title">供应商功能</span>
                        </template>
                        <el-menu-item index="/index/purchase">采购订单</el-menu-item>
                        <el-menu-item index="/index/outsourcing">委外订单</el-menu-item>
                        <el-menu-item index="/index/draft">送货草稿</el-menu-item>
                        <el-menu-item index="/index/deliveryOrder">送货记录</el-menu-item>
                        <el-menu-item index="/index/salesReturn">退货记录</el-menu-item>
                        <el-menu-item v-if="showPrice" index="/index/enquirySupOrder">供应商询价单</el-menu-item>
                        <el-menu-item v-if="showPrice" index="/index/offerSupOrder">供应商报价</el-menu-item>
                        <el-menu-item v-if="showPrice" index="/index/checkSupOrder">供应商对账</el-menu-item>
                    </el-submenu>
                    <el-submenu v-if="venUser.iAdmin==1&&venUser.iPrice==1" index="2">
                        <template slot="title">
                            <i class="el-icon-s-tools"></i>
                            <span slot="title">采购方功能</span>
                        </template>
                        <el-menu-item index="/index/enquiryOrder">询价单</el-menu-item>
                        <el-menu-item index="/index/offerOrder">报价单</el-menu-item>
                        <el-menu-item index="/index/checkOrder">对账单</el-menu-item>
                    </el-submenu>
                    <el-submenu v-if="venUser.iAdmin!=1&&venUser.iPrice==1" index="3">
                        <template slot="title">
                            <i class="el-icon-s-claim"></i>
                            <span slot="title">发票对账</span>
                        </template>
                        <el-menu-item index="/index/invoiceChecks">发票对账单</el-menu-item>
                    </el-submenu>
                    <el-submenu v-if="venUser.iAdmin==1&&venUser.iPrice==1" index="4">
                        <template slot="title">
                            <i class="el-icon-s-claim"></i>
                            <span slot="title">系统设置</span>
                        </template>
                        <el-menu-item index="/index/parameter">参数设置</el-menu-item>
                    </el-submenu>
                </el-menu>
            </el-aside>
            <el-main :style="{height: setHeight+'px', padding: 0}">
                <el-tabs v-model="activeTab" type="card" @tab-remove="removeTab" @tab-click="tabClick">
                    <el-tab-pane v-for="item in tabsItem"
                                :key="item.name"
                                :label="item.title"
                                :name="item.name"
                                :closable="item.closable"
                                :ref="item.ref">
                        <component :is="item.content"></component>
                    </el-tab-pane>
                </el-tabs>
            </el-main>
            <!-- 修改密码框 -->
            <el-dialog append-to-body title="密码修改" :visible.sync="showUpdPass" @close='closeUpdPass' width="500px" class="updPassForm">
                <el-form :model="updPass" :rules="rules" ref="rulePassForm" label-width="130px" class="demo-form-inline" >
                  <el-form-item label="用户：">
                      {{updPass.cUserName}}
                  </el-form-item>
                  <el-form-item label="用户账号：">
                      {{updPass.cUserId}}
                  </el-form-item>
                  <el-form-item label="旧密码：" prop="cPassword">
                    <el-input type="password" size="mini" v-model="updPass.cPassword" autocomplete="off" placeholder="请输入旧密码" style="width:200px"></el-input>
                  </el-form-item>
                  <el-form-item label="新密码：" prop="newCpassword">
                    <el-input type="password" size="mini" v-model="updPass.newCpassword" autocomplete="off"  placeholder="请输入新密码" style="width:200px"></el-input>
                  </el-form-item>
                  <el-form-item label="确认新密码：" prop="conNewCpassword">
                    <el-input type="password" size="mini" v-model="updPass.conNewCpassword" autocomplete="off"  placeholder="请确认新密码" style="width:200px"></el-input>
                  </el-form-item>
                </el-form>
                <el-row style="margin-top:10px">
                    <el-col :span="24" style="margin-bottom:20px;text-align: center;">
                      <el-button type="warning" icon="el-icon-refresh" size="mini" @click="restUpdPass()">重置</el-button>
                      <el-button type="primary" icon="el-icon-search" size="mini" @click="toUpdPass('rulePassForm')">修改</el-button>
                      <el-button type="danger" icon="el-icon-circle-close" size="mini" @click="closeUpdPass()">关闭</el-button>
                    </el-col>
                </el-row>
            </el-dialog>
        </el-container>
    </el-container>
</template>

<script>
    import Welcome from '../components/WelcomPage.vue'//欢迎页
    import Purchase from '../components/purchase/PurchasePage.vue'//采购订单页
    import Outsourcing from '../components/outsourcing/OutsourcingPage.vue'//委外订单页
    import Draft from "../components/draft/DraftPage.vue"//送货草稿页
    import DeliveryOrder from '../components/delivery/DeliveryOrderPage.vue'//送货记录
    import SalesReturn from '../components/salesReturn/SalesReturnPage.vue'//退货单
    import EnquirySupOrder from '../components/enquirySup/EnquirySupOrderPage.vue'//供应商询价单
    import OfferSupOrder from '../components/offerSup/OfferSupOrderPage.vue'//供应商报价
    import CheckSupOrder from '../components/checkSup/CheckSupOrderPage.vue'//供应商对账单
    import EnquiryOrder from '../components/enquiry/EnquiryOrderPage.vue'//采购方询价单
    import OfferOrder from '../components/offer/OfferOrderPage.vue'//采购方报价单
    import CheckOrder from '../components/check/CheckOrderPage.vue'//采购方对账单
    import InvoiceChecks from '../components/invoiceChecks/InvoiceChecksPage.vue'//发票对账单
    import Parameter from '../components/param/ParameterPage.vue'//参数设置页

    //从vuex中导入mapMutations，可以以集合的形式引入仓库中的修改器
    import { mapMutations } from 'vuex';
    export default {
      name: "Home",
      data() {
        //检验新旧密码是否一致
        var checkOldPassAndNewPass = (rule, value, callback) => {
          if (this.updPass.cPassword === this.updPass.newCpassword) {
            callback(new Error('新密码不能和旧密码一样'));
          } else {
            callback();
          }
        };
        //检验修改密码两次新密码是否一致
        var validatePass = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请再次输入密码'));
          } else if (value !== this.updPass.newCpassword) {
            callback(new Error('两次输入密码不一致!'));
          } else {
            callback();
          }
        };
        return {
          venUser:{},//登录的供应商对象
          showPrice:true,//是否显示单价权限
          isCollapse: false,  //false为展开 true为收缩
          openeds:['1','2','3','4'],//默认展开的侧边栏
          activeTab: '1', //默认显示的tab
          tabIndex: 1, //tab目前显示数
          //标签
          tabsItem: [
            {
              title: '欢迎页',//标签名称
              name: '1',//第几个标签
              closable: false,//标签是否可以关闭
              ref: 'tabs',
              content: Welcome
            }
          ],
          //标签对应的导航
          tabsPath: [
            {
              name: "1",//第几个标签
              path: '/index/welcome'//标签对应的页面路由地址
            }
          ],
          initTabsItem:[],//用于存储初始化标签
          initTabsPath:[],//用于存储初始化导航
          showUpdPass:false,//是否打开修改密码框的属性
          updPass:{
            cUserName:'',//用户名
            cUserId:'',//用户账号
            cVenCode:'',//供应商编码
            cVenName:'',//供应商名称
            cPassword:'',//旧密码
            newCpassword:'',//新密码
            conNewCpassword:''//确认新密码
          },
          //修改密码框验证规则
          rules: {
            cPassword: [
              { required: true, message: '请输入旧密码', trigger: 'blur' }
            ],
            newCpassword: [
              { required: true, message: '请输入新密码', trigger: 'blur' },
              { validator: checkOldPassAndNewPass, trigger: 'blur' }
            ],
            conNewCpassword: [
              { validator: validatePass, trigger: 'blur' }
            ]
          }
        }
      },
      computed: {
        //计算高度
        setHeight() {
          return document.documentElement.clientHeight - 65
        },
        activeNav() { //当前激活的导航
          return this.$route.path
        }
      },
      watch: {
        '$route': function (to) {  //监听路由的变化，动态生成tabs
          let flag = true; //判断是否需要新增页面
          const path = to.path;//要跳转的页面路由地址
          if (Object.keys(to.meta).length != 0) {
            for (let i = 0; i < this.$refs.tabs.length; i++) {
              if (this.$refs.tabs[i].label == to.meta.title) {
                this.activeTab = this.$refs.tabs[i].name  //定位到已打开页面
                flag = false
                break
              }
            }
            //新增页面
            if (flag) {
              //获得路由元数据的name和组件名
              const thisName = to.meta.title;//要打开的页面标题
              const thisComp = to.name;//要打开的页面组件名称
              //对tabs的当前激活下标和tabs数量进行自加
              let newActiveIndex = ++this.tabIndex + ''
              //动态双向追加tabs
              this.tabsItem.push({
                title: thisName,//新增的标签名称
                name: String(newActiveIndex),//新增的是第几个标签
                closable: true,//新增的标签是否可以关闭
                ref: 'tabs',
                content: thisComp//新增的页面组件名称
              })
              this.activeTab = newActiveIndex;//第几个标签
              /*
              * 当添加tabs的时候，把当前tabs的name（新增的页面是第几个标签）作为key，path（新增页面的路由地址）作为value存入tabsPath数组中
              * key:tabs的name（新增的页面是第几个标签）
              * value:tabs的path（新增页面的路由地址）
              * {
              *   key: name,
              *   value: path
              * }
              * ///后面需要得到当前tabs的时候可以通过当前tabs的name获得path
              * */
              if (this.tabsPath.indexOf(path) == -1) {
                this.tabsPath.push({
                  name: newActiveIndex,
                  path: path
                })
              }
            }
          }
        }
      },
      methods: {
        //使用...mapMutatioins以集合方式获取store仓库中的修改器
        //removeLogin是store仓库中修改器的移除登录用户token的方法
        ...mapMutations(['removeLogin']),
        removeLogin(){
            this.$store.commit('removeLogin')
            //调用恢复标签和导航的方法
            this.restTabsItemAndPath();
        },
        /**
         * 保存初始化标签和导航
         */
        keepInitTableAndPath(){
          this.initTabsItem=this.tabsItem;//保存初始化标签
          this.initTabsPath=this.tabsPath;//保存初始化导航
        },
        /**
         * 恢复页签和导航为初始值
         */
        restTabsItemAndPath(){
          this.tabsItem=this.initTabsItem;//恢复标签初始值
          this.tabsPath=this.initTabsPath;//恢复导航初始值
        },
        /**
         * 左侧栏伸缩的方法
         */
        isOpen() { //判断左侧栏是否展开或收缩
          if (this.isCollapse == false) {
            this.isCollapse = true
          } else {
            this.isCollapse = false
          }
        },
        /**
         * 删除标签的方法
         * @param targetName 要删除的是第几个标签序号
         */
        removeTab(targetName) { //删除Tab
          let tabs = this.tabsItem; //当前显示的tab数组
          let activeName = this.activeTab; //点前活跃的tab
          //如果当前tab正活跃 被删除时执行
          if (activeName === targetName) {
            tabs.forEach((tab, index) => {
              if (tab.name === targetName) {
                let nextTab = tabs[index + 1] || tabs[index - 1];
                if (nextTab) {
                  activeName = nextTab.name;
                  //调用重新选取tab的方法
                  this.tabClick(nextTab);
                }
              }
            });
          }
          this.activeTab = activeName;
          //在标签数组中删除该标签
          this.tabsItem = tabs.filter(tab => tab.name !== targetName);
          //在tabsPath（已存在的标签路由）中删除当前被删除tab的path
          this.tabsPath = this.tabsPath.filter(item => item.name !== targetName)
        },
        tabClick(thisTab) {
          /*
          * thisTab:当前选中的tabs的实例
          * 通过当前选中tabs的实例获得当前实例的path 重新定位路由
          * 如果删除的tab是中间位置tab，则删除后自动选取后面一个tab；
          * 如果删除的是最后一个tab则自动选取前一个tab
          * */
          let val = this.tabsPath.filter(item => thisTab.name == item.name)
          this.$router.push({
            path: val[0].path
          })
        },
        /**
         * 初始化密码修改框参数
         */
        initUpdPassParam(){
          this.updPass.cUserName=this.venUser.cUserName;//用户名
          this.updPass.cUserId=this.venUser.cUserId;//用户账号
          this.updPass.cVenCode=this.venUser.cVenCode;//供应商编码
          this.updPass.cVenName=this.venUser.cVenName;//供应商名称
        },
        /**
         * 打开修改密码框
         */
        openUpdPass(){
          this.showUpdPass=true;
        },
        /**
         * 关闭修改密码框
         */
        closeUpdPass(){
          //重置密码修改框
          this.restUpdPass();
          this.showUpdPass=false;
        },
        /**
         * 重置密码修改框
         */
        restUpdPass(){
          this.updPass.cPassword='',//旧密码
          this.updPass.newCpassword='',//新密码
          this.updPass.conNewCpassword=''//确认新密码
        },
        /**
         * 修改密码的方法
         */
        toUpdPass(formName){
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.$axios.post("/u8/login/toUpdPass",this.updPass)
              .then((resp)=>{
                this.$message(resp.data.msg);
                if(resp.data.success=="true"){
                  //关闭密码修改框
                  this.showUpdPass=false;
                  //调用安全退出的方法
                  this.removeLogin();
                }
              })
              .catch(
                function(error){
                  console.log(error);
                  console.log('账号服务器出错了');
                  this.$message('账号服务器出错了');
                }
              );
            } else {
              console.log('没有数据!!');
              return false;
            }
          });
        }
      },
      mounted() {
        /*
        * 监听页面刷新事件
        * 页面刷新前 需要保存当前打开的tabs的位置，刷新后按刷新前的顺序展示
        * 使用js的sessionStorage保存刷新页面前的数据
        * */
        window.addEventListener('beforeunload', e => {
          sessionStorage.setItem("tabsItem", JSON.stringify({
            currTabsItem: this.tabsItem.filter(item => item.name !== "1"),
            currTabsPath: this.tabsPath.filter(item => item.name !== "1"),
            currActiveTabs: this.activeTab,
            currIndex: this.tabIndex
          }))
        });
      },
      created() {
        //调用保存初始化的标签和导航
        this.keepInitTableAndPath();
        //获取上级父组件传进来的参数并赋值
        this.venUser=this.$store.state.UserToken;//路由中获取到的是json字符串，要转化为json对象
        if(this.venUser.iPrice==1){//如果有单价权限
          this.showPrice=true;//显示单价
        }else{
          this.showPrice=false;//不显示单价
        }
        //初始化密码修改框参数
        this.initUpdPassParam();
        /*
        * 使用js的sessionStorage读取刷新前的数据，并按刷新前的tabs顺序重新生成tabs
        * */
        const sessionTabs = JSON.parse(sessionStorage.getItem("tabsItem"))
        if(sessionTabs!=null){
          if(sessionTabs.currTabsItem.length != 0 && sessionTabs.currTabsPath.length != 0) {
            for(let i=0; i<sessionTabs.currTabsItem.length; i++) {
              this.tabsItem.push({
                title: sessionTabs.currTabsItem[i].title,
                name: sessionTabs.currTabsItem[i].name,
                closable: true,
                ref: sessionTabs.currTabsItem[i].ref,
                content: sessionTabs.currTabsItem[i].content
              })
            }
            for(let j=0; j<sessionTabs.currTabsPath.length; j++) {
              this.tabsPath.push({
                name: sessionTabs.currTabsPath[j].name,
                path: sessionTabs.currTabsPath[j].path
              })
            }
            this.activeTab = sessionTabs.currActiveTabs
            this.tabIndex = sessionTabs.currIndex
            //避免强制修改url 出现浏览器的url输入框的路径和当前tabs选中的路由路径不匹配
            const activePath = this.tabsPath.filter(item => item.name == this.activeTab)
            this.$router.push({
              path: activePath[0].path
            })
          }
        }
      },
      components: {
        Welcome,
        Purchase,
        Outsourcing,
        Draft,
        DeliveryOrder,
        SalesReturn,
        EnquirySupOrder,
        OfferSupOrder,
        CheckSupOrder,
        EnquiryOrder,
        OfferOrder,
        CheckOrder,
        InvoiceChecks,//发票对账单
        Parameter,//参数设置页
      }
    }
</script>

<style scoped>

  .indexParent{
    height: 100%; 
    margin-top: 0px;
    border: 1px solid #eee
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
    height: 100%;
  }

  .el-menu--collapse {
    height: 100%;
  }

  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }

  .el-aside {
    color: #333;
  }

  .el-submenu [class^=fa] {
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 16px;
  }

  html,body,#app,.el-container{
      height: 100%;
      padding: 0px 0px;
      margin: 0px 0px;
  }

  .updPassForm{
    margin-top:100px
  }

  .demo-form-inline{
    margin-left:40px
  }

</style>