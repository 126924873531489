<template>
    <!-- 询价单（采购方） -->
    <el-container>
        <!-- 查询条件区域 -->
        <el-header style="height:50px">
            <el-form :inline="true" :model="queryInline" class="demo-form-inline">
                <el-row class="query-row">
                    <el-col :span="6" class="query-col">
                        <el-form-item label="日期范围">
                            <el-date-picker
                            v-model="queryInline.inquiryDates"
                            type="daterange"
                            align="right"
                            unlink-panels
                            size="mini"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width:205px"
                            :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" class="query-col">
                        <el-form-item label="单据编码">
                            <el-input size="mini" v-model.trim="queryInline.inquiryCode" clearable placeholder="单据编码" style="width:120px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" class="query-col">
                        <el-form-item label="业务员">
                            <el-input size="mini" v-model.trim="queryInline.cPersonName" clearable placeholder="业务员姓名" style="width:120px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" class="query-col">
                        <el-form-item label="状态">
                            <el-select v-model="queryInline.isExamine" clearable size="mini" placeholder="请选择" style="width:120px">
                                <el-option
                                v-for="item in examineOptions"
                                :key="item.isExamine"
                                :label="item.examineName"
                                :value="item.isExamine">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="query-col" style="padding-top:5px">
                        <el-button type="warning" icon="el-icon-refresh" size="mini" @click="restQuery()">重置</el-button>
                        <el-button type="primary" icon="el-icon-search" size="mini" @click="queryEnquiry()">查询</el-button>
                        <el-button type="success" icon="el-icon-circle-plus" size="mini" @click="openAddEnquiry()">新增</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <!-- 询价单数据展示区域 -->
        <el-main class="data-show">
            <el-table
                :data="enquiryData.slice((queryInline.pageIndex-1)*queryInline.pageSize,queryInline.pageIndex*queryInline.pageSize)"
                stripe
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :height="tableHeight"
                border
                ref="enquiryTable"
                size="mini"
                style="width: 100%;margin-bottom:5px"
                class="enquiryShow">
                <el-table-column
                type="index"
                min-width="5%">
                </el-table-column>
                <el-table-column
                prop="inquiryDate"
                label="单据日期"
                sortable
                show-overflow-tooltip
                min-width="20%">
                </el-table-column>
                <el-table-column
                prop="inquiryCode"
                label="单据编码"
                sortable
                show-overflow-tooltip
                min-width="20%">
                </el-table-column>
                <el-table-column
                prop="cPersonName"
                label="业务员"
                sortable
                show-overflow-tooltip
                min-width="20%">
                </el-table-column>
                <el-table-column
                prop="isExamine"
                label="审核状态"
                sortable
                show-overflow-tooltip
                min-width="15%">
                    <template slot-scope="scope">
                        <el-tag size="mini" :type="scope.row.isExamine==1?'success':scope.row.isExamine==0?'danger':''">{{scope.row.isExamine==0?'未审核':scope.row.isExamine==1?'已审核':'已完成'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                label="操作"
                min-width="20%">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-s-release" @click="handleClick(scope.row)" size="mini" style="margin-right:10px">查看</el-button>
                        <el-popconfirm v-if="scope.row.isExamine==0" title="确定删除该询价单吗？" @confirm="delEnquiryAndDet(scope.row)">
                            <el-button size="mini" type="danger" :disabled="scope.row.isExamine!=0" icon="el-icon-delete" slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页查询区 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="this.queryInline.pageIndex"
                :page-sizes="this.queryInline.pageSizes"
                :page-size="this.queryInline.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.queryInline.total">
            </el-pagination>
            <!-- 询价单详情框 -->
            <el-dialog title="询价单详情" :visible.sync="showEnquiryDet" @close='closeEnquiryDet' width="95%" class="detailsForm">
                <EnquiryDetails @showDetails="changShowEnquiryDet" @updEnquiry="toUpdEnquiry" v-if="isRefresh" :isAddForm="isAddForm" :enquiry="selectpEnquiry"></EnquiryDetails>
                <el-row style="margin-top:10px">
                    <el-col :span="24" style="margin-bottom:10px">
                        <el-button type="danger" icon="el-icon-circle-close" @click="closeEnquiryDet()">关闭</el-button>
                    </el-col>
                </el-row>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
import EnquiryDetails from '../enquiry/EnquiryOrderDetailsPage'
export default {
    data(){
        return{
            //日历快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            //查询参数
            queryInline:{
                inquiryDates:[],//日期范围数组
                inquiryCode:'',//单据编码
                cPersonName:'',//业务员名称
                isExamine:'',//询价单状态
                pageSizes:[10, 32, 64, 100, 200,300],//页面容量数组
                pageSize:10,//每页容量
                pageIndex:1,//当前页码
                total:0//查询结果总量
            },
            //向后端查询询价单的参数
            queryParam:{
                inquiryDates:[],//日期范围数组
                inquiryCode:'',//单据编码
                cPersonName:'',//业务员名称
                isExamine:'',//询价单状态
            },
            //询价单状态下拉列表
            examineOptions:[
                {
                    isExamine:0,
                    examineName:'未审核'
                },
                {
                    isExamine:1,
                    examineName:'已审核'
                },
                {
                    isExamine:2,
                    examineName:'已完成'
                }
            ],
            enquiryData:[],//查询到的询价单数据
            loading:false,//是否显示加载图标
            tableHeight:window.innerHeight - 250,//数据展示区域自适应高度
            showEnquiryDet:false,//是否显示询价单详情框的属性
            selectpEnquiry:{},//选中要查看询价单详情的询价单主表对象
            isRefresh:true,//是否刷新询价单详情窗口子组件的属性
            isAddForm:true,//弹框是否为新增窗的属性
        }
    },
    watch:{
        "queryInline.inquiryDates"(newVal){//监控日期范围条件的绑定值
            if(newVal==null||newVal.length==0){//如果日期范围的绑定值为null
                this.queryInline.inquiryDates=[];//该日期范围绑定值设置为[]
                //初始化查询时间范围
                this.initDateRange();
            }
        }
    },
    created(){
        //初始化查询时间范围
        this.initDateRange();
        //调用向后端查询询价单的方法
        this.queryEnquiry();
    },
    methods:{
        /**
         * 初始化查询时间范围
         */
        initDateRange(){
            let toDay=new Date();//获取天时间
            toDay.setHours(0,0,0);//时分秒设置为零
            console.log('今天日期是：',toDay);
            let startDate=new Date(toDay.getTime()-(1000*60*60*24*30));
            console.log('一个月前是：',startDate);
            this.queryInline.inquiryDates.push(startDate);//设置开始时间
            this.queryInline.inquiryDates.push(toDay);//设置结束时间
            this.queryParam.inquiryDates=this.queryInline.inquiryDates;//设置向后端查询的时间范围
        },
        /**
         * 重置查询条件的方法
         */
        restQuery(){
            this.queryInline.inquiryDates=[];//日期范围数组
            this.queryInline.inquiryCode='';//单据编码
            this.queryInline.cPersonName='';//业务员名称
            this.queryInline.isExamine='';//询价单状态
        },
        /**
         * 点击查询询价单的方法
         */
        queryEnquiry(){
            //查询之前清空数据
            this.enquiryData=[]
            this.queryInline.pageIndex=1;//查询前重置为查询第一页
            this.queryInline.total=0;//清空查询到的询价单总数
             //设置向后端查询询价单的参数
            this.queryParam.inquiryDates=this.queryInline.inquiryDates;//日期范围数组
            this.queryParam.inquiryCode=this.queryInline.inquiryCode;//单据编码
            this.queryParam.cPersonName=this.queryInline.cPersonName;//业务员名称
            this.queryParam.isExamine=this.queryInline.isExamine;//询价单状态
            //调用向后端查询询价单的方法
            this.toQueryEnquiry();
        },
        /**
         * 向后端查询询价单的方法
         */
        toQueryEnquiry(){
            this.loading=true;//显示加载页面
            this.$axios.post("/u8/enquiry/getEnquiry",this.queryParam)
            .then((resp)=>{
                if(resp.data.success=="false"){
                    this.$message(resp.data.msg);
                }
                if(resp.data.success=="true"){
                    this.enquiryData=resp.data.data;//设置查询到的询价单集合
                    this.queryInline.total=this.enquiryData.length;//查询到的询价单数量
                }
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('询价单服务器出错了');
                    this.$message('询价单服务器出错了');
                }
            );
        },
        //分页查询
        /**
         * 改变每页显示的数量
         */
        handleSizeChange(val) {
            this.queryInline.pageSize=val;//设置每页容量
            this.queryInline.pageIndex=1;//改总量后，初始页面改为1
        },
        /**
         * 改变开始页码
         */
        handleCurrentChange(val) {
            this.queryInline.pageIndex=val;//设置当前页码
        },
        /**
         * 查看询价单详情的方法
         */
        handleClick(row){
            this.selectpEnquiry=row;//设置选中的询价单主表标识
            this.isRefresh=false;//是否刷新询价单详情窗口子组件的属性
            this.$nextTick(() => {
                this.isAddForm=false;//标记为查看窗口
                this.isRefresh=true;//强制刷新询价单详情页子组件数据
            });
            this.showEnquiryDet=true;//打开询价单详情框
        },
        /**
         * 打开新增询价单的窗口
         */
        openAddEnquiry(){
            this.isRefresh=false;//是否刷新询价单详情窗口子组件的属性
            this.$nextTick(() => {
                this.isAddForm=true;//标记为新增窗口
                this.isRefresh=true;//强制刷新询价单详情页子组件数据
            });
            this.showEnquiryDet=true;//打开询价单详情框
        },
        /**
         * 删除送询价单及询价单详情的方法
         */
        delEnquiryAndDet(row){
            this.$axios.post("/u8/enquiry/delEnquiryAndDet",row)
            .then((resp)=>{
                this.$message(resp.data.msg);
                if(resp.data.success=="true"){
                    //重新查询询价单
                    this.toQueryEnquiry();
                }
            })
            .catch(
                function(error){
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                    this.$message('询价单服务器出错了')
                }
            );
        },
        /**
         * 关闭询价单详情窗口的方法
         */
        closeEnquiryDet(){
            this.showEnquiryDet=false;
        },
        /**
         * 子组件传值关闭询价单详情窗口的方法
         */
        changShowEnquiryDet(data){
            this.showEnquiryDet=data;
            //重新查询询价单
            this.toQueryEnquiry();
        },
        /**
         * 子组件调用查询查询询价单主表的方法
         */
        toUpdEnquiry(){
            //重新查询询价单
            this.toQueryEnquiry();
        }
    },
    components:{
        //引入询价单详情页
        EnquiryDetails:EnquiryDetails,
    }
}
</script>

<style>
    /* 弹框样式 */
    .detailsForm{
        margin-top: 0px;
    }
</style>