<template>
    <!-- 拆分采购订单页（供货计划页） -->
    <el-container id="split-purchase-table">
        <el-dialog title="供货计划" :visible.sync="showSplitPurchaseDet" append-to-body center :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" width="60%" class="splitPurchaseDetailsForm"  style="padding-top:50px;">
            <!-- 要拆分采购订单的存货信息 -->
            <el-header class="header-purchase" style="height:30px">
                <el-form :inline="true" :model="activeSplitcInv" class="demo-form-inline" id="header-table">
                    <el-row class="query-row" style="height:30px">
                        <el-col :span="8" class="query-col">
                            <el-form-item size="mini" label="采购单号：">
                                {{activeSplitcInv.cPOID}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="query-col">
                            <el-form-item size="mini" label="存货编码：">
                                {{activeSplitcInv.cInvCode}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="query-col">
                            <el-form-item size="mini" label="单位：">
                                {{activeSplitcInv.cComUnitName}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="query-row" style="height:30px">
                        <el-col :span="8" class="query-col">
                            <el-form-item size="mini" label="存货名称：">
                                {{activeSplitcInv.cInvName}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="query-col">
                            <el-form-item size="mini " label="订单数量：">
                                {{activeSplitcInv.iQuantity}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="query-col">
                            <el-form-item size="mini " label="累计发货：">
                                {{activeSplitcInv.iArrQty}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="query-row" style="height:30px">
                        <el-col :span="16" class="query-col">
                            <el-form-item size="mini" label="存货规格：">
                                {{activeSplitcInv.cInvStd}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="query-col">
                            <el-form-item size="mini" label="期望交期：">
                                {{activeSplitcInv.dArriveDate}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="query-row" style="height:30px">
                        <el-col :span="8" class="query-col">
                            <el-form-item size="mini " label="可拆数量：">
                                {{detachableTotal}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="query-col">
                            <el-form-item size="mini " label="每次数量：">
                                <el-input-number size="mini" v-model="toSplitQuantity" :min="0" :max="detachableTotal" label="每次拆分数量"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" size="mini" class="query-col">
                            <el-button type="success" icon="el-icon-tickets" size="mini" @click="toSplitPurchase">拆分</el-button>
                            <el-button type="primary" icon="el-icon-s-claim" size="mini" @click="toSaveSplit">保存</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </el-header>
            <!-- 供货计划数据展示区 -->
            <el-main style="padding-top:5px;padding-bottom:0px;">
                <el-table
                    :data="supplyPlanData"
                    id="supplyPlanExportExcel-table"
                    v-loading="loading"
                    element-loading-text="正在加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                    :height="supplyPlanTableHeight"
                    border
                    :stripe="true"
                    size="mini"
                    style="width: 100%;">
                    <el-table-column
                    type="index"
                    show-overflow-tooltip
                    min-width="5%">
                    </el-table-column>
                    <el-table-column
                    prop="splitQuantity"
                    label="供货数量"
                    sortable
                    show-overflow-tooltip
                    min-width="32%">
                        <template slot-scope="scope">
                            <el-input-number :disabled="scope.row.iLocking" size="mini" @change="changeSQuantity" v-model="scope.row.splitQuantity" :min="1" :max="activeSplitcInv.iQuantity" label="供货数量"></el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="supplyDate"
                    label="供货日期"
                    sortable
                    show-overflow-tooltip
                    min-width="32%">
                        <template slot-scope="scope">
                            <el-date-picker
                                :disabled="scope.row.iLocking"
                                v-model="scope.row.supplyDate"
                                align="right"
                                type="date"
                                size="mini"
                                placeholder="选择日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="操作"
                    min-width="31%">
                        <template slot-scope="scope">
                            <!-- <el-button type="primary" v-if="scope.row.cDefine37==null&&scope.row.setState==false&&venUser.iAdmin!=1" icon="el-icon-edit" size="mini" @click="upChange(scope.row,scope.$index)" style="margin-right:0px">
                                编辑
                            </el-button>
                            <el-button type="primary" v-if="scope.row.setState==true" icon="el-icon-finished" size="mini" @click="confirmChange(scope.row,scope.$index)" style="margin-right:0px">
                                修改
                            </el-button> -->
                            <el-button :disabled="scope.row.iLocking" type="danger" icon="el-icon-delete" size="mini" @click="delSupplyplan(scope.row,scope.$index)" style="margin-right:0px">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-main>
            <span slot="footer" class="dialog-footer">
                <el-button type="danger" icon="el-icon-circle-close" size="mini" @click="closeSplitPurchaseDet">关闭</el-button>
            </span>
        </el-dialog>
    </el-container>
</template>

<script>
export default {
    data(){
        return{
            //日期快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                    picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', date);
                    }
                }]
            },
            detachableTotal:0,//可拆数量
            toSplitQuantity:0,//每次拆分数量
            supplyPlanData:[],//查询到的供货计划数据
            loading:false,//是否显示记载界面的属性
        }
    },
    props:{
        showSplitPurchaseDet:Boolean,//是否显示拆分采购订单页的属性
        activeSplitcInv:Object,//要拆分的采购订单存货
    },
    computed:{
        //计算供货计划数据区域自适应高度
        supplyPlanTableHeight(){
            return document.documentElement.clientHeight-500;
        }
    },
    created(){
        //调用获取采购订单存货可拆分数量
        this.getPoDetachableTotal();
        //调用获取生产订单该存货供货计划的方法
        this.toGetSupplyPlanData();
    },
    methods:{
        /**
         * 关闭拆分采购订单页
         */
        closeSplitPurchaseDet(){
            this.$emit("closeSplitPurchaseDet",false);
        },

        /**
         * 获取生产订单该存货供货计划的方法
         */
        toGetSupplyPlanData(){
            let that=this;
            this.supplyPlanData=[];//先把之前查询到的供货计划数据清空
            this.loading=true;//打开加载界面
            this.$axios.post("/u8/supplyPlan/toGetSupplyPlanData",this.activeSplitcInv)
            .then((resp)=>{
                console.log('查询供货计划返回结果：',resp);
                if(resp.data.success=="true"){
                    this.supplyPlanData=resp.data.data;
                }else{
                    this.$message({
                        showClose:true,
                        message:resp.data.msg,
                        type:'warning',
                    });
                }
                this.loading=false;//关闭加载界面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('查询供货计划服务器出错了');
                    that.$message.error('查询供货计划服务器出错了');
                }
            );
        },

        /**
         * 获取采购订单存货可拆分数量
         */
        getPoDetachableTotal(){
            let that=this;
            this.$axios.post("/u8/supplyPlan/getPoDetachableTotal",this.activeSplitcInv)
            .then((resp)=>{
                console.log("查询可拆分数量返回：",resp);
                this.detachableTotal=resp.data.data;//设置可拆分数量
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('查询可拆分数量服务器出错了');
                    that.$message.error('查询可拆分数量服务器出错了');
                }
            );
        },

        /**
         * 拆分采购订单存货的方法
         */
        toSplitPurchase(){
            if(this.toSplitQuantity==0){
                this.$message({
                    showClose: true,
                    message: '请输入每次拆分的数量',
                    type: 'warning'
                });
                return;
            }
            let that=this;
            console.log('每次拆分数量是：',this.toSplitQuantity);
            let initSetachableTotal=this.detachableTotal;//声明初始化可以拆分的数量
            let splitCount=Math.ceil(initSetachableTotal/this.toSplitQuantity);//获取需要拆分成的供货计划数量（条数）
            let splitQuantityTotal=0;//声明累计已经拆分数量的总数
            let supplyPlan={};//声明本次要组件新增的供货计划
            console.log('可拆分的数量是：',splitCount);
            for (let i = 0; i < splitCount; i++) {
                supplyPlan={};//清空要新增的供货计划
                console.log('进了循环,i:',i);
                if(i==splitCount-1){//如果是拆分后的最后一条供货计划
                    let lastSplitNum=initSetachableTotal-splitQuantityTotal;//最后一个供货计划非拆分数量
                    //组装要新增的最后一个供货计划
                    supplyPlan={
                        id:'',
                        mainId:this.activeSplitcInv.id,//采购订单（委外订单）子表Id
                        splitQuantity:lastSplitNum,//拆分数量（可拆分数量-已经累加拆分数量总数）
                        supplyDate:'',//供货日期
                        sourcesType:'PO',//来源单类型（采购订单）
                        addState:true,//是否新增供货计划
                    }
                    console.log('最后要追加的是：',supplyPlan);
                    this.supplyPlanData.push(supplyPlan);//追加查询到供货计划数据后面
                    splitQuantityTotal=splitQuantityTotal+supplyPlan.splitQuantity;//累加已经拆分数量的总数
                    console.log('最后累计拆分数量是：',splitQuantityTotal);
                }else{
                    let splitNum=this.toSplitQuantity;//其余供货计划拆分数量
                    //组装要新增的供货计划
                    supplyPlan={
                        id:'',
                        mainId:this.activeSplitcInv.id,//采购订单（委外订单）子表Id
                        splitQuantity:splitNum,//拆分数量
                        supplyDate:'',//供货日期
                        sourcesType:'PO',//来源单类型（采购订单）
                        addState:true,//是否新增供货计划
                    }
                    console.log('要追加的是：',supplyPlan);
                    this.supplyPlanData.push(supplyPlan);//追加查询到供货计划数据后面
                    splitQuantityTotal=splitQuantityTotal+supplyPlan.splitQuantity;//累加已经拆分数量的总数
                    console.log('累计拆分数量是：',splitQuantityTotal);
                }
            }
            this.toSplitQuantity=0;//每次要拆分的数量
            console.log('组装好的供货计划是：',this.supplyPlanData);
            this.detachableTotal=0;
        },

        /**
         * 监听改变供货数量的方法
         */
        changeSQuantity(newValue,oldValue){
            //如果新值为未定义
            if(newValue==undefined){
                newValue=0
            }
            let differNum=0;//声明新值和旧值的差
            if(newValue>oldValue){//如果新值大于旧值
                console.log('新值大于旧值');
                differNum=newValue-oldValue;//设置新值和旧值的差
                this.detachableTotal=this.detachableTotal-differNum;//重新设置可拆分数量
            }else{//如果新值小于旧值
                console.log('新值小于旧值');
                differNum=oldValue-newValue;//设置新值和旧值的差
                this.detachableTotal=this.detachableTotal+differNum;//重新设置可拆分数量
            }
            if(this.detachableTotal<0){//如果剩余可拆分数量减去新值和旧值的差小于0
                this.$message({
                    showClose:true,
                    message:'超过了可拆分的数量:'+(-this.detachableTotal),
                    type:'warning'
                });
            }
        },

        /**
         * 保存拆分采购订单结果的方法
         */
        toSaveSplit(){
            console.log('要保存的是：',this.supplyPlanData);
            if(this.detachableTotal<0){//如果剩余可拆分数量减去新值和旧值的差小于0
                this.$message({
                    showClose:true,
                    message:'超过了可拆分的数量:'+(-this.detachableTotal),
                    type:'warning'
                });
                return;
            }
            //遍历当前的供货计划数据
            for (let i = 0; i < this.supplyPlanData.length; i++) {
                //如果供货计划有其中一个供货日期为空
                if(this.supplyPlanData[i].supplyDate==''||this.supplyPlanData[i].supplyDate==null){
                    this.$message({
                        showClose:true,
                        message:'有供货日期为空',
                        type:'warning'
                    });
                    return;
                }
            }
            if(this.supplyPlanData.length==0){//如果供货计划数据数量为0
                this.$message({
                    showClose:true,
                    message:'请先生成供货计划',
                    type:'warning'
                });
                return;
            }
            let splitCount=0;//声明拆分订单的总数量
            for (let i = 0; i < this.supplyPlanData.length; i++) {
                splitCount=splitCount+this.supplyPlanData[i].splitQuantity;//累加每一个拆分订单的数量
            }
            if(splitCount>this.activeSplitcInv.iQuantity){//如果拆分的每个供货计划订单数量大于订单的总数量
                this.$message({
                    showClose:true,
                    message:'拆分的供货计划数量合计大于订单总数量',
                    type:'warning'
                });
                return;
            }
            //调用向后端新增或修改供货计划的方法
            this.toAddOrUpdSupplyPlan(this.supplyPlanData);
        },

        /**
         * 向后端新增或修改供货计划的方法
         */
        toAddOrUpdSupplyPlan(supplyPlanData){
            let that=this;
            let addOrUpdParam={
                supplyPlanDataArr:supplyPlanData,//要新增或修改的供货计划数据
            }
            this.$axios.post("/u8/supplyPlan/toAddOrUpdSupplyPlan",addOrUpdParam)
            .then((resp)=>{
                
                if(resp.data.success=="true"){
                    this.$message({
                        showClose:true,
                        message:resp.data.msg,
                        type:'success'
                    });
                    //调用获取生产订单该存货供货计划的方法
                    this.toGetSupplyPlanData();
                    //调用父组件重新查询采购订单详情的方法
                    this.$emit("restQueryPurchaseDet");
                }else{
                    this.$message({
                        showClose:true,
                        message:resp.data.msg,
                        type:'warning'
                    });
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('新增或修改供货计划服务器出错了');
                    that.$message.error('新增或修改供货计划服务器出错了');
                }
            );
        },

        /**
         * 删除供货计划的方法
         * @param row 要删除的供货计划
         */
        delSupplyplan(row,index){
            console.log('要删除的供货计划是：',row);
            console.log('要删除的供货计划下标是：',index);
            this.supplyPlanData.splice(index,1);//供货计划数据中删除指定索引的元素
            console.log('剩余的供货计划是：',this.supplyPlanData);
            //把要删除的本次供货计划拆分数量加回可拆分数量
            this.detachableTotal=this.detachableTotal+row.splitQuantity;
            if(row.addState==false){//如果不是新增供货计划
                //需要从数据库中删除该供货计划
                //调用向后端删除供货计划的方法
                this.toDelSupplyPlan(row);
            }
        },

        /**
         * 向后端删除供货计划的方法
         * @param row 要删除的供货计划
         */
        toDelSupplyPlan(row){
            let that=this;
            this.$axios.post("/u8/supplyPlan/toDelSupplyPlan",row)
            .then((resp)=>{
                if(resp.data.success=="true"){
                    this.$message({
                        showClose:true,
                        message:resp.data.msg,
                        type:'success',
                    });
                    //调用获取生产订单该存货供货计划的方法
                    this.toGetSupplyPlanData();
                    //调用父组件重新查询采购订单详情的方法
                    this.$emit("restQueryPurchaseDet");
                }else{
                    this.$message({
                        showClose:true,
                        message:resp.data.msg,
                        type:'warning',
                    });
                }
            })
            .catch(
                function(error){
                    console.log(eror);
                    console.log('删除供货计划服务器出错了');
                    that.$message.error('删除供货计划服务器出错了');
                }
            );
        }
    }
}
</script>

<style>
    .splitPurchaseDetailsForm .el-dialog .el-dialog__body{
        padding:0px;
    }
</style>