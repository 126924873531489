<template>
    <!-- 采购订单 -->
    <el-container class="purchase-order-parent">
        <!-- 查询条件区域 -->
        <el-header class="query-condition" style="height:120px">
            <el-form :inline="true" :model="queryInline" class="demo-form-inline">
                <el-row class="query-row" style="height:40px">
                    <el-col :span="8" class="query-col">
                        <el-form-item label="日期范围">
                            <el-date-picker
                            v-model="queryInline.purchaseDate"
                            type="daterange"
                            align="right"
                            unlink-panels
                            size="mini"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width:220px"
                            :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col">
                        <el-form-item label="采购单号">
                            <el-input v-model="queryInline.proOrderNum" clearable size="mini" style="width:130px" placeholder="采购单号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col">
                        <el-form-item label="存货编码">
                            <el-input v-model="queryInline.inventoryCode" clearable size="mini" style="width:130px" placeholder="存货编码"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="query-row" style="height:40px">
                    <el-col :span="8" class="query-col">
                        <el-form-item label="存货名称">
                            <el-input v-model="queryInline.inventoryName" clearable size="mini" style="width:130px" placeholder="存货名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col">
                        <el-form-item label="规格型号">
                            <el-input v-model="queryInline.speci" clearable size="mini" style="width:130px" placeholder="规格型号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col" style="padding-left:24px;">
                        <el-form-item v-if="venUser.iAdmin==1" label="供应商">
                            <el-select filterable size="mini" clearable v-model="queryInline.cVenCode" placeholder="请选择">
                                <el-option
                                v-for="item in venOpt"
                                :key="item.cVenCode"
                                :label="item.cVenName "
                                :value="item.cVenCode">
                                    <span style="float: left">{{ item.cVenName }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px;margin-left:10px;">{{ item.cVenCode }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="query-row" style="height:40px">
                    <el-col :span="24" class="query-col" style="margin-top:10px">
                        <el-button type="warning" icon="el-icon-refresh" size="mini" @click="restQuery()">重置</el-button>
                        <el-button type="primary" icon="el-icon-search" size="mini" @click="queryPurchase()">查询</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <!-- 采购订单数据展示区域 -->
        <el-main class="data-show">
            <el-table
                :data="purchaseData.slice((queryInline.pageIndex-1)*queryInline.pageSize,queryInline.pageIndex*queryInline.pageSize)"
                stripe
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :height="tableHeight"
                :stripe="true"
                border
                ref="purchaseTable"
                size="mini"
                style="width: 100%;margin-bottom:5px"
                class="purchaseShow">
                <el-table-column
                type="index"
                width="50px">
                </el-table-column>
                <el-table-column
                prop="dPODate"
                label="日期"
                sortable
                show-overflow-tooltip
                min-width="100px">
                </el-table-column>
                <el-table-column
                prop="cPOID"
                label="采购单号"
                sortable
                show-overflow-tooltip
                min-width="80px">
                </el-table-column>
                <el-table-column
                prop="cVenName"
                label="供应商名称"
                sortable
                show-overflow-tooltip
                min-width="100px">
                </el-table-column>
                <el-table-column
                prop="cMemo"
                sortable
                label="备注"
                show-overflow-tooltip
                min-width="100px">
                </el-table-column>
                <el-table-column
                prop="cPersonName"
                sortable
                label="采购员"
                show-overflow-tooltip
                min-width="100px">
                </el-table-column>
                <el-table-column
                label="操作"
                min-width="100px">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页查询区 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="this.queryInline.pageIndex"
                :page-sizes="this.queryInline.pageSizes"
                :page-size="this.queryInline.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.queryInline.total">
            </el-pagination>
            <!-- 采购订单详情框 -->
            <el-dialog title="采购订单详情" :visible.sync="showPurchaseDet" @close='closePurchaseDet' width="95%" class="purDetailsForm">
                <Details @showDetails="changShowPurchaseDet" v-if="isRefresh" :purchase="selectpPurchase"></Details>
                <el-row style="margin-top:10px">
                    <el-col :span="24" style="margin-bottom:10px">
                        <el-button type="danger" size="mini" icon="el-icon-circle-close" @click="closePurchaseDet()">关闭</el-button>
                    </el-col>
                </el-row>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
import Details from '../purchase/PurchaseDetailsPage'
//从vuex中导入mapMutations，可以以集合的形式引入仓库中的修改器
import { mapMutations } from 'vuex';
export default {
    data(){
        return{
            //日历快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            venUser:{},//登录的供应商对象
            //查询参数
            queryInline:{
                purchaseDate:[],//日期范围数组
                proOrderNum:'',//采购单号
                inventoryCode:'',//存货编码
                inventoryName:'',//存货名称
                speci:'',//规格型号
                cVenCode:'',//供应商编码
                pageSizes:[10, 32, 64, 100, 200,300],//页面容量数组
                pageSize:10,//每页容量
                pageIndex:1,//当前页码
                total:0//查询结果总量
            },
            //向后端查询采购订单的参数
            queryParam:{
                purchaseDate:[],//日期范围数组
                proOrderNum:'',//采购单号
                inventoryCode:'',//存货编码
                inventoryName:'',//存货名称
                speci:'',//规格型号
                iAdmin:'',//供应商权限
                cVenCode:'',//供应商编码
            },
            tableHeight:window.innerHeight - 320,//数据展示区域自适应高度
            loading:false,//是否显示加载图标
            purchaseData:[],//查询到的采购订单数组
            showPurchaseDet:false,//是否显示采购订单详情页的属性
            selectpPurchase:'',//选中要查看采购订单详情的采购订单主表对象
            isRefresh:true,//是否刷新采购订单详情窗口子组件的属性
            venOpt:[],//供应商下列表
        }
    },
    watch:{
        "queryInline.purchaseDate"(newVal){//监控日期范围条件的绑定值
            if(newVal==null||newVal.length==0){//如果日期范围的绑定值为null
                this.queryInline.purchaseDate=[];//该日期范围绑定值设置为[]
                //初始化查询时间范围
                this.initDateRange();
            }
        }
    },
    created(){
        //调用设置查询中供应商的参数
        this.setVenUser();
        //初始化查询时间范围
        this.initDateRange();
        //初始化供应商下拉列表
        this.initVen();
        //调用向后端查询采购订单的方法
        this.toQueryPurchase();
    },
    methods:{
        /**
         * 初始化查询时间范围
         */
        initDateRange(){
            let toDay=new Date();//获取天时间
            toDay.setHours(0,0,0);//时分秒设置为零
            let startDate=new Date(toDay.getTime()-(1000*60*60*24*90));
            this.queryInline.purchaseDate.push(startDate);//设置开始时间
            this.queryInline.purchaseDate.push(toDay);//设置结束时间
            this.queryParam.purchaseDate=this.queryInline.purchaseDate;//设置向后端查询的时间范围
        },
        /**
         * 获取供应商下拉列表的方法
         */
        initVen(){
            let that=this;
            this.venOpt=[];//先清空之前查询到的供应商下拉列表
            console.log('查询供应商下拉列表');
            this.$axios.post("/u8/vendor/getVendor")
            .then((resp)=>{
                if (resp.data.success=='true') {
                    this.venOpt=resp.data.data;
                }else{
                    this.$message({
                        showClose:true,
                        type:'warning',
                        message:resp.data.msg
                    });
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('查询供应商下拉列表服务器出错了');
                    that.$message.error('查询供应商下拉列表服务器出错了');
                }
            );
        },
        /**
         * 设置查询中供应商的参数
         */
        setVenUser(){
            //获取上级父组件传进来的参数并赋值
            this.venUser=this.$store.state.UserToken;//路由中获取到的是json字符串，要转化为json对象
            this.queryParam.iAdmin=this.venUser.iAdmin;//供应商权限
            if (this.venUser.iAdmin==0) {//如果是供应商
                this.queryParam.cVenCode=this.venUser.cVenCode;//供应商编码
            }
        },
        /**
         * 清空查询条件的方法
         */
        restQuery(){
            this.queryInline.purchaseDate=[];//日期范围数组
            this.queryInline.proOrderNum='';//采购单号
            this.queryInline.inventoryCode='';//存货编码
            this.queryInline.inventoryName='';//存货名称
            this.queryInline.speci='';//规格型号
            this.queryInline.cVenCode='';//供应商编码
        },
        /**
         * 点击查询采购订单的方法
         */
        queryPurchase(){
            //查询之前清空数据
            this.purchaseData=[];
            this.queryInline.pageIndex=1;//查询前重置为查询第一页
            this.queryInline.total=0;//重置查询到的采购订单总数
            //设置向后端查询的查询参数
            this.queryParam.purchaseDate=this.queryInline.purchaseDate;//日期范围数组
            this.queryParam.proOrderNum=this.queryInline.proOrderNum;//采购单号
            this.queryParam.inventoryCode=this.queryInline.inventoryCode;//存货编码
            this.queryParam.inventoryName=this.queryInline.inventoryName;//存货名称
            this.queryParam.speci=this.queryInline.speci//规格型号
            if (this.venUser.iAdmin==1) {//如果是采购方
                this.queryParam.cVenCode=this.queryInline.cVenCode//供应商编码
            }
            //调用向后端查询采购订单的方法
            this.toQueryPurchase();
        },
        /**
         * 向后端查询采购订单的方法
         */
        toQueryPurchase(){
            //打开加载页面
            this.loading=true;
            this.$axios.post("/u8/purchase/getPurchase", this.queryParam)
            .then((resp)=>{
                if(resp.data.success=="false"){
                    this.$message(resp.data.msg);
                }
                if(resp.data.success=="true"){
                    this.purchaseData=resp.data.data;
                    this.queryInline.total=this.purchaseData.length;//查询到采购订单的总数
                }
                //关闭加载页面
                this.loading=false;
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('采购订单服务器出错了');
                    this.$message('采购订单服务器出错了');
                }
            );
        },
        //分页查询
        /**
         * 改变每页显示的数量
         */
        handleSizeChange(val) {
            this.queryInline.pageSize=val;//设置每页容量
            this.queryInline.pageIndex=1;//改总量后，初始页面改为1
        },
        /**
         * 改变开始页码
         */
        handleCurrentChange(val) {
            this.queryInline.pageIndex=val;//设置当前页码
        },
        /**
         * 查看采购订单详情的方法
         */
        handleClick(row){
            this.selectpPurchase=row;//设置选中的采购订单主表对象
            this.isRefresh=false;//是否刷新采购订单详情窗口子组件的属性
            this.$nextTick(() => {
                this.isRefresh= true;//强制刷新采购订单详情页子组件数据
            });
            this.showPurchaseDet=true;//打开采购订单详情框
        },
        /**
         * 关闭采购订单详情的方法
         */
        closePurchaseDet(){
            this.showPurchaseDet=false;//关闭采购订单详情框
        },
        /**
         * 子组件传值关闭详情窗口的方法
         */
        changShowPurchaseDet(data){
            this.showPurchaseDet=data;//关闭显示窗口
        }

    },
    components:{
        //引入采购订单详情页
        Details:Details,
    }
}
</script>

<style>
    /* 采购订单总框样式 */
    .purchase-order-parent{
        height: 100%;
    }
    /* 查询区域样式 */
    .query-condition{
        /* background-color: yellow; */
    }
    /* 查询条件每一行样式 */
    .query-row{
        height: 40px;
        margin-bottom: 0px;
    }
    /* 查询区域每个条件的高度 */
    .query-col{
        height: 40px;
        text-align: left;
        padding-left: 10px;
    }
    /* 数据展示区域 */
    .data-show{
        /* background-color: green; */
    }
    /* 解决标题和内容单元格错位 */
    body .el-table th.gutter {
        display: table-cell!important;
    }
    
    /* 解决表格固定列后覆盖滚动条 */
    /* 解决左列固定 */
    .el-table .el-table__fixed {
        height:auto !important;
        bottom:0px;
    }
    /* 解决右列固定 */
    .el-table .el-table__fixed-right {
        height:auto !important;
        bottom:0px;
    }

    /*滚动条覆盖*/
    .el-table__body-wrapper{
        z-index: 2;
    }

    /* 订单详情弹框样式 */
    .el-dialog{
        margin-top: 20px !important;
    }

</style>