<template>
    <!-- 报价单详情（供应商） -->
    <el-container>
        <!-- 表头 -->
        <el-header class="table-header" style="height:80px">
            <el-row style="text-align: left;padding-left:10px;margin-top:5px">
                <el-col :span="24">
                    <el-popconfirm v-if="venUser.iAdmin!=1&&isExamine!=-1&&isExamine!=1" title="审核后不能修改，确认审核？" @confirm="confirmOfferSup()">
                        <el-button size="mini" :disabled="isExamine==-1||isExamine==1" type="primary" icon="el-icon-s-claim" slot="reference" style="margin-left:10px;margin-right:10px">{{isExamine==1?'已经审核':isExamine==-1?'未保存报价':'确认审核'}}</el-button>
                    </el-popconfirm>
                    <el-button v-if="venUser.iAdmin!=1&&isExamine==-1" :disabled="offerSupDet.offerId!=''" size="mini" icon="el-icon-folder-checked" type="success" @click="saveOfferSup()" style="margin-left:10px;margin-right:10px">保存报价单</el-button>
                    <el-popconfirm v-if="venUser.iAdmin!=1&&isExamine!=-1&&isExamine!=1" title="取消报价后无法恢复，确认取消？" @confirm="delClick()">
                        <el-button type="danger" :disabled="isExamine==-1||isExamine==1" icon="el-icon-s-release" size="mini" style="margin-left:10px;margin-right:10px" slot="reference">{{isExamine==-1?'未保存报价':'取消报价'}}</el-button>
                    </el-popconfirm>
                </el-col>
            </el-row>
            <el-row style="margin-top:0px">
                <el-form :inline="true" :model="offerSupDet" class="demo-form-inline">
                    <el-row class="query-row">
                        <el-col :span="4" class="query-col">
                            <el-form-item label="单据编码：" style="margin-left:10px">
                                {{offerSupDet.inquiryCode}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" class="query-col">
                            <el-form-item label="单据日期：">
                                {{offerSupDet.inquiryDate}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="query-col">
                            <el-form-item label="业务员：">
                                {{offerSupDet.cPersonName}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="5" class="query-col" style="text-align: center;">
                            <el-form-item label="供应商：">
                                <span style="font-size: 12px;">{{offerSupDet.cVenName}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" class="query-col" style="text-align: center;">
                            <el-form-item label="报价类型：">
                                {{offerSupDet.offerType=='EM'?'报价':offerSupDet.offerType=='ED'?'调价':''}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" class="query-col" style="text-align: center;">
                            <el-form-item label="是否审核：">
                                <el-tag size="mini" :type="isExamine==1?'success':isExamine==-1?'warning':'danger'">{{isExamine==1?'已审核':isExamine==-1?'未生成报价单':'未审核'}}</el-tag>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-row>
            <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                width="30%"
                append-to-body
                style="margin-top:100px">
                <span>有报价已经存在供应商存货价格表中，是否需要覆盖？</span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confirmCoverage()">确 定</el-button>
                </span>
            </el-dialog>
        </el-header>
        <!-- 供应商报价单详情内容 -->
        <el-main>
            <el-table
                :data="offerSupData"
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :height="tableHeight"
                border
                size="mini"
                @row-dblclick="dbOpenChang($event)"
                style="width: 100%;margin-bottom: 0px;">
                <el-table-column
                type="index"
                fixed
                width="30">
                </el-table-column>
                <el-table-column
                prop="cInvName"
                label="存货名称"
                sortable
                fixed
                show-overflow-tooltip
                width="140">
                    <template slot-scope="scope">
                        <span v-if="scope.row.setState">
                            <el-row>
                                <el-col :span="12">
                                    {{scope.row.cInvName}}
                                </el-col>
                                <el-col :span="12" style="text-align: right;">
                                    <el-button size="mini" type="primary" icon="el-icon-search" circle @click="updInv(scope.row,scope.$index)"></el-button>
                                </el-col>
                            </el-row>
                        </span>
                        <span v-else>{{scope.row.cInvName}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                prop="cInvCode"
                label="存货编码"
                sortable
                fixed
                show-overflow-tooltip
                width="100">
                </el-table-column>
                <el-table-column
                prop="cInvStd"
                label="规格型号"
                sortable
                fixed
                show-overflow-tooltip
                width="100">
                </el-table-column>
                <el-table-column
                prop="cComUnitName"
                label="单位"
                sortable
                fixed
                show-overflow-tooltip
                width="100">
                </el-table-column>
                <el-table-column
                prop="plaQuantity"
                label="计划数量"
                sortable
                fixed
                show-overflow-tooltip
                width="95">
                </el-table-column>
                <el-table-column
                prop="demDate"
                label="需求日期"
                sortable
                show-overflow-tooltip
                width="100">
                </el-table-column>
                <el-table-column
                prop="lowQty"
                label="数量下限"
                sortable
                width="150">
                    <template slot-scope="scope">
                        <el-input-number size="mini" :disabled="!scope.row.setState" v-model="scope.row.lowQty" :min="0" :max="1000000000" controls-position="right" style="width:135px"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column
                prop="ceiQty"
                label="数量上限"
                sortable
                width="150">
                    <template slot-scope="scope">
                        <el-input-number size="mini" :disabled="!scope.row.setState" v-model="scope.row.ceiQty" :min="0" :max="1000000000" controls-position="right" style="width:135px"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column
                prop="iPerTaxRate"
                label="税率%"
                sortable
                width="150">
                    <template slot-scope="scope">
                        <el-input-number size="mini" :precision="2" :disabled="!scope.row.setState" v-model="scope.row.iPerTaxRate" :min="0" :max="100" controls-position="right" style="width:135px"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column
                prop="iTaxPrice"
                label="含税单价"
                sortable
                width="150">
                    <template slot-scope="scope">
                        <el-input-number size="mini" :precision="decimalLength" :disabled="!scope.row.setState" v-model="scope.row.iTaxPrice" :min="0" :max="1000000000" controls-position="right" style="width:135px"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column
                prop="noiTaxPrice"
                label="不含税单价"
                sortable
                width="150">
                    <template slot-scope="scope">
                        <el-input-number size="mini" :precision="decimalLength" :disabled="true" :value="calculationnoiTaxPrice(scope.row)" v-model="scope.row.noiTaxPrice" :min="0" :max="1000000000" controls-position="right" style="width:135px"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column
                prop="dEnableDate"
                label="生效日期"
                sortable
                width="150">
                    <template slot-scope="scope">
                        <el-date-picker
                            v-model="scope.row.dEnableDate"
                            :disabled="!scope.row.setState"
                            size="mini"
                            align="right"
                            type="date"
                            placeholder="选择日期"
                            :picker-options="pickerOptions"
                            style="width:125px">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column
                prop="deadline"
                label="失效日期"
                sortable
                width="150">
                    <template slot-scope="scope">
                        <el-date-picker
                            v-model="scope.row.deadline"
                            :disabled="!scope.row.setState"
                            size="mini"
                            align="right"
                            type="date"
                            placeholder="选择日期"
                            :picker-options="pickerOptions"
                            style="width:125px">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button v-if="venUser.iAdmin!=1&&isExamine!=1" type="primary" size="mini" :disabled="isExamine==1" @click="scope.row.setState?addChange(scope.row,scope.$index):upChange(scope.row,scope.$index)" style="margin-right:15px">
                            {{scope.row.setState?'保存':"修改"}}
                        </el-button>
                        <el-popconfirm v-if="venUser.iAdmin!=1&&isExamine!=1" :title="scope.row.setState?'确定要取消？':'确定要删除？'" @confirm="!scope.row.setState?delChange(scope.row,scope.$index):cancelChange(scope.row,scope.$index,true)">
                            <el-button size="mini" :disabled="isExamine==1" :type="!scope.row.setState?'danger':'warning'" slot="reference">
                                {{!scope.row.setState?'删除':"取消"}}
                            </el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <div class="el-table-add-row" v-if="(isExamine==-1||isExamine==0)&&venUser.iAdmin!=1" @click="addCommodity()" style="text-align: center;"><span>+ 添加</span></div>
        </el-main>
        <el-row>
            <el-col :span="24">
                <!-- 步骤条 -->
                <el-steps :active="isExamine+2" :align-center="true" space="50%">
                    <el-step title="选择询价单"></el-step>
                    <el-step title="设置并生成报价单"></el-step>
                    <el-step title="审核报价单"></el-step>
                </el-steps>
            </el-col>
        </el-row>
        <!-- 存货选择框 -->
        <el-dialog title="存货选择" append-to-body :visible.sync="showInvSelectDet" @close='closeInvSelectDet' width="60%" class="InvSelectForm">
            <InventorySelect v-if="isInvRefresh" @selInventory="selectChange" :isAddInv="isAddInv" :offerType="offerType" :venUser="venUser" :offerSupDet="offerSupDet"></InventorySelect>
            <el-row style="margin-top:20px">
                <el-col :span="24" style="margin-bottom:10px;text-align: center;">
                    <el-button type="danger" icon="el-icon-circle-close" @click="closeInvSelectDet()">关闭</el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </el-container>
</template>

<script>
import InventorySelect from '../inventory/InventorySelectPage'
export default {
    props:["offerSup","venUser","decimalLength"],
    created(){
        //调用检验打开的询价单是否已经生成供应商报价单
        this.checkOfferSup();
        //调用设置查询中供应商的参数
        // this.setVenUser();
        //根据报价单主表标识查找报价详情
        // this.getOfferSupDet();
    },
    data(){
        return{
            //日历快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                    picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', date);
                    }
                }]
            },
            loading:false,//是否显示加载图标
            //选中要查看详情的报价单对象
            offerSupDet:{
                offerId:'',//报价单主表标识
                inquiryCode:'',//单据编码
                inquiryDate:'',//日期
                cPersonCode:'',//业务员编码
                cPersonName:'',//业务员名称
                cVenCode:'',//供应商编码
                cVenName:'',//供应商
                isExamine:'',//供应商报价单审核标识
                enId:'',//询价单主表标识
                offerType:''//报价单类型
            },
            //供应商报价单详情数据
            offerSupData:[],
            oneSet:false,//标识是否有一行数据处在修改状态中
            isExamine:0,//该供应商报价单是否已经审核
            //打开修改状态是保存的存货信息
            keepInfo:{
                lowQty:'',//数量下限
                ceiQty:'',//数量上限
                iTaxPrice:'',//含税单价
                noiTaxPrice:'',//不含税单价
                iPerTaxRate:'',//税率
                dEnableDate:'',//生效日期
                deadline:''//失效日期
            },
            dialogVisible:false,//提示报价已经存在价格本中是否覆盖的提示框显示属性
            tableHeight:window.innerHeight - 445,//数据展示区域自适应高度
            showInvSelectDet:false,//是否显示存货选择框
            isInvRefresh:true,//是否强制刷新存货选择框的属性
            isAddInv:false,//是否新增存货的属性
            offerType:'OF',//报价单类型（主动报价）
        }
    },
    computed:{

    },
    methods:{
        /**
         * 计算不含税单价
         */
        calculationnoiTaxPrice(row){
            //不含税单价=含税单价/(1+(税率/100)）
            let noiTaxPrice=row.iTaxPrice/(1+(row.iPerTaxRate/100))
            row.noiTaxPrice=parseFloat(noiTaxPrice).toFixed(this.decimalLength);//计算出来的不含税单价保留两位小数
            return row;
        },
        /**
         * 计算含税单价
         */
        calculationiTaxPrice(row){
            //含税单价=不含税单价+（不含税单价*（税率/100））
            let iTaxPrice=row.noiTaxPrice+(row.noiTaxPrice*(row.iPerTaxRate/100));
            row.iTaxPrice=parseFloat(iTaxPrice).toFixed(this.decimalLength);//计算出来的含税单价保留两位小数
            return row;
        },
        /**
         * 清空要查询的报价单主表内容
         */
        clearOfferSup(){
            this.offerSupDet.inquiryCode='';//单据编码
            this.offerSupDet.inquiryDate='';//日期
            this.offerSupDet.cPersonCode='';//业务员编码
            this.offerSupDet.cPersonName='';//业务员名称
            this.offerSupDet.cVenCode='',//供应商编码
            this.offerSupDet.cVenName='';//供应商
            this.offerSupDet.isExamine='';//供应商报价单审核标识
            this.offerSupDet.offerType='';//供应商报价单类型
        },
        /**
         * 清空要查询的报价单子表详情
         */
        clearOfferSupDet(){
            this.offerSupData=[];//供应商报价单详情数据
        },
        /**
         * 检验打开的询价单是否已经生成供应商报价单
         */
        checkOfferSup(){
            console.log('offerSup是：',this.offerSup);
            //调用清空要查询的报价单主表内容的方法
            this.clearOfferSup();
            //调用清空要查询的报价单子表详情的方法
            this.clearOfferSupDet();
            let checkOfferSupParam={
                inquiryCode:this.offerSup.inquiryCode,//单据编码
                cVenCode:this.venUser.cVenCode//供应商主表
            }
            this.offerSupDet.enId=this.offerSup.enId;//设置该报价单对应的询价单主表标识
            this.$axios.post("/u8/offerSup/getOfferSup",checkOfferSupParam)
            .then((resp)=>{
                if(resp.data.success=="false"){//如果不存在，则是还没有生成供应商报价单
                    this.$message(resp.data.msg);
                    this.offerSupDet.inquiryCode=this.offerSup.inquiryCode;//设置选中的报价单对象的单据编码
                    this.offerSupDet.inquiryDate=this.offerSup.inquiryDate;//设置选中的报价单对象的日期
                    this.offerSupDet.cPersonCode=this.offerSup.cPersonCode;//设置选中的报价单对象的业务员编码
                    this.offerSupDet.cPersonName=this.offerSup.cPersonName;//设置选中的报价单对象的业务员名称
                    this.offerSupDet.cVenCode=this.venUser.cVenCode,//设置选中的报价单对象的供应商编码
                    this.offerSupDet.cVenName=this.venUser.cVenName;//设置选中的报价单对象的供应商
                    this.offerSupDet.isExamine=-1;//供应商报价单审核状态标识
                    this.offerSupDet.offerType='EM'//设置报价类型为询价
                    this.isExamine=-1;//全局报价单是否审核的标识
                }else{
                    this.offerSupDet.offerId=resp.data.data.offerId;//设置选中的报价单对象的报价单主表标识
                    this.offerSupDet.inquiryCode=resp.data.data.inquiryCode;//设置选中的报价单对象的单据编码
                    this.offerSupDet.inquiryDate=resp.data.data.inquiryDate;//设置选中的报价单对象的日期
                    this.offerSupDet.cPersonCode=resp.data.data.cPersonCode;//设置选中的报价单对象的业务员编码
                    this.offerSupDet.cPersonName=resp.data.data.cPersonName;//设置选中的报价单对象的业务员名称
                    this.offerSupDet.cVenCode=resp.data.data.cVenCode,//设置选中的报价单对象的供应商编码
                    this.offerSupDet.cVenName=resp.data.data.cVenName;//设置选中的报价单对象的供应商
                    this.offerSupDet.isExamine=resp.data.data.isExamine;//供应商报价单审核状态标识
                    this.offerSupDet.offerType=resp.data.data.offerType//设置报价类型为询价
                    this.isExamine=resp.data.data.isExamine;//全局报价单是否审核的标识
                    //调用根据报价单主表标识查询报价单子表（详情）的方法
                    this.getOfferSupDet(this.offerSupDet);
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('供应商报价单服务器出错了');
                    this.$message('供应商报价单服务器出错了');
                }
            );
        },
        /**
         * 保存存货信息的方法
         */
        addChange(row, index){
            if(row.cInvCode==''||row.cInvCode==null){
                return this.$message('请选择存货');
            }
            if(row.lowQty==undefined||row.ceiQty==undefined){
                return this.$message('数量上限或数量下限不能为空');
            }
            if(row.lowQty>0||row.ceiQty>0){
                if(row.lowQty==row.ceiQty){
                    return this.$message('请输入数量上限和下限,如果不设上下限则都输入0');
                }
            }
            if(row.lowQty>row.ceiQty){
                if(row.ceiQty!=0){
                    return this.$message('数量上限不能小于数量下限');
                }
            }
            if(row.iPerTaxRate==undefined){
                return this.$message('税率不能为空，可设为0');
            }
            if(row.noiTaxPrice==''||row.noiTaxPrice==0||row.noiTaxPrice==null){
                return this.$message('请输入不含税单价');
            }
            if(row.dEnableDate==null||row.dEnableDate==''){
                return this.$message('请选择生效日期');
            }
            if(row.deadline==null||row.deadline==''){
                return this.$message('请选择失效日期');
            }
            if(row.dEnableDate>row.deadline){
                return this.$message('失效日期不能早于生效日期');
            }
            //如果当前报价单对象的报价单主表标识不为空，则为查看
            if(this.offerSupDet.offerId!=''&&this.offerSupDet.offerId!=null){
                if(row.id!=''&&row.id!=null){//如果点击保存的当前行id不为空，则为修改
                    this.$axios.post("/u8/offerSup/updOfferSupDet",row)
                    .then((resp)=>{
                        this.$message(resp.data.msg);
                        if(resp.data.success=="false"){
                            return;
                        }
                    })
                    .catch(
                        function(error){
                            console.log(error);
                            console.log('供应商报价单详情服务器出错了');
                            this.$message('供应商报价单详情服务器出错了');
                        }
                    );
                }else{//如果点击保存的当前行id为空，则为新增
                    row.offerId=this.offerSupDet.offerId;//设置当前报价单子表的主表标识
                    this.$axios.post("/u8/offerSup/addOfferSupDet",row)
                    .then((resp)=>{
                        this.$message(resp.data.msg);
                        row.id=resp.data.data.id;//获取获取的自增Id
                    })
                    .catch(
                        function(error){
                            console.log(error);
                            console.log('供应商报价单详情服务器出错了');
                            this.$message('供应商报价单详情服务器出错了');
                        }
                    );
                }
            }
            //无论是新增报价单还是查看报价单，都需要关闭当前行的修改状态
            row.setState=false;//关闭修改状态
            this.oneSet=false;//标识有一行数据处在修改状态中为关闭
        },
        /**
         * 打开修改状态是保存存货信息
         */
        tokeepInfo(row){
            this.keepInfo.lowQty=row.lowQty;//数量下限
            this.keepInfo.ceiQty=row.ceiQty;//数量上限
            this.keepInfo.iTaxPrice=row.iTaxPrice;//含税单价
            this.keepInfo.noiTaxPrice=row.noiTaxPrice;//不含税单价
            this.keepInfo.iPerTaxRate=row.iPerTaxRate;//税率
            this.keepInfo.dEnableDate=row.dEnableDate;//生效日期
            this.keepInfo.deadline=row.deadline;//失效日期
        },
        /**
         * 恢复存货信息
         */
        recovery(row){
            row.lowQty=this.keepInfo.lowQty;//数量下限
            row.ceiQty=this.keepInfo.ceiQty;//数量上限
            row.iTaxPrice=this.keepInfo.iTaxPrice;//含税单价
            row.noiTaxPrice=this.keepInfo.noiTaxPrice;//不含税单价
            row.iPerTaxRate=this.keepInfo.iPerTaxRate;//税率
            row.dEnableDate=this.keepInfo.dEnableDate;//失效日期
            row.deadline=this.keepInfo.deadline;//失效日期
        },
        /**
         * 双击行数据打开修改状态
         */
        dbOpenChang(column){
            if(this.offerSupDet.isExamine==1){//如果该报价单供应商已经审核
                return;//不做任何操作
            }
            if(this.oneSet==true){
                if(column.setState==false){
                    return this.$message('请保存当前编辑项');
                }
            }
            //保存修改前的存货信息
            this.tokeepInfo(column);
            this.oneSet=true;//标识有一行数据处在修改状态中为打开
            column.setState=true;//打开修改状态
        },
        /**
         * 打开存货修改状态
         */
        upChange(row, index){
            if(this.oneSet==true){
                return this.$message('请保存当前编辑项');
            }
            //保存修改前的存货信息
            this.tokeepInfo(row);
            this.oneSet=true;//标识有一行数据处在修改状态中为打开
            row.setState=true;//打开修改状态
        },
        /**
         * 取消新增存货信息的方法
         */
        cancelChange(row, index, cg){
            //是否是取消操作
            if (cg) {
                if (!row.id) {
                    this.offerSupData.splice(index, 1);
                    this.oneSet=false;//标识有一行数据处在修改状态中为关闭
                }else{
                    //恢复存货信息
                    this.recovery(row);
                    this.oneSet=false;//标识有一行数据处在修改状态中为关闭
                    return row.setState = !row.setState;
                }
            }
        },
        /**
         * 删除存货对象信息的方法
         */
        delChange(row, index){
            if(this.oneSet==true){
                return this.$message('请保存当前编辑项');
            }
            if(this.offerSupDet.offerId!=''&&this.offerSupDet.offerId!=null){
                if(this.offerSupData.length==1){
                    return this.$message('只剩下一个存货信息，请往上一级删除报价单');
                }
            }
            this.offerSupData.splice(index, 1);
            if(row.id!=''){
                let delOfferSupParam={
                    id:row.id,
                    offerId:row.offerId,//报价单主表标识
                    cInvCode:row.cInvCode//存货编码
                }
                this.$axios.post("/u8/offerSup/toDelOfferSupDet",delOfferSupParam)
                .then((resp)=>{
                    this.$message(resp.data.msg)
                })
                .catch(
                    function(error){
                        console.log(error);
                        console.log('报价单详情服务器出错了');
                        this.$message('报价单详情服务器出错了');
                    }
                );
            }
        },
        /**
         * 审核报价单的方法
         */
        confirmOfferSup(){
            if(this.offerSupData.length==0){
                return this.$message('请添加存货信息');
            }
            if(this.oneSet==true){
                return this.$message('请保存当前编辑项');
            }
            let priceTotal=0;//报价单详情每个存货的含税单价合计
            for (let i = 0; i < this.offerSupData.length; i++) {
                priceTotal=priceTotal+this.offerSupData[i].iTaxPrice;//累加每个存货的含税单价
            }
            if(priceTotal==0){
                return this.$message('最少需要有一个存货已经报价');
            }
            //获取是否有报价已经存在供应商存货价格表中
            this.checkInvPrice(this.offerSupDet);
        },
        /**
         * 检查供应商存货价格表是否有重复报价的方法
         */
        checkInvPrice(offerSupDet){
            this.$axios.post("/u8/venInvPrice/checkInvPrice",offerSupDet)
            .then((resp)=>{
                let isRepeat=resp.data.success;//获取价格本是否有重复的标识
                if(isRepeat=='false'){
                    //调用改变报价单状态为审核的方法
                    this.updOfferSup(offerSupDet,isRepeat);
                }else{
                    this.dialogVisible=true;//打开提示报价已经存在价格本中的提示框
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('供应商报价单服务器出错了');
                    this.$message('供应商报价单服务器出错了');
                }
            );
        },
        /**
         * 改变报价单状态为审核并加入到价格本中的方法
         */
        updOfferSup(offerSupDet,isRepeat){
            let examineOfferParam={//审核报价单的参数
                offer:offerSupDet,//要审核的报价单主表对象
                isRepeat:isRepeat//价格本是否有重复的标识
            }
            this.$axios.post("/u8/offerSup/toExaOfferSup",examineOfferParam)
            .then((resp)=>{
                this.$message(resp.data.msg);
                if(resp.data.success=="true"){
                    this.offerSupDet.isExamine=1;//该报价单设置为已经审核状态
                    this.isExamine=1;//报价单全局设置为已经审核状态
                    if(this.dialogVisible==true){//如果提示已经有报价存在价格本中的提示框是打开状态
                        this.dialogVisible=false;//关闭提示有报价存在价格本的提示框
                    }
                    this.$emit('updOfferSup')//调用父组件刷新查询报价单主表的方法
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('供应商报价单服务器出错了');
                    this.$message('供应商报价单服务器出错了');
                }
            );
        },
        /**
         * 确认覆盖价格本的方法
         */
        confirmCoverage(){
            let offerSupDet=this.offerSupDet;//要审核的报价单主表对象
            let isRepeat='true';//价格本是否有重复的标识
            //调用改变报价单状态为审核的方法
            this.updOfferSup(offerSupDet,isRepeat);
        },
        /**
         * 保存报价单的方法
         */
        saveOfferSup(){
            if(this.oneSet==true){
                return this.$message('请保存当前编辑项');
            }
            if(this.venUser.iAdmin==1){
                return this.$message('超级用户不能生成报价单');
            }
            if(this.offerSupDet.cVenName==null||this.offerSupDet.cVenCode==null||this.offerSupDet.cVenName==''||this.offerSupDet.cVenCode==''){
                return this.$message('供应商名称或供应商编码为空');
            }
            let priceTotal=0;//标识所有存货的不含税单价总和
            for (let i = 0; i < this.offerSupData.length; i++) {
                priceTotal=priceTotal+this.offerSupData[i].noiTaxPrice;//累加每个存货的不含税单价
            }
            if(priceTotal==0){//如果最后所有存货的不含税单价总和为零，则没有报价
                return this.$message('最少需要有一个存货报价');
            }
            let addOfferSupAndDet={
                offerSup:this.offerSupDet,//供应商报价单主表对象
                offerSupDet:this.offerSupData//供应商报价单子表（详情）内容
            }
            this.$axios.post("/u8/offerSup/addOfferSupAndDet",addOfferSupAndDet)
            .then((resp)=>{
                this.$message(resp.data.msg);
                if(resp.data.success=="true"){
                    this.offerSupDet.offerId=resp.data.data.offerId;//返回设置供应商报价单主表标识
                    this.offerSupDet.isExamine=resp.data.data.isExamine;//返回设置供应商报价单审核状态
                    this.isExamine=resp.data.data.isExamine;//设置全局审核状态
                    //调用根据报价单主表标识查询报价单子表（详情）的方法
                    this.getOfferSupDet(this.offerSupDet);
                    this.$emit('updOfferSup')//调用父组件刷新查询报价单主表的方法
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('供应商报价单服务器出错了');
                    this.$message('供应商报价单服务器出错了');
                }
            );
        },
        /**
         * 根据报价单主表标识查询报价单子表（详情）的方法
         */
        getOfferSupDet(offerSupDet){
            //清空之前查询到的报价单子表（详情）
            this.offerSupData=[];
            let queryOfferSupDetParam={
                offerId:offerSupDet.offerId//报价单主表标识
            }
            this.loading=true;//显示加载页面
            this.$axios.post("/u8/offerSup/getOfferSupDet",queryOfferSupDetParam)
            .then((resp)=>{
                if(resp.data.success=="false"){
                    this.$message(resp.data.msg);
                }else{
                    this.offerSupData=resp.data.data;//设置查询到的供应商报价单详情
                }
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('供应商报价单子表服务器出错了');
                    this.$message('供应商报价单子表服务器出错了');
                }
            );
        },
        /**
         * 添加存货的方法
         */
        addCommodity() {
            if(this.oneSet==true){
                return this.$message('请保存当前编辑项');
            }
            this.isInvRefresh=false;//是否刷新存货选择窗口子组件的属性
            this.$nextTick(() => {
                this.isAddInv=true;//设置为是新增存货
                this.isInvRefresh=true;//强制刷新存货选择页子组件数据
            });
            //打开存货选择框
            this.showInvSelectDet=true;
        },
        /**
         * 选取存货获取存货信息的方法
         */
        getcInvInfo(row){
            let querycInvInfo={
                cInvCode:row.cInvCode,//存货编码
                enId:this.offerSupDet.enId//询价单主表标识
            }
            this.$axios.post("/u8/enquiry/getEnquirycInvInfoByCode",querycInvInfo)
            .then((resp)=>{
                row.cInvName=resp.data.data.cInvName;//存货名称
                row.cInvStd=resp.data.data.cInvStd;//存货规格
                row.cUnitID=resp.data.data.cUnitID;//计量单位编码
                row.cComUnitName=resp.data.data.cComUnitName;//计量单位名称
                row.plaQuantity=resp.data.data.plaQuantity;//计划数量
                row.demDate=resp.data.data.demDate;//需求日期
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('存货服务器出错了');
                    this.$message('存货服务器出错了');
                }
            );
        },
        /**
         * 删除整个报价单
         */
        delClick(){
            let delOfferParam={
                offerId:this.offerSupDet.offerId,//报价单主表标识
                inquiryCode:this.offerSupDet.inquiryCode//单据编码
            }
            this.$axios.post("/u8/offerSup/delOfferSupAndDet",delOfferParam)
            .then((resp)=>{
                this.$message(resp.data.msg);
                if(resp.data.success=="true"){
                    this.$emit('showDetails')//调用父组件关闭报价单详情的方法
                    this.$emit('updOfferSup')//调用父组件刷新查询报价单主表的方法
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('供应商报价单服务器出错了');
                    this.$message('供应商报价单服务器出错了');
                }
            );
        },
        /**
         * 子组件传回选中要询价的存货方法
         */
        selectChange(data){
            let row=data.row;//选中的存货信息
            this.isAddInv=data.isAddInv;//是新增存货还是修改存货
            //关闭存货选择框
            this.showInvSelectDet=false;
            this.oneSet=true;//标识有一行数据处在修改状态中为打开
            //根据选中的存货和询价单主表标识查询计划数量和需求日期
            if(this.isAddInv){//如果是新增存货
                //设置新增供应商报价存货数据行的格式
                let j = { id: "",offerId:"",cInvCode:row.cInvCode, cInvName: row.cInvName, cInvStd: row.cInvStd,cUnitID: row.cUnitID,cComUnitName: row.cComUnitName,plaQuantity:row.plaQuantity,demDate:row.demDate,lowQty:"",ceiQty:"",iTaxPrice:"",noiTaxPrice:"",iPerTaxRate:"",deadline:"", setState: true };
                //追加到新增存货报价单详情中
                this.offerSupData.push(j);
                this.sel = JSON.parse(JSON.stringify(j));
                //恢复不是为新增存货
                this.isAddInv=false;
            }else{//如果是修改存货
                this.offerSupData[this.invIndex].cInvName=row.cInvName;//存货名称
                this.offerSupData[this.invIndex].cInvCode=row.cInvCode;//存货编码
                this.offerSupData[this.invIndex].cInvStd=row.cInvStd;//规格型号
                this.offerSupData[this.invIndex].cComUnitName=row.cComUnitName;//单位
                this.invIndex='';//清空要修改询价单详情存货的下标
            }
        },
        /**
         * 打开修改存货的方法
         */
        updInv(row,index){
            this.isInvRefresh=false;//是否刷新存货选择窗口子组件的属性
            this.$nextTick(() => {
                this.isAddInv=false;//设置为是修改存货
                this.isInvRefresh=true;//强制刷新存货选择页子组件数据
            });
            //打开存货选择框
            this.showInvSelectDet=true;
            this.invIndex=index;//设置要修改询价单详情存货的下标
        },
        /**
         * 关闭存货选择框的方法
         */
        closeInvSelectDet(){
            //关闭存货选择框
            this.showInvSelectDet=false;
        },
    },
    components:{
        //引入存货选择框
        InventorySelect:InventorySelect,
    }
}
</script>
<style>
    /* 解决标题和内容单元格错位 */
    body .el-table th.gutter {
        display: table-cell!important;
    }
    
    /* 解决表格固定列后覆盖滚动条 */
    /* 解决左列固定 */
    .el-table .el-table__fixed {
        height:auto !important;
        bottom:0px;
    }
    /* 解决右列固定 */
    .el-table .el-table__fixed-right {
        height:auto !important;
        bottom:0px;
    }

    /*滚动条覆盖*/
    .el-table__body-wrapper{
        z-index: 2;
    }

    /* 添加按钮框样式 */
    .el-table-add-row{
        background-color: rgb(64,158,255);
        color: white;
        border-radius:5px;
        cursor:pointer;
        margin-top: 10px;
        height: 30px;
        line-height: 30px;
        border:1px dashed #000;
    }

    /* 步骤条样式 */
    .step {
        width: 1200px;
        margin: 44px auto 0;
    }
    .el-step__icon.is-icon {
        width: 10px;
        height: 10px;
    }
    .el-step__title.is-process {
        font-weight: 400;
        color: #303133;
    }
    .el-step__head.is-finish {
        color: #fca404;
        border-color: #fca404;
    }
    .el-step__title.is-finish {
        color: #fca404;
    }
    .el-step__description.is-finish {
        color: #fca404;
        height: 60px;
    }
    .el-step__head.is-finish {
        color: #fca404;
    }
    .el-step.is-horizontal .el-step__line {
        height: 2px;
        top: 10px;
    }
    .el-step{
        height: 70px !important;
    }

</style>