import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import axios from 'axios';
Vue.prototype.$axios = axios;
//引入vuex配置文件store
import store from './store'

//导入vue-router
import VueRouter from 'vue-router';
//导入路由器
import router from './router/index.js'

// vue中导出excel表格模板
import FileSaver from 'file-saver'
import XLSX from 'xlsx'

Vue.prototype.$FileSaver = FileSaver; //导出excel设置全局
Vue.prototype.$XLSX = XLSX; //导出excel设置全局

Vue.use(ElementUI);
//给Vue安装Vue-router插件
Vue.use(VueRouter);

Vue.config.productionTip = false

//axios请求拦截器
axios.interceptors.request.use(config=>{
  let token=JSON.parse(localStorage.getItem('UserToken'));//从stroe仓库中获取共享数据（用户token）
  if(token==null){//如果获取到的token是空，则还没登录
    console.log('token是空');
    return config;//允许请求
  }
  //全露守卫拦截生效时间
  let checkDate=new Date();
  //获取登录是的时间
  let loginDate=new Date(token.loginDate);
  //拦截生效和登录时间的时间差
  let timeDifference=checkDate.getTime()-loginDate.getTime()
  if(timeDifference>1200000){//如果时间差大于20分钟则超时需要重新登录
      localStorage.removeItem('UserToken');//移除本地存储数据
      location.href = "/";//强制跳转到登录页面
  }else{
      token.loginDate=checkDate;//重新赋值登录时间
      localStorage.setItem('UserToken', JSON.stringify(token));//设置本地存储的 name/value
      return config;//允许请求
  }
},error => {
  Promise.reject(error);
});

new Vue({
  el: '#app',
  router,//在创建vm对象的时候，把路由器注入
  store,//注入store仓库
  render: h => h(App),
}).$mount('#app')
