<template>
    <!-- 合并发货订单详情页 -->
    <el-container>
        <!-- 打开的采购（委外）订单内容 -->
        <el-header class="header-purOrout" style="height:30px">
            <el-form :inline="true" :model="purOrOutDet" class="demo-form-inline">
                <el-row class="query-row">
                    <el-col :span="8" class="query-col">
                        <el-form-item :label="purOrOutDet.cSourceType=='PO'?'采购单号':'委外单号'">
                            {{purOrOutDet.cID}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col">
                        <el-form-item label="单据日期：">
                            {{purOrOutDet.dDate}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col">
                        <el-form-item label="供应商：">
                            {{purOrOutDet.cVenName}}
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <!-- 采购（采购）订单详情内容 -->
        <el-main>
            <el-table
                :data="purOrOutDetData"
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                height="300"
                border
                :stripe="true"
                row-key="parentId"
                :default-expand-all="false"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                size="mini"
                style="width: 100%;">
                <el-table-column
                type="index"
                fixed
                show-overflow-tooltip
                min-width="5%">
                </el-table-column>
                <el-table-column
                prop="cInvCode"
                label="存货编码"
                sortable
                fixed
                show-overflow-tooltip
                min-width="8%">
                    <template slot-scope="scope">
                        <span v-if="scope.row.level==1">
                            {{scope.row.cInvCode}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                prop="cInvName"
                label="存货名称"
                sortable
                fixed
                show-overflow-tooltip
                min-width="9%">
                    <template slot-scope="scope">
                        <span v-if="scope.row.level==1">
                            {{scope.row.cInvName}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                prop="cInvStd"
                label="规格型号"
                sortable
                fixed
                show-overflow-tooltip
                min-width="9%">
                </el-table-column>
                <el-table-column
                prop="iQuantity"
                label="订单数量"
                sortable
                fixed
                show-overflow-tooltip
                min-width="8%">
                </el-table-column>
                <el-table-column
                prop="iTaxPrice"
                label="含税单价"
                v-if="venUser.iAdmin==1?true:venUser.iPrice==1?true:false"
                sortable
                show-overflow-tooltip
                min-width="8%">
                </el-table-column>
                <el-table-column
                prop="iPerTaxRate"
                label="税率"
                v-if="venUser.iAdmin==1?true:venUser.iPrice==1?true:false"
                sortable
                show-overflow-tooltip
                min-width="6%">
                </el-table-column>
                <el-table-column
                prop="iSum"
                label="原币价税合计"
                v-if="venUser.iAdmin==1?true:venUser.iPrice==1?true:false"
                sortable
                show-overflow-tooltip
                min-width="9%">
                </el-table-column>
                <el-table-column
                prop="iArrQty"
                label="累计送货"
                sortable
                show-overflow-tooltip
                min-width="8%">
                </el-table-column>
                <el-table-column
                prop="fPoRetQuantity"
                label="累计退货"
                sortable
                show-overflow-tooltip
                min-width="8%">
                </el-table-column>
                <el-table-column
                prop="cComUnitName"
                label="单位"
                sortable
                show-overflow-tooltip
                min-width="6%">
                </el-table-column>
                <el-table-column
                prop="dArriveDate"
                label="期望交期"
                sortable
                show-overflow-tooltip
                min-width="8%">
                </el-table-column>
                <el-table-column
                prop="firmArriveDate"
                label="确认交期"
                sortable
                show-overflow-tooltip
                min-width="8%">
                </el-table-column>
            </el-table>
        </el-main>
    </el-container>
</template>

<script>
export default {
    props:["purOrOut","venUser"],
    created(){
        this.purOrOutDet=this.purOrOut;//设置要查询采购(委外)订单详情的采购(委外)订单
        //根据采购（委外）订单主表标识查找采购（委外）订单详情
        this.getPurOrOutDet();
    },
    data(){
        return{
            purOrOutDet:{},//要查询采购(委外)订单详情的采购(委外)订单
            purOrOutDetData:[],//查询到的采购（委外）订单详情数据
            loading:false,//是否显示加载图标
        }
    },
    methods:{
        /**
         * 根据采购（委外）订单主表标识查找采购（委外）订单详情
         */
        getPurOrOutDet(){
            //查询前清空之前查询到的数据
            this.purOrOutDetData=[];
            let queryPurOrOutDetParam={
                poOrOutId:this.purOrOutDet.poOrOutId,//采购（委外）订单主表标识
                cSourceType:this.purOrOutDet.cSourceType//来源单类型
            }
            this.loading=true;//显示加载页面
            this.$axios.post("/u8/delivery/getPurOrOutDet",queryPurOrOutDetParam)
            .then((resp)=>{
                console.log("查询采购（委外）返回详情是：",resp);
                this.purOrOutDetData=resp.data.data;//设置查询到的采购（委外）订单详情
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('采购（委外）订单详情服务器出错了');
                    this.$message('采购（委外）订单详情服务器出错了');
                }
            );
        }
    }
}
</script>

<style>

</style>