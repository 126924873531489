<template>
    <!-- 确认合并发货页 -->
    <el-container>
        <!-- 打开的采购订单内容 -->
        <el-header class="header-arrival" style="height:50px">
            <el-form :inline="true" class="demo-form-inline">
                <el-row class="query-row">
                    <el-col :span="6" class="query-col">
                        <el-form-item label="快递单号：">
                            <el-input v-model="txtcDefine14" size="mini" clearable style="width:130px" placeholder="请输入快递单号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="query-col">
                        <el-form-item label="送货备注：">
                            <el-input v-model="txtcMemo" size="mini" clearable style="width:130px" placeholder="请输入送货备注"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="query-col">
                        <el-form-item label="送货日期：">
                            <el-date-picker
                                v-model="deliverTime"
                                size="mini"
                                align="right"
                                type="date"
                                placeholder="选择日期"
                                style="width:130px"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="query-col" style="text-align: center;">
                        <el-button type="warning" icon="el-icon-refresh" size="mini" @click="restParam()" style="margin-top:10px">重置</el-button>
                        <el-button type="primary" icon="el-icon-truck" size="mini" @click="deliverGoods()" style="margin-top:10px">发货</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <!-- 订单详情内容 -->
        <el-main>
            <el-table
                :data="arrivalData"
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :height="tableHeight"
                :stripe="true"
                border
                size="mini"
                style="width: 100%;"
                @row-dblclick="dbOpenChang($event)"
                row-key="aId"
                default-expand-all
                :tree-props="{children: 'giveChildren', hasChildren: 'hasChildren'}">
                <el-table-column
                type="index"
                fixed
                show-overflow-tooltip
                width="50px">
                </el-table-column>
                <el-table-column
                property="poOrOmID"
                show-overflow-tooltip
                fixed
                :label="sentType=='PO'?'采购订单号':'委外订单号'"
                width="100px">
                </el-table-column>
                <el-table-column
                property="dDate"
                label="单据日期"
                fixed
                show-overflow-tooltip
                width="100px">
                </el-table-column>
                <el-table-column
                prop="cInvCode"
                label="存货编码"
                sortable
                fixed
                show-overflow-tooltip
                width="100px">
                </el-table-column>
                <el-table-column
                prop="cInvName"
                label="存货名称"
                sortable
                fixed
                show-overflow-tooltip
                width="100px">
                </el-table-column>
                <el-table-column
                prop="cInvStd"
                label="规格型号"
                sortable
                fixed
                show-overflow-tooltip
                width="100px">
                </el-table-column>
                <el-table-column
                prop="iQuantity"
                label="订单数量"
                sortable
                fixed
                show-overflow-tooltip
                width="100px">
                </el-table-column>
                <el-table-column
                prop="iTaxPrice"
                label="含税单价"
                v-if="venUser.iAdmin==1?true:venUser.iPrice==1?true:false"
                sortable
                show-overflow-tooltip
                width="95px">
                </el-table-column>
                <el-table-column
                prop="iPerTaxRate"
                label="税率"
                v-if="venUser.iAdmin==1?true:venUser.iPrice==1?true:false"
                sortable
                show-overflow-tooltip
                width="70px">
                </el-table-column>
                <el-table-column
                prop="iSum"
                label="原币价税合计"
                v-if="venUser.iAdmin==1?true:venUser.iPrice==1?true:false"
                sortable
                show-overflow-tooltip
                width="120px">
                </el-table-column>
                <el-table-column
                prop="iArrQty"
                label="累计送货"
                sortable
                show-overflow-tooltip
                width="95px">
                </el-table-column>
                <el-table-column
                prop="fPoRetQuantity"
                label="累计退货"
                sortable
                show-overflow-tooltip
                width="95px">
                </el-table-column>
                <el-table-column
                prop="cComUnitName"
                label="单位"
                sortable
                show-overflow-tooltip
                width="70px">
                </el-table-column>
                <el-table-column
                prop="thisiQty"
                label="本次数量"
                sortable
                show-overflow-tooltip
                width="180px">
                    <template slot-scope="scope">
                        <template v-if="scope.row.bGiveaway==true">
                            <span>{{scope.row.thisiQty}}</span>
                        </template>
                        <template v-else>
                            <span v-if="scope.row.setState">
                                <el-input-number size="mini" :precision="2" :disabled="!scope.row.setState" v-model="scope.row.thisiQty" controls-position="right" @change="listenNum(scope.row)" :min="0" :max="scope.row.iQuantity-scope.row.iArrQty+scope.row.fPoRetQuantity-scope.row.fPoRetQuantity" style="width:150px"></el-input-number>
                            </span>
                            <span v-else>{{scope.row.thisiQty}}</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="bGiveaway"
                    label="赠品"
                    sortable
                    show-overflow-tooltip
                    width="70px">
                    <template slot-scope="scope">
                        <el-tag size="mini" :type="scope.row.bGiveaway==true?'':'success'">{{scope.row.bGiveaway==true?'是':'否'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                prop="cBatch"
                label="批号"
                sortable
                show-overflow-tooltip
                width="115px">
                    <template slot-scope="scope" v-if="scope.row.bGiveaway==false">
                        <span v-if="scope.row.setState">
                            <el-input size="mini" :disabled="scope.row.bInvBatch==0?true:false"  clearable v-model="scope.row.cBatch" style="width:90px" placeholder="批号"></el-input>
                        </span>
                        <span v-else>{{scope.row.cBatch}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                prop="dPDate"
                label="生产日期"
                sortable
                width="150px">
                    <template slot-scope="scope" v-if="scope.row.bGiveaway==false">
                        <el-date-picker
                            v-model="scope.row.dPDate"
                            :disabled="scope.row.setState==false?true:scope.row.bInvQuality==0?true:false"
                            size="mini"
                            align="right"
                            type="date"
                            placeholder="选择日期"
                            style="width:125px"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column
                prop="dArriveDate"
                label="期望交期"
                sortable
                show-overflow-tooltip
                width="95px">
                </el-table-column>
                <el-table-column
                prop="cbMemo"
                label="备注"
                sortable
                show-overflow-tooltip
                width="115px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.setState">
                            <el-input size="mini" :disabled="!scope.row.setState" clearable v-model="scope.row.cbMemo" style="width:90px" placeholder="备注" ></el-input>
                        </span>
                        <span v-else>{{scope.row.cbMemo}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="270px">
                    <template slot-scope="scope">
                        <el-button type="primary" :icon="!scope.row.setState?'el-icon-edit':'el-icon-finished'" v-if="(scope.row.iQuantity!=scope.row.iArrQty)||(scope.row.bGiveaway==true)" size="mini" @click="!scope.row.setState?upChange(scope.row,scope.$index):confirmChange(scope.row,scope.$index)" style="margin-right:0px">
                            {{!scope.row.setState?'修改':"确认"}}
                        </el-button>
                        <el-button size="mini" type="danger" icon="el-icon-refresh" v-if="scope.row.setState&&scope.row.bGiveaway==false" slot="reference" @click="restChange(scope.row,scope.$index)" style="margin-left:5px;">
                            重置
                        </el-button>
                        <el-popover
                            placement="right"
                            width="250"
                            trigger="click">
                            <el-table 
                                :data="scope.row.supplyPlanList"
                                v-loading="supplyLoading"
                                element-loading-text="正在加载中"
                                element-loading-spinner="el-icon-loading"
                                element-loading-background="rgba(0, 0, 0, 0.8)"
                                :stripe="true"
                                size="mini"
                                max-height="200px">
                                <el-table-column width="120" property="splitQuantity" label="供货数量"></el-table-column>
                                <el-table-column width="100" property="supplyDateStr" label="供货日期"></el-table-column>
                            </el-table>
                            <el-button size="mini" type="success" icon="el-icon-tickets" slot="reference" style="margin-left:5px;">计划</el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
    </el-container>
</template>

<script>
import { formatDate } from '@/common/date.js';
export default {
    props:["sents","sentType","user","bInspeDate"],
    created(){
        console.log('合并发货是否控制交期：',this.bInspeDate);
        this.selectpSents=this.sents;//设置选中要合并发货的订单数组
        this.sentcSourceType=this.sentType;//设置要合并发货的来源单类型
        this.venUser=this.user;//设置登录的供应商对象
        //调用根据合并发货订单数组查询合并订单详情的方法
        this.getComSentDet();
    },
    data(){
        return{
            //日历快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                    picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', date);
                    }
                }]
            },
            tableHeight:window.innerHeight - 450,//数据展示区域自适应高度
            selectpSents:[],//选中要合并发货的订单数组
            sentcSourceType:'',//要合并发货的来源单类型
            txtcDefine14:'',//快递单号
            txtcMemo:'',//送货备注
            deliverTime:new Date(new Date().setHours(0, 0, 0, 0)),//送货日期
            arrivalData:[],//订单的详情数据
            oneSet:false,//是否有一行存货数据正在被修改的属性
            venUser:{},//登录的供应商对象
            loading:false,//是否显示加载图标
            supplyPlanData:[],//查询到的每个存货供货计划
            supplyLoading:false,//是否显示供货计划加载中界面
        }
    },
    methods:{
        /**
         * 重置表头参数的方法
         */
        restParam(){
            this.txtcDefine14='';//快递单号
            this.txtcMemo='';//送货备注
            this.deliverTime=new Date;//送货日期
        },
        /**
         * 根据合并发货订单数组查询合并订单详情
         */
        getComSentDet(){
            let that=this;
            this.arrivalData=[];//查询之前清空之前查询到的数据
            let queryComSentDetParam={
                selectpSents:this.selectpSents,//选中要合并发货的订单数组
                sentcSourceType:this.sentcSourceType//要合并发货的订单来源单类型
            }
            this.loading=true;//打开加载页面
            this.$axios.post("/u8/draft/getComSentDet",queryComSentDetParam)
            .then((resp)=>{
                if(resp.data.success=="false"){
                    this.$message(resp.data.msg);
                }else{
                    this.arrivalData=resp.data.data;//设置查询到的合并订单详情
                }
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('采购订单或委外订单详情服务器出错了');
                    that.$message.error('采购订单或委外订单详情服务器出错了');
                }
            );
        },
        /**
         * 确认当行采购订单详情行数据
         */
        confirmChange(row,index){
            if(row.thisiQty==undefined){
                return this.$message('本次数量不能为空，不发货请输入0');
            }
            row.setState=false;
            this.oneSet=false;//设置存货处在修改状态为关闭
        },
        /**
         * 双击行数据打开修改状态
         */
        dbOpenChang(column){
            if(this.oneSet==true){
                if(column.setState==false){
                    return this.$message('请先确认当前编辑项');
                }
            }else{
                if (column.bGiveaway==true) {//如果是赠品
                    column.setState=true;
                    this.oneSet=true;//设置有一行存货在处于修改状态
                }else{//如果不是赠品
                    if(column.iQuantity==column.iArrQty){
                        return this.$message('该存货已经发货完毕，可以到送货草稿或送货记录中查看');
                    }else{
                        column.setState=true;
                        this.oneSet=true;//设置有一行存货在处于修改状态
                    }
                }
            }
        },
        /**
         * 设置改行为修改状态
         */
        upChange(row, index){
            if(this.oneSet==true){
                this.$message('请先确认当前编辑项');
            }else{
                if (row.bGiveaway==true) {//如果是赠品
                    row.setState=true;
                    this.oneSet=true;//设置有一行存货在处于修改状态
                }else{//如果不是赠品
                    if(row.iQuantity==row.iArrQty){
                        this.$message('该存货已经发货完毕，可以到送货草稿或送货记录中查看');
                    }else{
                        row.setState=true;
                        this.oneSet=true;//设置有一行存货在处于修改状态
                    }
                }
            }
        },
        /**
         * 重置当前行订单详情行数据
         */
        restChange(row, index){
            row.thisiQty='';//本次数量
            row.cBatch='';//批号
            row.dPDate='';//生产日期
            row.firmArriveDate='';//确认交期
            row.cbMemo='';//备注
        },
        /**
         * 发货的方法
         */
        deliverGoods(){
            if(this.oneSet==true){
                this.$message('请先确认当前编辑项');
            }else{
                if(this.deliverTime==null||this.deliverTime==''){
                    return this.$message('请选择发货日期');
                }
                let total='';//所有存货的发货总量
                for (let i = 0; i < this.arrivalData.length; i++) {
                    total=total+this.arrivalData[i].thisiQty;//计算所有存货的本次数量合计数
                }
                if(total==0){//如果合计数为0，即都没有设置本次数量
                    return this.$message('所有存货的本次数量都为空');
                }
                //如果没有找到发货是否检验交期的参数
                if (this.bInspeDate==undefined||this.bInspeDate==null||this.bInspeDate=='') {
                    return this.$message({
                        showClose:true,
                        type:'warning',
                        message:'没有设置发货是否检验交期参数'
                    })
                }
                console.log('发货时，检验是否检验交期参数是：',this.bInspeDate);
                //如果发货需要验证交期
                if (this.bInspeDate=='True') {
                    //检验交期是否通过
                    let checkDate=this.checkArriveDate();
                    if (checkDate==false) {
                        return this.$message({
                            showClose:true,
                            type:'warning',
                            message:'有存货数量早于供货计划'
                        });
                    }
                }
                //检验是否有批号或生产日期没填写
                let check=this.checkBatchAndDate();
                if(check==true){
                    let deliverParam={
                        txtcDefine14:this.txtcDefine14,//快递单号
                        txtcMemo:this.txtcMemo,//送货备注
                        deliverTime:this.deliverTime,//发货日期
                        venUser:this.venUser,//登录的供应商对象
                        sentcSourceType:this.sentcSourceType,//合并发货订单来源单类型
                        arrivalData:this.arrivalData//发货详情数据
                    }
                    this.$axios.post("/u8/draft/deliverGoods",deliverParam)
                    .then((resp)=>{
                        this.$message(resp.data.msg);
                        if(resp.data.success=="true"){//如果发货成功
                            let restParam={
                                showSentDet:false,//关闭确认合并发货窗口
                                selectpDraft:resp.data.data//成功新增的送货草稿主表对象
                            }
                            this.$emit('showSentDetails',restParam)//向父组件传值关闭确认合并发货窗口
                        }
                    })
                    .catch(
                        function(error){
                            console.log(error);
                            console.log('发货订单服务器出错了');
                            this.$message('发货订单服务器出错了');
                        }
                    );
                }else{
                    let that=this;
                    for (let i = 0; i < check.length; i++) {
                        setTimeout(function(){
                            that.$notify({
                                title: '注意',
                                message: check[i],
                                position: 'top-left',
                                type: 'warning'
                            });
                        },500);
                    }
                }
            }
        },
        //检验是否有批号或者生产日期没有填写
        checkBatchAndDate(){
            let errStr=[];//保存报错信息的数组
            for (let i = 0; i < this.arrivalData.length; i++) {
                //如果要送货的存货本次数量不为0
                if(this.arrivalData[i].thisiQty==0||this.arrivalData[i].thisiQty==''||this.arrivalData[i].thisiQty==null){
                    //如果本次为0，则不发货，不做判断
                }else{
                    //如果需要设置批号
                    if(this.arrivalData[i].bInvBatch==1){
                        if(this.arrivalData[i].cBatch==''||this.arrivalData[i].cBatch==null){//如果没有填写批号
                            //要返回的内容
                            let restStr="";
                            restStr=restStr+"存货编码为："+this.arrivalData[i].cInvCode+"；\n序号为："+(i+1)+"的存货需要设置批号";
                            //如果除了要设置批号之外还要设置生产日期
                            if(this.arrivalData[i].bInvQuality==1&&(this.arrivalData[i].dPDate==''||this.arrivalData[i].dPDate==null)){
                                restStr=restStr+"和生产日期";
                            }
                            errStr.push(restStr);
                        }else if(this.arrivalData[i].cBatch!=''&&this.arrivalData[i].cBatch!=null){//如果已经填写了批号
                            //如果除了要设置批号之外还要设置生产日期
                            if(this.arrivalData[i].bInvQuality==1&&(this.arrivalData[i].dPDate==''||this.arrivalData[i].dPDate==null)){
                                errStr.push("存货编码为："+this.arrivalData[i].cInvCode+"；\n序号为："+(i+1)+"的存货需要设置生产日期");
                            }
                        }
                    }
                    //如果不需要设置批号
                    if(this.arrivalData[i].bInvBatch==0){
                        //如果要设置生产日期
                        if(this.arrivalData[i].bInvQuality==1&&(this.arrivalData[i].dPDate==''||this.arrivalData[i].dPDate==null)){
                            errStr.push('存货编码为：'+this.arrivalData[i].cInvCode+'；\n序号为：'+(i+1)+'的存货需要设置生产日期');
                        }
                    }
                    
                }
            }
            if(errStr.length>0){
                return errStr;
            }
            return true;
        },
        /**
         * 检验交期是否通过
         */
        checkArriveDate(){
            for (let i = 0; i < this.arrivalData.length; i++) {
                //如果要检验的送货草稿明细有本次发货数量，并且不是赠品
                if ((this.arrivalData[i].thisiQty!=null&&this.arrivalData[i].thisiQty!=0&&this.arrivalData[i].thisiQty!='')
                    &&(this.arrivalData[i].bGiveaway==false)) {
                    //计算累计发货和本次发货的数量总和
                    let qtyTotal=this.arrivalData[i].iArrQty+this.arrivalData[i].thisiQty;
                    //获取该送货详情的供货计划
                    let supplyPlanList=this.arrivalData[i].supplyPlanList;
                    if (supplyPlanList.length>0) {//如果有供货计划
                        let suppTotal=0;//供货计划累计数
                        for (let j = 0; j < supplyPlanList.length; j++) {
                            suppTotal=suppTotal+supplyPlanList[j].splitQuantity;
                            // 如果累计发货数量小于遍历到最近的供货计划供货数量累计数
                            if (qtyTotal<=suppTotal) {
                                if (this.deliverTime.getTime()<new Date(supplyPlanList[j].supplyDate).getTime()) {
                                    return false;
                                }
                                break;
                            }
                        }
                    }else{//如果没有供货计划
                        //获取交期
                        let dArriveDate=new Date(this.arrivalData[i].dArriveDate);
                        dArriveDate.setHours(0,0,0,0);
                        if (this.deliverTime.getTime()<dArriveDate.getTime()){
                            return false;
                        }
                    }
                }
            }
            return true;
        },
        /**
         * 显示单个存货的供货计划
         */
        showSupplyPlan(row){
            let that=this;
            this.supplyPlanData=[];//清空之前查询到的单个供货计划
            this.supplyLoading=true;//显示加载界面
            this.$axios.post("/u8/supplyPlan/toComSentshowSupplyPlan",row)
            .then((resp)=>{
                if(resp.data.success=="true"){
                    this.supplyPlanData=resp.data.data;
                }else{
                    this.$message({
                        showClose:true,
                        message:resp.data.msg,
                        type:'warning'
                    });
                }
                this.supplyLoading=false;
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('查询单个存货供货计划服务器出错了');
                    that.$message.error('查询单个存货供货计划服务器出错了');
                }
            );
        },
        /**
         * 监听发货数量的变化
         */
        listenNum(row){
            let thisiQtyTotal=0;//声明发货数量改变相同存货的发货总数量
            for (let i = 0; i < this.arrivalData.length; i++) {
                //如果找到了发货数量变化对应的不是赠品的发货详情
                if((row.cInvCode==this.arrivalData[i].cInvCode)&&(this.arrivalData[i].bGiveaway==false)){
                    //累加相同存货发货详情的本次发货数量
                    thisiQtyTotal=thisiQtyTotal+this.arrivalData[i].thisiQty;
                }
            }
            for (let i = 0; i < this.arrivalData.length; i++) {
                //如果找到了发货数量变化对应的赠品存货
                if((row.cInvCode==this.arrivalData[i].cInvCode)&&(this.arrivalData[i].bGiveaway==true)){
                    //设置存货赠品数量为计算出的相同存货本次发货总数乘以赠品比例（向上取整）
                    this.arrivalData[i].thisiQty=Math.ceil(thisiQtyTotal*row.giveawayPro);
                }
            }
        }
    }

}
</script>

<style>
    /* 使提示框信息换行 */
    .el-notification {
        white-space:pre-wrap !important; 
    }
</style>