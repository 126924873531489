<template>
    <el-container>
        <el-header>
            <h1>欢迎使用供应商协同平台</h1>
        </el-header>
        <!-- <el-row style="margin-top:50px">
            <el-col :span="8"><div class="grid-content bg-purple"></div></el-col>
            <el-col :span="8">
                <el-image :src="img" fit="cover" style="width: 478px;margin-top:0 auto;"></el-image>
            </el-col>
            <el-col :span="8"><div class="grid-content bg-purple"></div></el-col>
        </el-row> -->
    </el-container>
</template>

<script>
export default {
    data(){
        return {
            //图片地址
            // img:require('@/assets/transverse.png')
        }
    }
}
</script>

<style>

</style>