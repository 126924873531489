<template>
    <!-- 询价单详情（供应商） -->
    <el-container class="enquirySupOrderDet">
        <!-- 表头 -->
        <el-header class="table-header" style="height:30px">
            <el-row style="margin-top:0px">
                <el-form :inline="true" :model="enquiryDetSup" class="demo-form-inline">
                    <el-row class="query-row">
                        <el-col :span="5" class="query-col">
                            <el-form-item label="单据编码：" style="margin-left:10px;">
                                <span style="font-size: 12px;">
                                    {{enquiryDetSup.inquiryCode}}
                                </span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" class="query-col">
                            <el-form-item label="单据日期：" style="margin-left:10px">
                                {{enquiryDetSup.inquiryDate}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="query-col">
                            <el-form-item label="业务员：" style="margin-left:10px">
                                {{enquiryDetSup.cPersonName}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" class="query-col" style="text-align: center;">
                            <el-form-item label="报价状态：">
                                <el-tag size="mini" :type="enquiryDetSup.offerIsExamine==null?'danger':enquiryDetSup.offerIsExamine==0?'':'success'">{{enquiryDetSup.offerIsExamine==null?'未报价':enquiryDetSup.offerIsExamine==0?'已报价未审核':'已审核'}}</el-tag>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" class="query-col" style="text-align: center;">
                            <el-form-item label="供应商：" style="margin-left:10px">
                                <span style="font-size: 12px;">
                                    {{enquiryDetSup.cVenName}}
                                </span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-row>
        </el-header>
        <!-- 询价单详情内容 -->
        <el-main class="enquiryDetailsTable">
            <el-table
                :data="enquirySupData"
                height="300"
                border
                size="mini"
                style="width: 100%;margin-bottom: 0px;">
                <el-table-column
                type="index"
                min-width="10%">
                </el-table-column>
                <el-table-column
                prop="cInvName"
                label="存货名称"
                sortable
                show-overflow-tooltip
                min-width="18%">
                </el-table-column>
                <el-table-column
                prop="cInvCode"
                label="存货编码"
                sortable
                show-overflow-tooltip
                min-width="18%">
                </el-table-column>
                <el-table-column
                prop="cInvStd"
                label="规格型号"
                sortable
                show-overflow-tooltip
                min-width="18%">
                </el-table-column>
                <el-table-column
                prop="cComUnitName"
                label="单位"
                sortable
                show-overflow-tooltip
                min-width="18%">
                </el-table-column>
                <el-table-column
                prop="plaQuantity"
                label="计划数量"
                sortable
                min-width="18%">
                </el-table-column>
                <el-table-column
                prop="demDateStr"
                label="需求日期"
                sortable
                min-width="18%">
                </el-table-column>
            </el-table>
        </el-main>
    </el-container>
</template>

<script>
export default {
    props:["enquirySup","venUser"],
    data(){
        return{
            loading:false,//是否显示加载图标
            //选中要查看详情的供应商询价单对象
            enquiryDetSup:{
                enId:'',//询价单主表标识
                inquiryCode:'',//单据编码
                inquiryDate:'',//日期
                cPersonCode:'',//业务员编码
                cPersonName:'',//业务员姓名
                isExamine:'',//审核状态
                cVenName:'',//供应商名称
                offerIsExamine:''//关联的报价单主表审核标识
            },
            enquirySupData:[],//供应商询价单详情数据
        }
    },
    created(){
        //设置表头内容
        this.setEnquiryHead();
    },
    methods:{
        /**
         * 设置表头内容
         */
        setEnquiryHead(){
            
            this.enquiryDetSup.enId=this.enquirySup.enId;//询价单主表标识
            this.enquiryDetSup.inquiryCode=this.enquirySup.inquiryCode;//单据编码
            this.enquiryDetSup.inquiryDate=this.enquirySup.inquiryDate;//日期
            this.enquiryDetSup.cPersonCode=this.enquirySup.cPersonCode;//业务员编码
            this.enquiryDetSup.cPersonName=this.enquirySup.cPersonName;//业务员姓名
            this.enquiryDetSup.isExamine=this.enquirySup.isExamine;//审核状态
            this.enquiryDetSup.cVenName=this.venUser.cVenName;//供应商名称
            this.enquiryDetSup.offerIsExamine=this.enquirySup.offerIsExamine;//关联的报价单主表审核标识
            console.log('详情页要打开的询价单是：',this.enquiryDetSup);
            //根据询价单主表标识查找询价详情
            this.getEnquirySupDet();
        },
        /**
         * 根据询价单主表标识查询询价单详情
         */
        getEnquirySupDet(){
            this.loading=true;//显示加载页面
            this.enquirySupData=[];//查询之前清空之前查询到的询价单详情数据
            let queryEnquiryDetParam={
                enId:this.enquiryDetSup.enId//询价单主表标识
            }
            this.$axios.post("/u8/enquirySup/getEnquirySupDetails",queryEnquiryDetParam)
            .then((resp)=>{
                this.enquirySupData=resp.data.data;//设置查询到的询价单详情
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('供应商询价单详情服务器出错了');
                    this.$message('供应商询价单详情服务器出错了');
                }
            );
        },
    }
}
</script>

<style>
    
</style>

<style lang="less" scoped>

</style>