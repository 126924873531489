import Vue from 'vue';
import Vuex from 'vuex';//引入vuex
import router from '../router/index';

//插件注册
Vue.use(Vuex)

//store（仓库）
const store=new Vuex.Store({
    //被共享的数据
    state:{
        //登录用户token
        UserToken:localStorage.getItem('UserToken')?JSON.parse(localStorage.getItem('UserToken')):''
    },
    //修改state仓库数据（mutations中不要一步操作）
    mutations:{
        //修改token，并将token存入localStorage
        changeLogin(state,user){//添加token数据的方法
            state.UserToken = user.UserToken;//给共享数据中的用户token赋值为当前登录用户token
            localStorage.setItem('UserToken', JSON.stringify(user.UserToken));//设置本地存储的 name/value
        },
        //移除token数据
        removeLogin(state){
            state.UserToken='';//当前登录用户token设置为空
            localStorage.removeItem('UserToken');//移除本地存储数据
            router.push({name:'login'});;//安全退出后返回登录页
        }
    }
});

//导出（导出整个仓库）
export default store;