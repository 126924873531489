<template>
    <!-- 送货记录 -->
    <el-container>
        <!-- 查询条件区域 -->
        <el-header class="query-condition" style="height:30px">
            <el-form :inline="true" :model="queryInline" class="demo-form-inline">
                <el-row class="query-row">
                    <el-col :span="8" class="query-col">
                        <el-form-item label="送货日期范围：">
                            <el-date-picker
                            v-model="queryInline.deliveryDate"
                            type="daterange"
                            align="right"
                            unlink-panels
                            size="mini"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width:220px"
                            :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="query-col">
                        <el-form-item label="业务员名称：">
                            <el-input size="mini" v-model="queryInline.cPersonName" clearable placeholder="业务员名称" style="width:120px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="query-col">
                        <el-form-item label="单据类型：">
                            <el-select size="mini" v-model="queryInline.cBusType" clearable placeholder="请选择" style="width:100px">
                                <el-option
                                v-for="item in cBusTypeOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" class="query-col">
                        <el-button type="warning" icon="el-icon-refresh" size="mini" @click="restQuery()">重置</el-button>
                        <el-button type="primary" icon="el-icon-search" size="mini" @click="queryPurchase()">查询</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <!-- 送货记录数据展示区域 -->
        <el-main class="data-show">
            <el-table
                :data="deliveryData.slice((queryInline.pageIndex-1)*queryInline.pageSize,queryInline.pageIndex*queryInline.pageSize)"
                stripe
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :height="tableHeight"
                border
                ref="purchaseTable"
                size="mini"
                style="width: 100%;margin-bottom:5px"
                class="purchaseShow">
                <el-table-column
                type="index"
                min-width="5%">
                </el-table-column>
                <el-table-column
                prop="dDate"
                label="送货日期"
                sortable
                show-overflow-tooltip
                min-width="13%">
                </el-table-column>
                <el-table-column
                prop="cCode"
                label="送货单号"
                sortable
                show-overflow-tooltip
                min-width="12%">
                </el-table-column>
                <el-table-column
                prop="cVenName"
                label="供应商名称"
                sortable
                show-overflow-tooltip
                min-width="16%">
                </el-table-column>
                <el-table-column
                prop="cPersonName"
                label="业务员名称"
                sortable
                show-overflow-tooltip
                min-width="9%">
                </el-table-column>
                <el-table-column
                prop="cAuditDate"
                sortable
                label="审核时间"
                show-overflow-tooltip
                min-width="9%">
                </el-table-column>
                <el-table-column
                prop="cBusType"
                sortable
                label="业务类型"
                show-overflow-tooltip
                min-width="9%">
                    <!-- <template slot-scope="scope">
                        <el-tag size="mini" :type="scope.row.cBusType=='普通采购'?'success':''">{{scope.row.cBusType=='普通采购'?'采购订单':'委外订单'}}</el-tag>
                    </template> -->
                </el-table-column>
                <el-table-column
                prop="cpocode"
                sortable
                :label="queryParam.cBusType==''?'订单号':queryParam.cBusType=='普通采购'?'采购单号':'委外单号'"
                show-overflow-tooltip
                min-width="13%">
                </el-table-column>
                <el-table-column
                label="操作"
                min-width="15%">
                    <template slot-scope="scope">
                        <el-button @click="showUpLoad(scope.row)" type="primary" icon="el-icon-s-management" size="mini" style="padding-left:5px;padding-right:5px">附件</el-button>
                        <el-button @click="handleClick(scope.row)" type="primary" icon="el-icon-s-release" size="mini" style="padding-left:5px;padding-right:5px">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页查询区 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="this.queryInline.pageIndex"
                :page-sizes="this.queryInline.pageSizes"
                :page-size="this.queryInline.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.queryInline.total">
            </el-pagination>
            <!-- 送货记录详情框 -->
            <el-dialog title="供应商送货单" :visible.sync="showDeliveryDet" @close='closeDeliveryDet' width="95%" class="deliDetailsForm">
                <DeliveryDetails @showDetails="changShowDeliveryDet" v-if="isRefresh" :delivery="selectpDelivery"></DeliveryDetails>
                <!-- <el-row>
                    <el-col :span="24" >
                        <el-steps :active="3" :align-center="true" space="33.3%">
                            <el-step title="选择订单"></el-step>
                            <el-step title="设置并生成送货草稿"></el-step>
                            <el-step title="生成送货记录"></el-step>
                            <el-step title="生成并打印条码" description="如关闭可在送货记录查看"></el-step>
                        </el-steps>
                    </el-col>
                </el-row> -->
                <el-row>
                    <el-col :span="24" style="margin-bottom:10px">
                        <el-button size="mini" type="danger" @click="closeDeliveryDet()">关闭</el-button>
                    </el-col>
                </el-row>
            </el-dialog>
            <!-- 采购到货单附件窗口 -->
            <OrderReportPage @toCloseReportForm="closeReportForm" :showReportForm="showReportForm" :deliveryReport="deliveryReport" v-if="isResReport" :venUser="venUser"></OrderReportPage>
        </el-main>
    </el-container>
</template>

<script>
import DeliveryDetails from '../delivery/DeliveryDetailsPage'
import OrderReportPage from '../delivery/OrderReportPage'
export default {
    data(){
        return{
            //日历快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            cBusTypeOpt:[
                {
                    value: '普通采购',
                    label: '采购订单'
                },
                {
                    value: '委外加工',
                    label: '委外订单'
                }
            ],
            venUser:{},//登录的供应商对象
            //查询参数
            queryInline:{
                deliveryDate:[],//日期范围数组
                cPersonName:'',//业务员名称
                cBusType:'',//单据类型
                pageSizes:[10, 32, 64, 100, 200,300],//页面容量数组
                pageSize:10,//每页容量
                pageIndex:1,//当前页码
                total:0//查询结果总量
            },
            //向后端查询送货记录的参数
            queryParam:{
                deliveryDate:[],//日期范围数组
                cPersonName:'',//业务员名称
                cBusType:'',//单据类型
                iAdmin:'',//供应商权限
                cVenCode:'',//供应商编码
            },
            deliveryData:[],//查询到的送货记录数组
            loading:false,//是否显示加载图标
            tableHeight:window.innerHeight - 230,//数据展示区域自适应高度
            showDeliveryDet:false,//是否显示送货记录详情框的属性
            selectpDelivery:{},//选中要查看采购订单详情的采购订单主表标识
            isRefresh:true,//是否刷新采购订单详情窗口子组件的属性
            showReportForm:false,//是否显示采购订单附件窗口的属性
            deliveryReport:{},//要查看采购到货单附件的采购到货单对象
            isResReport:false,//是否刷新采购到货单附件窗口的属性
        }
    },
    watch:{
        "queryInline.deliveryDate"(newVal){//监控日期范围条件的绑定值
            if(newVal==null||newVal.length==0){//如果日期范围的绑定值为null
                this.queryInline.deliveryDate=[];//该日期范围绑定值设置为[]
                //初始化查询时间范围
                this.initDateRange();
            }
        }
    },
    created(){
        //调用设置查询中供应商的参数
        this.setVenUser();
        //初始化查询时间范围
        this.initDateRange();
        //调用向后端查询送货记录的方法
        this.toQueryDelivery();
    },
    methods:{
        /**
         * 初始化查询时间范围
         */
        initDateRange(){
            let toDay=new Date();//获取天时间
            toDay.setHours(0,0,0);//时分秒设置为零
            let startDate=new Date(toDay.getTime()-(1000*60*60*24*30));
            this.queryInline.deliveryDate.push(startDate);//设置开始时间
            this.queryInline.deliveryDate.push(toDay);//设置结束时间
            this.queryParam.deliveryDate=this.queryInline.deliveryDate;//设置向后端查询的时间范围
        },
        /**
         * 设置查询中供应商的参数
         */
        setVenUser(){
            //获取上级父组件传进来的参数并赋值
            this.venUser=this.$store.state.UserToken;//路由中获取到的是json字符串，要转化为json对象
            this.queryParam.iAdmin=this.venUser.iAdmin;//供应商权限
            this.queryParam.cVenCode=this.venUser.cVenCode;//供应商编码
        },
        /**
         * 重置查询条件的方法
         */
        restQuery(){
            this.queryInline.deliveryDate=[];//日期范围数组
            this.queryInline.cPersonName='';//业务员名称
            this.queryInline.cBusType='';//单据类型
        },
        /**
         * 点击查询送货记录的方法
         */ 
        queryPurchase(){
            //查询之前清空数据
            this.deliveryData=[]
            this.queryInline.pageIndex=1;//设置为查询第一页
            this.queryInline.total=0;//清空查询到的送货记录总数
            //设置向后端查询送货记录的参数
            this.queryParam.deliveryDate=this.queryInline.deliveryDate;//日期范围数组
            this.queryParam.cPersonName=this.queryInline.cPersonName;//业务员名称
            this.queryParam.cBusType=this.queryInline.cBusType;//单据类型
            //调用向后端查询送货记录的方法
            this.toQueryDelivery();
        },
        /**
         * 向后端查询送货记录的方法
         */
        toQueryDelivery(){
            this.loading=true;//显示加载页面
            this.$axios.post("/u8/delivery/getDelivery",this.queryParam)
            .then((resp)=>{
                if(resp.data.success=="false"){
                    this.$message(resp.data.msg);
                }
                if(resp.data.success=="true"){
                    this.deliveryData=resp.data.data;//设置查询到的送货记录
                    this.queryInline.total=this.deliveryData.length;//查询到的送货记录总数
                }
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('送货记录服务器出错了');
                    this.$message('送货记录服务器出错了');
                }
            );
        },
        //分页查询
        /**
         * 改变每页显示的数量
         */
        handleSizeChange(val) {
            this.queryInline.pageSize=val;//设置每页容量
            this.queryInline.pageIndex=1;//改总量后，初始页面改为1
        },
        /**
         * 改变开始页码
         */
        handleCurrentChange(val) {
            this.queryInline.pageIndex=val;//设置当前页码
        },
        /**
         * 查看送货记录详情的方法
         */
        handleClick(row){
            this.selectpDelivery=row;//设置选中的采购订单主表标识
            this.isRefresh=false;//是否刷新采购订单详情窗口子组件的属性
            this.$nextTick(() => {
                this.isRefresh= true;//强制刷新采购订单详情页子组件数据
            });
            this.showDeliveryDet=true;//打开送货记录详情框
        },
        /**
         * 关闭送货记录详情窗口的方法
         */
        closeDeliveryDet(){
            this.showDeliveryDet=false;
        },
        /**
         * 子组件传值关闭送货记录详情窗口的方法
         */
        changShowDeliveryDet(data){
            this.showDeliveryDet=data;
        },
        /**
         * 打开查看附件的方法
         */
        showUpLoad(row){
            this.deliveryReport=row;//设置要查看采购到货单附件的采购到货单对象
            this.isResReport=false;//是否刷新采购到货单附件窗口
            this.$nextTick(() => {
                this.isResReport= true;//强制刷新采购到货单附件窗口
            });
            this.showReportForm=true;//打开采购到货单附件窗口
        },
        /**
         * 附件窗口传回关闭附件窗口的方法
         */
        closeReportForm(data){
            this.showReportForm=data;//关闭采购到货单附件窗口
        }
    },
    components:{
        //引入送货记录详情页
        DeliveryDetails:DeliveryDetails,
        //引入采购到货单附件窗口
        OrderReportPage:OrderReportPage,
    }
}
</script>

<style>
    /* 查询条件每一行样式 */
    .query-row{
        height: 50px;
    }
    /* 查询区域每个条件的高度 */
    .query-col{
        height: 100%;
        text-align: left;
        padding-left: 10px;
    }
    /* 弹框样式 */
    .deliDetailsForm{
        margin-top: 0px;
        
    }

    /* 弹框标题样式 */
    .deliDetailsForm .el-dialog__header{
        padding-top:10px;
    }

    /* 步骤条样式 */
    .step {
        width: 1200px;
        margin: 44px auto 0;
    }
    .el-step__icon.is-icon {
        width: 10px;
        height: 10px;
    }
    .el-step__title.is-process {
        font-weight: 400;
        color: #303133;
    }
    .el-step__head.is-finish {
        color: #fca404;
        border-color: #fca404;
    }
    .el-step__title.is-finish {
        color: #fca404;
    }
    .el-step__description.is-finish {
        color: #fca404;
        height: 60px;
    }
    .el-step__head.is-finish {
        color: #fca404;
    }
    .el-step.is-horizontal .el-step__line {
        height: 2px;
        top: 10px;
    }
    .el-step{
        height: 70px !important;
    }
</style>

<style lang="less">
    .deliDetailsForm{
        .el-dialog{
            margin-top:0px !important;
            margin-bottom:0px !important;
        }
    }
</style>