<template>
    <!-- 采购方对账单 -->
    <el-container class="check-form">
        <!-- 查询条件区域 -->
        <el-header style="height:50px">
            <el-form :inline="true" :model="queryInline" class="demo-form-inline">
                <el-row class="query-row">
                    <el-col :span="18" class="query-col" style="padding-top:5px;">
                        <el-form-item label="日期范围：">
                            <el-date-picker
                            v-model="queryInline.inquiryDates"
                            type="daterange"
                            align="right"
                            unlink-panels
                            size="mini"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width:210px"
                            :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="供应商：" style="margin-left:20px">
                            <el-select filterable size="mini" clearable v-model="queryInline.cVenCode" placeholder="请选择">
                                <el-option
                                v-for="item in vendorOpt"
                                :key="item.cVenCode"
                                :label="item.cVenName"
                                :value="item.cVenCode">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-button type="warning" icon="el-icon-refresh" size="mini" style="margin-top:6px;margin-left:20px" @click="restQuery()">查询重置</el-button>
                        <el-button type="primary" icon="el-icon-search" size="mini" style="margin-top:6px" @click="queryCheck()">查询</el-button>
                    </el-col>
                    <el-col :span="6" class="query-col" style="padding-top:5px">
                        <el-button @click="drawer = true" size="mini" type="primary" style="margin-left: 16px;margin-top:6px">导出excel表格</el-button>
                        <el-drawer
                        title="导出excel页"
                        :visible.sync="drawer"
                        :with-header="false"
                        size="340px">
                            <h3 style="text-align: center;margin-top:30px">导出excel表格</h3>
                            <el-input size="mini" placeholder="当前页excel表格名称" v-model="excelName" style="width:300px;margin-top:30px;margin-left:20px">
                                <el-button size="mini" type="primary" slot="append" icon="el-icon-download" @click="exportOneExcel()">导出当前页excel</el-button>
                            </el-input>
                            <el-input size="mini" placeholder="全部内容excel表格名称" v-model="excelAllName" style="width:300px;margin-top:30px;margin-left:20px">
                                <el-button size="mini" type="primary" slot="append" icon="el-icon-download" @click="exportAllExcel()">导出全部excel</el-button>
                            </el-input>
                        </el-drawer>
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <!-- 采购方对账单数据展示区域 -->
        <el-main class="data-show">
            <el-table
                :data="checkData.slice((queryInline.pageIndex-1)*queryInline.pageSize,queryInline.pageIndex*queryInline.pageSize)"
                id="checkExportExcel-table"
                stripe
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :height="tableHeight"
                border
                ref="checkTable"
                size="mini"
                :summary-method="getSummaries"
                show-summary
                style="width: 100%;margin-bottom:5px"
                class="checkShow">
                <el-table-column
                type="index"
                fixed
                width="50px">
                </el-table-column>
                <el-table-column
                prop="cVenAbbName"
                label="供应商名称"
                sortable
                fixed
                show-overflow-tooltip
                width="110px">
                </el-table-column>
                <el-table-column
                prop="dDate"
                label="入库日期"
                sortable
                fixed
                show-overflow-tooltip
                width="110px">
                </el-table-column>
                <el-table-column
                prop="cBusType"
                label="单据类型"
                sortable
                fixed
                show-overflow-tooltip
                width="95px">
                </el-table-column>
                <el-table-column
                prop="cOrderCode"
                label="采购（委外）单号"
                sortable
                fixed
                show-overflow-tooltip
                width="145px">
                </el-table-column>
                <el-table-column
                prop="cARVCode"
                label="送货单号"
                sortable
                show-overflow-tooltip
                width="120px">
                </el-table-column>
                <el-table-column
                prop="cCode"
                label="入库单号"
                sortable
                show-overflow-tooltip
                width="100px">
                </el-table-column>
                <el-table-column
                prop="cInvCode"
                label="存货编码"
                sortable
                show-overflow-tooltip
                width="100px">
                </el-table-column>
                <el-table-column
                prop="cInvName"
                label="存货名称"
                sortable
                show-overflow-tooltip
                width="100px">
                </el-table-column>
                <el-table-column
                prop="cInvStd"
                label="规格型号"
                sortable
                show-overflow-tooltip
                width="100px">
                </el-table-column>
                <el-table-column
                prop="cComUnitName"
                label="单位"
                sortable
                show-overflow-tooltip
                width="70px">
                </el-table-column>
                <el-table-column
                prop="iQuantity"
                label="入库数量"
                sortable
                show-overflow-tooltip
                width="100px">
                </el-table-column>
                <el-table-column
                prop="iTaxRate"
                label="税率"
                sortable
                show-overflow-tooltip
                width="70px">
                </el-table-column>
                <el-table-column
                prop="iOriTaxCost"
                label="含税单价"
                sortable
                show-overflow-tooltip
                width="100px">
                </el-table-column>
                <el-table-column
                prop="ioriSum"
                label="含税金额"
                sortable
                show-overflow-tooltip
                width="100px">
                </el-table-column>
                <el-table-column
                prop="iOriCost"
                label="不含税单价"
                sortable
                show-overflow-tooltip
                width="110px">
                </el-table-column>
                <el-table-column
                prop="iOriMoney"
                label="不含税金额"
                sortable
                show-overflow-tooltip
                width="110px">
                </el-table-column>
                <el-table-column
                prop="iOriTaxPrice"
                label="税额"
                sortable
                show-overflow-tooltip
                width="110px">
                </el-table-column>
                <el-table-column
                prop="cMemo"
                label="备注"
                show-overflow-tooltip
                width="100px">
                </el-table-column>
                <el-table-column
                prop="cDefine35"
                label="是否对账"
                show-overflow-tooltip
                width="100px">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.cDefine35==1?'':'warning'">{{scope.row.cDefine35==1?'已对账':'未对账'}}</el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页查询区 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="this.queryInline.pageIndex"
                :page-sizes="this.queryInline.pageSizes"
                :page-size="this.queryInline.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.queryInline.total"
                style="margin-top:10px">
            </el-pagination>
        </el-main>
    </el-container>
</template>

<script>
export default {
    data(){
        return{
            //日历快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            //供应商下拉列表
            vendorOpt:[
                {
                    cVenCode: '0',
                    cVenName: '未对账'
                },
                {
                    cVenCode: '1',
                    cVenName: '已对账'
                }
            ],
            //查询参数
            queryInline:{
                inquiryDates:[],//日期范围数组
                cVenCode:'',//供应商编码
                pageSizes:[10, 32, 64, 100, 200,300],//页面容量数组
                pageSize:10,//每页容量
                pageIndex:1,//当前页码
                total:0//查询结果总量
            },
             //向后端查询采购方对账单的参数
            queryParam:{
                inquiryDates:[],//日期范围数组
                cVenCode:'',//供应商编码
            },
            //查询到的对账单数据
            checkData:[],
            loading:false,//是否显示加载图标
            tableHeight:window.innerHeight - 255,//数据展示区域自适应高度
            excelName:'',//导出excel表格的名称
            excelAllName:'',//导出全部内容excel表格的名称
            drawer:false,//弹出导出excel页面的属性
        }
    },
    created(){
        //调用获取供应商下拉列表的方法
        this.getVendors();
        //初始化查询时间范围
        this.initDateRange();
        //向后端查询采购方对账单的方法
        this.toQueryCheck()
    },
    watch:{
        "queryInline.inquiryDates"(newVal){//监控日期范围条件的绑定值
            if(newVal==null||newVal.length==0){//如果日期范围的绑定值为null
                this.queryInline.inquiryDates=[];//该日期范围绑定值设置为[]
                //初始化查询时间范围
                this.initDateRange();
            }
        },
        "drawer"(newVal){//监听导出excel窗口的显示属性
            if(newVal==false){//如果关闭了显示导出excel窗口
                this.excelName='';//清空导出excel表格的名称
                this.excelAllName='';//清空导出全部内容excel表格的名称
            }
        }
    },
    methods:{
        /**
         * 初始化查询时间范围
         */
        initDateRange(){
            let toDay=new Date();//获取天时间
            toDay.setHours(0,0,0);//时分秒设置为零
            let startDate=new Date(toDay.getTime()-(1000*60*60*24*30));
            this.queryInline.inquiryDates.push(startDate);//设置开始时间
            this.queryInline.inquiryDates.push(toDay);//设置结束时间
            this.queryParam.inquiryDates=this.queryInline.inquiryDates;//设置向后端查询的时间范围
        },
        /**
         * 获取供应商下拉列表的方法
         */
        getVendors(){
            this.$axios.post("/u8/check/getVendors")
            .then((resp)=>{
                this.vendorOpt=resp.data.data;//设置供应商下拉列表
                if(resp.data.success=="false"){
                    this.$message(resp.data.msg);
                }
            })
            .catch(
                function(error){
                    console.log(error)
                    console.log('供应商服务器出错了')
                    this.$message('供应商服务器出错了')
                }
            );
        },
        /**
         * 重置查询条件的方法
         */
        restQuery(){
            this.queryInline.inquiryDates=[];//日期范围数组
            this.queryInline.cVenCode='';//供应商编码
        },
        /**
         * 点击查询对账单的方法
         */
        queryCheck(){
            //查询之前清空数据
            this.checkData=[]
            this.queryInline.pageIndex=1;//查询前重置为查询第一页
            this.queryInline.total=0;//清空查询到的采购方对账单总数
             //设置向后端查询采购方对账单的参数
            this.queryParam.inquiryDates=this.queryInline.inquiryDates;//日期范围数组
            this.queryParam.cVenCode=this.queryInline.cVenCode;//供应商编码
             //调用向后端查询采购方对账单的方法
            this.toQueryCheck();
        },
        /**
         * 向后端查询采购方对账单的方法
         */
        toQueryCheck(){
            this.loading=true;//显示加载页面
            this.$axios.post("/u8/check/getCheckOrder",this.queryParam)
            .then((resp)=>{
                if(resp.data.success=="false"){
                    this.$message(resp.data.msg);
                }
                if(resp.data.success=="true"){
                    this.checkData=resp.data.data;//设置查询到的采购方对账单集合
                    this.queryInline.total=this.checkData.length;//查询到的采购方对账单数量
                }
                //解决合计行错位，checkTable是table表单的ref值
                this.$nextTick(() => {
                    this.$refs.checkTable.doLayout();
                });
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error)
                    console.log('采购方对账单服务器出错了')
                    this.$message('采购方对账单服务器出错了')
                }
            );
        },
        //分页查询
        /**
         * 改变每页显示的数量
         */
        handleSizeChange(val) {
            this.queryInline.pageSize=val;//设置每页容量
            this.queryInline.pageIndex=1;//改总量后，初始页面改为1
        },
        /**
         * 改变开始页码
         */
        handleCurrentChange(val) {
            this.queryInline.pageIndex=val;//设置当前页码
        },
        /**
         * 显示指定行的合计方法
         */
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (column.property=='iQuantity'||column.property=='ioriSum'||column.property=='iOriMoney'||column.property=='iOriTaxPrice') {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                    //合计数保留两位小数
                    sums[index]=sums[index].toFixed(2);
                } else {
                    sums[index] = '';
                }
            });
            return sums;
        },
        /**
         * 导出当前页excel表格
         */
        exportOneExcel(){
            if(this.excelName==''){
                this.$message('请输入要导出excel表格的名字');
            }else{
                let fix = document.querySelector('.el-table__fixed');
                let tables = document.getElementById("checkExportExcel-table");
                let table_book;
                if(fix){//判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
                    table_book = this.$XLSX.utils.table_to_book(tables.removeChild(fix),{raw:true});
                    tables.appendChild(fix);
                }else{
                    table_book = this.$XLSX.utils.table_to_book(tables,{raw:true});
                }
                var table_write = this.$XLSX.write(table_book, {
                    bookType: "xlsx",
                    bookSST: true,
                    type: "array"
                });
                let excelNameStr=this.excelName+'.xlsx'
                try {
                    this.$FileSaver.saveAs(
                    new Blob([table_write], { type: "application/octet-stream" }),
                    excelNameStr
                    );
                } catch (e) {
                    if (typeof console !== "undefined") console.log(e, table_write);
                }
                this.excelName='';//表格名设置为空
                return table_write;
            }
        },
        /**
         * 导出查询到的全部内容excel表格
         */
        exportAllExcel(){
            if(this.excelAllName==''){
                this.$message('请输入要导出全部内容excel表格的名字');
            }else{
                let defaultPageSize=this.queryInline.pageSize;//原本选择的页面容量
                this.queryInline.pageSize=this.queryInline.total;//将表格长度变成数据总长度
                this.queryInline.pageIndex = 1//显示第一页
                this.$nextTick(function () {
                    let fix = document.querySelector('.el-table__fixed');
                    let tables = document.getElementById("checkExportExcel-table");
                    let wb;
                    if(fix){//判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
                        wb = this.$XLSX.utils.table_to_book(tables.removeChild(fix),{raw:true});
                        tables.appendChild(fix);
                    }else{
                        wb = this.$XLSX.utils.table_to_book(tables,{raw:true});
                    }
                    let wbout = this.$XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
                    let excelAllNameStr=this.excelAllName+'.xlsx'
                    try {
            　　　　　　 //Blob 对象表示一个不可变、原始数据的类文件对象。它的数据可以按文本或二进制的格式进行读取，也可以转换成 ReadableStream 来用于数据操作
                        this.$FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), excelAllNameStr)
                    } catch (e) {
                        if (typeof console !== 'undefined') console.log(e, wbout)
                    }
                    this.queryInline.pageSize = defaultPageSize;//表格长度还原
                    this.excelAllName='';//表格名设置为空
                    
                    return wbout;
                })
            }
        },
    }
}
</script>

<style>
    /* 使table显示合计正常 */
    .check-form .el-table{
        overflow:visible !important;
    }

    /* table合计行贴底 */
    .check-form .el-table__footer-wrapper{
        /* position: absolute !important; */
        bottom: 18px;
    }
    
    /* 解决标题和内容单元格错位 */
    body .el-table th.gutter {
        display: table-cell!important;
    }
    
    /* 解决表格固定列后覆盖滚动条 */
    /* 解决左列固定 */
    .check-form .el-table .el-table__fixed {
        height:auto !important;
        bottom:0px;
    }
    /* 解决右列固定 */
    .check-form .el-table .el-table__fixed-right {
        height:auto !important;
        bottom:0px;
    }

    /*滚动条覆盖*/
    .check-form .el-table__body-wrapper{
        z-index: 2;
    }


</style>