<template>
    <!-- 送货草稿 -->
    <el-container>
        <!-- 查询条件区域 -->
        <el-header style="height:30px">
            <el-form :inline="true" :model="queryInline" class="demo-form-inline">
                <el-row class="query-row">
                    <el-col :span="8" class="query-col">
                        <el-form-item label="送货日期范围">
                            <el-date-picker
                            v-model="queryInline.draftDate"
                            type="daterange"
                            align="right"
                            unlink-panels
                            size="mini"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width:220px"
                            :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col">
                        <el-form-item label="来源单类型">
                            <el-select v-model="queryInline.cSourceType" size="mini" clearable  placeholder="请选择">
                                <el-option
                                v-for="item in typOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col">
                        <el-button type="warning" icon="el-icon-refresh" size="mini" @click="restQuery()">重置</el-button>
                        <el-button type="primary" icon="el-icon-search" size="mini" @click="queryDraft()">查询</el-button>
                        <el-button type="success" icon="el-icon-receiving" v-if="venUser.iAdmin!=1" size="mini" @click="openSent()">发货</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <!-- 送货草稿数据展示区域 -->
        <el-main class="data-show">
            <el-table
                :data="draftData.slice((queryInline.pageIndex-1)*queryInline.pageSize,queryInline.pageIndex*queryInline.pageSize)"
                stripe
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :height="tableHeight"
                border
                ref="draftTable"
                size="mini"
                style="width: 100%;margin-bottom:5px"
                class="draftShow">
                <el-table-column
                type="index"
                min-width="5%">
                </el-table-column>
                <el-table-column
                prop="deliverTime"
                label="送货日期"
                sortable
                show-overflow-tooltip
                min-width="15%">
                </el-table-column>
                <el-table-column
                prop="cCode"
                label="送货草稿号"
                sortable
                show-overflow-tooltip
                min-width="15%">
                </el-table-column>
                <el-table-column
                prop="cVenName"
                label="供应商名称"
                sortable
                show-overflow-tooltip
                min-width="20%">
                </el-table-column>
                <el-table-column
                prop="cSourceType"
                label="来源单类型"
                sortable
                show-overflow-tooltip
                min-width="15%">
                </el-table-column>
                <el-table-column
                prop="cSourceCode"
                sortable
                label="采购单号"
                show-overflow-tooltip
                min-width="15%">
                </el-table-column>
                <el-table-column
                label="操作"
                min-width="15%">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-s-release" @click="handleClick(scope.row)" size="mini" style="margin-right:10px">查看</el-button>
                        <el-popconfirm title="确定删除该送货草稿？" @confirm="delDraftAndDet(scope.row)">
                            <el-button v-if="venUser.iAdmin!=1" size="mini" type="danger" icon="el-icon-delete" slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页查询区 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="this.queryInline.pageIndex"
                :page-sizes="this.queryInline.pageSizes"
                :page-size="this.queryInline.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.queryInline.total">
            </el-pagination>
            <!-- 合并发货选择页框 -->
            <el-dialog title="发货选择页" :visible.sync="showSentSelect" @close='closeSent' width="95%" class="detailsForm">
                <CombinedSent @showSent="changShowSent" v-if="isComSentRefresh"></CombinedSent>
                <!-- 步骤条 -->
                <el-steps :active="0" :align-center="true" space="33.3%">
                    <el-step title="选择订单"></el-step>
                    <el-step title="设置并生成送货草稿"></el-step>
                    <el-step title="生成送货记录"></el-step>
                    <el-step title="生成并打印条码" description="如关闭可在送货记录查看"></el-step>
                </el-steps>
                <el-row>
                    <el-col :span="24" style="margin-bottom:10px">
                        <el-button type="danger" icon="el-icon-circle-close" @click="closeSent()">关闭</el-button>
                    </el-col>
                </el-row>
            </el-dialog>
            <!-- 确认合并发货框 -->
            <el-dialog append-to-body :title="sentcSourceType=='PO'?'采购订单发货':'委外订单发货'" :visible.sync="showSentDet" @close='closeSentDet' width="95%" class="purDetailsForm">
                <ToComSentDetails @showSentDetails="changShowSentet" v-if="isSentRefresh" :sents="selectpSents" :sentType="sentcSourceType" :user="venUser" :bInspeDate="bInspeDate"></ToComSentDetails>
                <!-- 步骤条 -->
                <el-steps :active="1" :align-center="true" space="33.3%">
                    <el-step title="选择订单"></el-step>
                    <el-step title="设置并生成送货草稿"></el-step>
                    <el-step title="生成送货记录"></el-step>
                    <el-step title="生成并打印条码" description="如关闭可在送货记录查看"></el-step>
                </el-steps>
                <el-row style="margin-top:10px">
                    <el-col :span="24" class="close-col">
                        <el-button type="danger" icon="el-icon-circle-close" @click="closeSentDet()">关闭</el-button>
                    </el-col>
                </el-row>
            </el-dialog>
            <!-- 送货草稿详情框 -->
            <el-dialog title="送货单草稿详情" :visible.sync="showDraftDet" @close='closeDraftDet' width="95%" class="detailsForm">
                <DraftDetails @showDetails="changShowDraftDet" v-if="isRefresh" :draft="selectpDraft"></DraftDetails>
                <!-- 步骤条 -->
                <el-steps :active="2" :align-center="true" space="33.3%">
                    <el-step title="选择订单"></el-step>
                    <el-step title="设置并生成送货草稿"></el-step>
                    <el-step title="生成送货记录"></el-step>
                    <el-step title="生成并打印条码" description="如关闭可在送货记录查看"></el-step>
                </el-steps>
                <el-row style="margin-top:10px">
                    <el-col :span="24" style="margin-bottom:10px">
                        <el-button type="danger" icon="el-icon-circle-close" @click="closeDraftDet()">关闭</el-button>
                    </el-col>
                </el-row>
            </el-dialog>
            <!-- 送货记录单详情框 -->
            <el-dialog title="送货记录单详情" :visible.sync="showDeliveryDet" @close='closeDeliveryDet' width="95%" class="detailsForm">
                <DeliveryDetails @showDeliveryDetails="changShowDeliveryDet" v-if="isDetRefresh" :delivery="selectpDelivery"></DeliveryDetails>
                <!-- 步骤条 -->
                <el-steps :active="3" :align-center="true" space="33.3%">
                    <el-step title="选择订单"></el-step>
                    <el-step title="设置并生成送货草稿"></el-step>
                    <el-step title="生成送货记录"></el-step>
                    <el-step title="生成并打印条码" description="如关闭可在送货记录查看"></el-step>
                </el-steps>
                <el-row style="margin-top:10px">
                    <el-col :span="24" style="margin-bottom:10px">
                        <el-button type="danger" icon="el-icon-circle-close" @click="closeDeliveryDet()">关闭</el-button>
                    </el-col>
                </el-row>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
import DraftDetails from '../draft/DraftDetailsPage'
import DeliveryDetails from '../delivery/DeliveryDetailsPage'
import CombinedSent from '../draft/CombinedSentPage'
import ToComSentDetails from '../draft/ToComSentDetailsPage'
export default {
    data(){
        return{
            //日历快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            venUser:{},//登录的供应商对象
            //查询参数
            queryInline:{
                draftDate:[],//日期范围数组
                cSourceType:'',//来源单类型
                pageSizes:[10, 32, 64, 100, 200,300],//页面容量数组
                pageSize:10,//每页容量
                pageIndex:1,//当前页码
                total:0//查询结果总量
            },
            //向后端查询送货草稿的参数
            queryParam:{
                draftDate:[],//日期范围数组
                cSourceType:'',//来源单类型
                iAdmin:'',//供应商权限
                cVenCode:'',//供应商编码
            },
            draftData:[],//查询到的送货记录数组
            loading:false,//是否显示加载图标
            tableHeight:window.innerHeight - 230,//数据展示区域自适应高度
            showDraftDet:false,//是否显示送货草稿详情框的属性
            selectpDraft:{},//选中要查看送货草稿详情的送货草稿主表标识
            isRefresh:false,//是否刷新送货草稿详情窗口子组件的属性
            isComSentRefresh:false,//是否刷新合并发货选择页窗口子组件的属性
            isSentRefresh:false,//是否刷新确认合并发货窗口子组件的属性
            isDetRefresh:true,//是否刷新送货记录详情窗口子组件的属性
            typOptions:[
                {
                    value: 'PO',
                    label: '采购订单'
                },
                {
                    value: 'OM',
                    label: '委外订单'
                }
            ],
            showDeliveryDet:false,//是否显示送货记录详情框的属性
            //要查看送货记录详情的采购到货单主表标识 
            selectpDelivery:{
                id:''//采购到货单主表标识
            },
            showSentSelect:false,//是否显示合并发货选择框的属性
            showSentDet:false,//是否显示确认合并发货页的属性
            selectpSents:[],//最后要合并发货的采购（委外）订单数组
            sentcSourceType:'',//要合并发货的订单来源单类型
            bInspeDate:'',//供应商平台送货是否检验交期参数
        }
    },
    watch:{
        "queryInline.draftDate"(newVal){//监控日期范围条件的绑定值
            if(newVal==null||newVal.length==0){//如果日期范围的绑定值为null
                this.queryInline.draftDate=[];//该日期范围绑定值设置为[]
                //初始化查询时间范围
                this.initDateRange();
            }
        }
    },
    created(){
        //调用设置查询中供应商的参数
        this.setVenUser();
        //调用获取供应商平台发货是否检验交期的参数
        this.getbInspeDate();
        //初始化查询时间范围
        this.initDateRange();
        //调用向后端查询送货草稿的方法
        this.toQueryDraft();
    },
    methods:{
        /**
         * 初始化查询时间范围
         */
        initDateRange(){
            let toDay=new Date();//获取天时间
            toDay.setHours(0,0,0);//时分秒设置为零
            let startDate=new Date(toDay.getTime()-(1000*60*60*24*30));
            this.queryInline.draftDate.push(startDate);//设置开始时间
            this.queryInline.draftDate.push(toDay);//设置结束时间
            this.queryParam.draftDate=this.queryInline.draftDate;//设置向后端查询的时间范围
        },
        /**
         * 设置查询中供应商的参数
         */
        setVenUser(){
            //获取上级父组件传进来的参数并赋值
            this.venUser=this.$store.state.UserToken;//路由中获取到的是json字符串，要转化为json对象
            this.queryParam.iAdmin=this.venUser.iAdmin;//供应商权限
            this.queryParam.cVenCode=this.venUser.cVenCode;//供应商编码
        },
        /**
         * 获取供应商平台发货是否检验交期的参数
         */
        getbInspeDate(){
            let that=this;
            this.bInspeDate='';//先清空之前设置发货是否检验交期的参数
            let queryParam={
                cSysID:'ST',
                cID:'SRM',
                cName:'bInspeDate'
            }
            this.$axios.post("/u8/accinformation/getAccinformationByParam",queryParam)
            .then((resp)=>{
                if (resp.data.success=="true") {
                    this.bInspeDate=resp.data.data.cValue;
                }else{
                    this.$message({
                        showClose:true,
                        type:'warning',
                        message:'没有配置“供应商平台送货是否检验交期”参数'
                    })
                }
                console.log('查询到是否校验交期：',this.bInspeDate);
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('查询发货是否检验交期服务器出错了');
                    that.$message.error('查询发货是否检验交期服务器出错了');
                }
            );
        },
        /**
         * 重置查询条件
         */
        restQuery(){
            this.queryInline.draftDate=[];//日期范围
            this.queryInline.cSourceType='';//来源单类型
        },
        /**
         * 点击查询送货草稿的方法
         */ 
        queryDraft(){
            //查询之前清空数据
            this.draftData=[]
            this.queryInline.pageIndex=1;//查询前重置为查询第一页
            this.queryInline.total=0;//清空查询到的送货记录总数
            //设置向后端查询送货草稿的参数
            this.queryParam.draftDate=this.queryInline.draftDate;//日期范围数组
            this.queryParam.cSourceType=this.queryInline.cSourceType;//来源单类型
            //调用向后端查询送货草稿的方法
            this.toQueryDraft();
        },
        /**
         * 向后端查询送货草稿的方法
         */
        toQueryDraft(){
            //查询前清空之前查询到的数据
            this.draftData=[];
            this.loading=true;//显示加载页面
            this.$axios.post("/u8/draft/getDraft",this.queryParam)
            .then((resp)=>{
                if(resp.data.success=="false"){
                    this.$message(resp.data.msg);
                }
                if(resp.data.success=="true"){
                    this.draftData=resp.data.data;//设置查询到的送货草稿集合
                    this.queryInline.total=this.draftData.length;//查询到的送货草稿数量
                }
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('送货草稿服务器出错了');
                    this.$message('送货草稿服务器出错了');
                }
            );
        },
        //分页查询
        /**
         * 改变每页显示的数量
         */
        handleSizeChange(val) {
            this.queryInline.pageSize=val;//设置每页容量
            this.queryInline.pageIndex=1;//改总量后，初始页面改为1
        },
        /**
         * 改变开始页码
         */
        handleCurrentChange(val) {
            this.queryInline.pageIndex=val;//设置当前页码
        },
        /**
         * 查看采购送货草稿详情的方法
         */
        handleClick(row){
            console.log('查看的是：',row);
            this.selectpDraft=row;//设置选中的采购订单主表标识
            this.isRefresh=false;//是否刷新采购订单详情窗口子组件的属性
            this.$nextTick(() => {
                this.isRefresh= true;//强制刷新采购订单详情页子组件数据
            });
            this.showDraftDet=true;//打开采购送货草稿详情框
        },
        /**
         * 删除送货草稿及送货草稿详情的方法
         */
        delDraftAndDet(row){
            let delDraftAndDetParam={
                arrID:row.arrID,//采购送货草稿主表标识
                cCode:row.cCode,//送货草稿号
            }
            this.$axios.post("/u8/draft/delDraftAndDet",delDraftAndDetParam)
            .then((resp)=>{
                this.$message(resp.data.msg);
                if(resp.data.success=="true"){
                    //重新查询送货草稿
                    this.toQueryDraft();
                }
            })
            .catch(
                function(error){
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                    this.$message('送货草稿服务器出错了')
                }
            );
        },
        /**
         * 关闭送货草稿详情窗口的方法
         */
        closeDraftDet(){
            this.showDraftDet=false;//关闭送货草稿详情窗口
            //重新查询送货草稿
            this.toQueryDraft();
        },
        /**
         * 子组件传值关闭送货草稿详情窗口的方法
         */
        changShowDraftDet(data){
            this.showDraftDet=data.showDraftDet;//关闭送货草稿详情窗口
            //重新查询送货草稿
            this.toQueryDraft();
            //调用根据采购到货单主表标识查询到货单主表内容
            this.toQueryDelivery(data.arrVouchId);
        },
        /**
         * 根据采购到货单主表标识查询到货单主表内容
         */
        toQueryDelivery(id){
            let queryDeliveryParam={
                id:id//采购到货单主表标识
            }
            this.$axios.post("/u8/delivery/toQueryDelivery",queryDeliveryParam)
            .then((resp)=>{
                this.isDetRefresh=false;//是否刷新送货记录详情窗口子组件的属性
                if(resp.data.success=="true"){
                    this.selectpDelivery=resp.data.data;//设置查询到的送货记录主表内容
                    this.$nextTick(() => {
                        this.isDetRefresh=true;//是否刷新送货记录详情窗口子组件的属性
                    });
                    this.showDeliveryDet=true;//跳转打开送货记录详情窗口
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('送货记录主表服务器出错了');
                    this.$message('送货记录主表服务器出错了');
                }
            );
        },
        /**
         * 关闭送货记录详情窗口的方法
         */
        closeDeliveryDet(){
            this.showDeliveryDet=false;
            this.$nextTick(() => {
                this.isDetRefresh=true;//是否刷新送货记录详情窗口子组件的属性
            });
        },
        /**
         * 子组件传值关闭送货记录详情窗口的方法
         */
        changShowDeliveryDet(data){
            this.showDeliveryDet=data;//关闭送货草稿详情窗口
            this.$nextTick(() => {
                this.isDetRefresh=true;//是否刷新送货记录详情窗口子组件的属性
            });
            //重新查询送货草稿
            this.toQueryDraft();
        },
        /**
         * 打开合并发货选择框的方法
         */
        openSent(){
            this.isComSentRefresh=false;//是否刷新合并发货选择窗口子组件的属性
            this.$nextTick(() => {
                this.isComSentRefresh= true;//强制刷新合并发货选择页子组件数据
            });
            this.showSentSelect=true;//打开合并发货选择框
        },
        /**
         * 关闭合并发货选择框
         */
        closeSent(){
            this.showSentSelect=false;//关闭合并发货选择框
        },
        /**
         * 子组件传值关闭合并发货选择窗口然后打开确认合并发货的方法
         */
        changShowSent(data){
            this.showSentSelect=data.showSentDet;//关闭合并发货选择窗口
            this.selectpSents=data.selectpSents;//设置要合并发货的订单
            this.sentcSourceType=data.cSourceType;//设置要合并发货的订单来源单类型
            this.isSentRefresh=false;//是否刷新确认合并发货窗口子组件的属性
            this.$nextTick(() => {
                this.isSentRefresh= true;//强制刷新确认合并发货页子组件数据
            });
            this.showSentDet=true;//打开确认合并发货窗口
        },
        /**
         * 关闭合并发货页
         */
        closeSentDet(){
            this.showSentDet=false;//关闭确认合并发货页
            this.isSentRefresh=false;//是否刷新确认合并发货窗口子组件的属性
        },
        /**
         * 子组件传值关闭确认合并发货窗口的方法
         */
        changShowSentet(data){
            this.showSentDet=data.showSentDet;//关闭确认合并发货窗口
            if(data.showSentDet==false){
                this.isSentRefresh=false;//是否刷新确认合并发货窗口子组件的属性
            }
            //关闭确认合并发货窗口后直接打开刚新增送货草稿对应的送货草稿详情
            this.handleClick(data.selectpDraft);
        },
    },
    components:{
        //引入合并发货选择页框
        CombinedSent:CombinedSent,
        //引入送货草稿详情页
        DraftDetails:DraftDetails,
        //引入送货记录单详情页
        DeliveryDetails:DeliveryDetails,
        //引入确认合并发货页
        ToComSentDetails:ToComSentDetails
    }
}
</script>

<style>
    /* 查询条件每一行样式 */
    .query-row{
        height: 50px;
    }
    /* 查询区域每个条件的高度 */
    .query-col{
        height: 100%;
        text-align: left;
        padding-left: 10px;
    }
    /* 弹框样式 */
    .detailsForm{
        margin-top: 0px;
        width: 100%;
    }

    /* 步骤条样式 */
    .step {
        width: 1200px;
        margin: 44px auto 0;
    }
    .el-step__icon.is-icon {
        width: 10px;
        height: 10px;
    }
    .el-step__title.is-process {
        font-weight: 400;
        color: #303133;
    }
    .el-step__head.is-finish {
        color: #fca404;
        border-color: #fca404;
    }
    .el-step__title.is-finish {
        color: #fca404;
    }
    .el-step__description.is-finish {
        color: #fca404;
        height: 60px;
    }
    .el-step__head.is-finish {
        color: #fca404;
    }
    .el-step.is-horizontal .el-step__line {
        height: 2px;
        top: 10px;
    }
    .el-step{
        height: 70px !important;
    }
</style>