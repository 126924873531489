<template>
    <!-- 退货记录详情 -->
    <el-container>
        <!-- 表头 -->
        <el-header class="table-header" style="height:100px">
            <el-row style="margin-top:0px">
                <el-col :span="8" style="padding-left:22px">
                    <p style="text-align: left;height: 18px;line-height: 18px;">退货单号：{{returnDet.cCode}}</p>
                    <p style="text-align: left;height: 18px;line-height: 18px;">快递单号：{{returnDet.cDefine14}}</p>
                </el-col>
                <el-col :span="8" style="padding-left:22px">
                    <p style="text-align: left;height: 18px;line-height: 18px;">单据日期：{{returnDet.dDate}}</p>
                    <p style="text-align: left;height: 18px;line-height: 18px;">备注：{{returnDet.cMemo}}</p>
                </el-col>
                <el-col :span="8" style="padding-left:22px">
                    <p style="text-align: left;height: 18px;line-height: 18px;">供应商：{{returnDet.cVenName}}</p>
                    <p style="text-align: left;height: 18px;line-height: 18px;">来源单类型：{{returnDet.cBusType}}</p>
                </el-col>
            </el-row>
        </el-header>
        <el-main class="table-body">
            <!-- 送货草稿详情 -->
            <el-main class="return-order">
                <el-table
                    ref="singleTable"
                    :data="returnData"
                    v-loading="loading"
                    element-loading-text="正在加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                    :height="tableHeight"
                    border
                    size="mini"
                    style="width: 100%">
                    <el-table-column
                    type="index"
                    show-overflow-tooltip
                    min-width="5%">
                    </el-table-column>
                    <el-table-column
                    property="ivouchrowno"
                    label="行号"
                    show-overflow-tooltip
                    min-width="4%">
                    </el-table-column>
                    <el-table-column
                    property="cOrderCode"
                    show-overflow-tooltip
                    :label="returnDet.cBusType=='普通采购'?'采购单号':'委外单号'"
                    sortable
                    min-width="11%">
                    </el-table-column>
                    <el-table-column
                    property="cInvCode"
                    label="存货编码"
                    sortable
                    show-overflow-tooltip
                    min-width="10%">
                    </el-table-column>
                    <el-table-column
                    property="cInvName"
                    label="存货名称"
                    sortable
                    show-overflow-tooltip
                    min-width="11%">
                    </el-table-column>
                    <el-table-column
                    property="cInvStd"
                    show-overflow-tooltip
                    label="规格型号"
                    sortable
                    min-width="11%">
                    </el-table-column>
                    <el-table-column
                    property="iQuantity"
                    show-overflow-tooltip
                    label="发货数量"
                    sortable
                    min-width="9%">
                    </el-table-column>
                    <el-table-column
                    property="cComUnitName"
                    show-overflow-tooltip
                    label="单位"
                    sortable
                    min-width="6%">
                    </el-table-column>
                    <el-table-column
                    property="cBatch"
                    show-overflow-tooltip
                    label="批号"
                    sortable
                    min-width="11%">
                    </el-table-column>
                    <el-table-column
                    property="dPDate"
                    show-overflow-tooltip
                    label="生产日期"
                    sortable
                    min-width="11%">
                    </el-table-column>
                    <el-table-column
                    property="cbMemo"
                    show-overflow-tooltip
                    label="备注"
                    min-width="11%">
                    </el-table-column>
                </el-table>
            </el-main>
        </el-main>
    </el-container>
</template>

<script>
export default {
    props:["return"],
    created(){
        this.returnDet=this.return;//设置要查询的退货记录对象
        //根据采购送货主表标识查找退货记录详情
        this.getReturnDet();
    },
    data(){
        return{
            loading:false,//是否显示加载图标
            tableHeight:window.innerHeight - 345,//数据展示区域自适应高度
            returnDet:{},//要查询的退货记录对象
            returnData:[],//退货草稿详情数据
        }
    },
    methods:{
        /**
         * 根据采购送货主表标识查找退货记录详情
         */
        getReturnDet(){
            //清空之前查询到的数据
            this.returnData=[];
            let queryReturnDetParam={
                id:this.returnDet.id//采购送货单主表标识
            }
            this.$axios.post("/u8/return/getReturnDet",queryReturnDetParam)
            .then((resp)=>{
                this.returnData=resp.data.data;
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('退货记录服务器出错了');
                    this.$message('退货记录服务器出错了');
                }
            );
        }
    }
}
</script>

<style>
    /* 表体样式 */
    .table-body{
        height: 75%;
        margin: 5px;
        padding: 0px;
        /* background-color: yellow; */
    }

    /* 退货记录草稿详情 */
    .return-order{
        padding: 10px 20px;
        /* background-color: pink; */
    }
</style>