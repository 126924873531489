<template>
    <!-- 报价单（供应商） -->
    <el-container class="offerSupOrder">
        <!-- 查询条件区域 -->
        <el-header style="height:120px">
            <el-row style="text-align: left;margin-bottom:5px">
                <el-col :span="24">
                    <el-button type="warning" icon="el-icon-refresh" size="mini" @click="restQuery()">重置</el-button>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="queryOfferSup()">查询</el-button>
                    <el-button type="success" v-if="venUser.iAdmin!=1" icon="el-icon-circle-plus-outline" size="mini" @click="openAddOfferSup()">报价</el-button>
                    <el-button type="danger" v-if="venUser.iAdmin!=1" icon="el-icon-circle-plus" size="mini" @click="openEdAddOfferSup()">调价</el-button>
                </el-col>
            </el-row>
            <el-form :inline="true" :model="queryInline" class="demo-form-inline">
                <el-row class="query-row">
                    <el-col :span="8" class="query-col">
                        <el-form-item label="日期范围：">
                            <el-date-picker
                            v-model="queryInline.inquiryDates"
                            type="daterange"
                            align="right"
                            unlink-panels
                            size="mini"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width:210px"
                            :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col">
                        <el-form-item label="单据编码：">
                            <el-input size="mini" v-model.trim="queryInline.inquiryCode" clearable placeholder="单据编码" style="width:130px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col" style="padding-top:5px">
                        <el-form-item label="审核状态：">
                            <el-select size="mini" v-model="queryInline.isExamine" clearable placeholder="请选择" style="width:125px">
                                <el-option
                                v-for="item in isExamineOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="query-row">
                    <el-col :span="8" class="query-col">
                        <el-form-item label="业务员：">
                            <el-input size="mini" v-model.trim="queryInline.cPersonName" clearable placeholder="业务员姓名" style="width:130px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col">
                        <el-form-item label="报价单类型：">
                            <el-select size="mini" v-model="queryInline.offerType" clearable placeholder="请选择" style="width:115px">
                                <el-option
                                v-for="item in offerTypeOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col">
                        
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <!-- 供应商报价单数据展示区域 -->
        <el-main class="data-show">
            <el-table
                :data="offerSupData.slice((queryInline.pageIndex-1)*queryInline.pageSize,queryInline.pageIndex*queryInline.pageSize)"
                stripe
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :height="tableHeight"
                border
                ref="offerSupTable"
                size="mini"
                style="width: 100%;margin-bottom:5px"
                class="offerSupShow">
                <el-table-column
                type="index"
                min-width="5%">
                </el-table-column>
                <el-table-column
                prop="inquiryDate"
                label="单据日期"
                sortable
                show-overflow-tooltip
                min-width="15%">
                </el-table-column>
                <el-table-column
                prop="inquiryCode"
                label="单据编码"
                sortable
                show-overflow-tooltip
                min-width="15%">
                </el-table-column>
                <el-table-column
                prop="cPersonName"
                label="业务员"
                sortable
                show-overflow-tooltip
                min-width="8%">
                </el-table-column>
                <el-table-column
                prop="cVenName"
                label="供应商"
                sortable
                show-overflow-tooltip
                min-width="20%">
                </el-table-column>
                <el-table-column
                prop="offerType"
                label="报价类型"
                sortable
                show-overflow-tooltip
                min-width="8%">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.offerType!=null" :type="scope.row.offerType=='EM'?'success':scope.row.offerType=='ED'?'':'warning'">{{scope.row.offerType=='EM'?'报价':scope.row.offerType=='ED'?'调价':''}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                prop="isExamine"
                label="审核状态"
                sortable
                show-overflow-tooltip
                :filters="[{ text: '未生成报价单', value: -1 }, { text: '未审核', value: 0 }, { text: '供应商已审核', value: 1 }, { text: '采购方已审核', value: 2 }]"
                :filter-method="filterTag"
                min-width="11%">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.isExamine==1?'success':scope.row.isExamine==2?'':scope.row.isExamine==0?'danger':'warning'">{{scope.row.isExamine==1?'供应商已审核':scope.row.isExamine==2?'采购方已审核':scope.row.isExamine==0?'未审核':'未生成报价单'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                label="操作"
                min-width="18%">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-s-order" @click="handleClick(scope.row)" size="mini" style="margin-right:10px">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页查询区 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="this.queryInline.pageIndex"
                :page-sizes="this.queryInline.pageSizes"
                :page-size="this.queryInline.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.queryInline.total">
            </el-pagination>
            <!-- 询价单选择框 -->
            <el-dialog title="询价单选择" :visible.sync="showEnquirySupOrder" @close='closeEnquirySupDet' width="95%" id="selectEnquiry">
                <EnquirySupOrder v-if="isEnquiryRefresh" @genOfferSup="toGenOfferSup" :isSelect="isSelect"></EnquirySupOrder>
                <!-- 步骤条 -->
                <el-steps :active="0" :align-center="true" space="50%">
                    <el-step title="选择询价单"></el-step>
                    <el-step title="设置并生成报价单"></el-step>
                    <el-step title="审核报价单"></el-step>
                </el-steps>
                <el-row style="margin-top:10px">
                    <el-col :span="24" style="margin-bottom:10px">
                        <el-button type="danger" icon="el-icon-circle-close" @click="closeEnquirySupDet()">关闭</el-button>
                    </el-col>
                </el-row>
            </el-dialog>
            <!-- 供应商询价报价单详情框 -->
            <el-dialog title="供应商报价单详情" :visible.sync="showOfferSupDet" append-to-body @close='closeOfferSupDet' width="95%" class="detailsForm">
                <OfferSupDetails v-if="isRefresh" @showDetails="changShowOfferSupDet" @updOfferSup="toUpdOfferSup"  :offerSup="selectpOfferSup" :venUser="venUser" :decimalLength="decimalLength"></OfferSupDetails>
                <el-row style="margin-top:10px">
                    <el-col :span="24" style="margin-bottom:10px;text-align: center;">
                        <el-button type="danger" icon="el-icon-circle-close" @click="closeOfferSupDet()">关闭</el-button>
                    </el-col>
                </el-row>
            </el-dialog>
            <!-- 供应商调价单详情框 -->
            <el-dialog title="供应商调价单详情" :visible.sync="showEdOfferSupDet" append-to-body @close='closeEdOfferSupDet' width="95%" class="detailsForm">
                <EdOfferSupOrderDetails v-if="isEdOfferRefresh" @showDetails="changShowEdOfferSupDet" @updOfferSup="toUpdOfferSup" :offerSup="selectpOfferSup" :venUser="venUser" :isEdAdd="isEdAdd" :decimalLength="decimalLength"></EdOfferSupOrderDetails>
                <el-row style="margin-top:10px">
                    <el-col :span="24" style="margin-bottom:10px;text-align: center;">
                        <el-button type="danger" icon="el-icon-circle-close" @click="closeEdOfferSupDet()">关闭</el-button>
                    </el-col>
                </el-row>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
import EnquirySupOrder from '../enquirySup/EnquirySupOrderPage'
import OfferSupDetails from '../offerSup/OfferSupOrderDetailsPage'
import EdOfferSupOrderDetails from '../offerSup/EdOfferSupOrderDetailsPage'
export default {
    data(){
        return{
            //日历快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            venUser:{},//登录的供应商对象
            //查询参数
            queryInline:{
                inquiryDates:[],//日期范围数组
                inquiryCode:'',//单据编码
                cPersonName:'',//业务员名称
                offerType:'',//报价单类型
                isExamine:'',//审核状态
                pageSizes:[10, 32, 64, 100, 200,300],//页面容量数组
                pageSize:10,//每页容量
                pageIndex:1,//当前页码
                total:0//查询结果总量
            },
            //向后端查询供应商报价单的参数
            queryParam:{
                inquiryDates:[],//日期范围数组
                inquiryCode:'',//单据编码
                cPersonName:'',//业务员名称
                offerType:'',//报价单类型
                isExamine:'',//审核状态
                iAdmin:'',//供应商权限
                cVenCode:'',//供应商编码
            },
            //查询到的报价单数据
            offerSupData:[],
            loading:false,//是否显示加载图标
            tableHeight:window.innerHeight - 320,//数据展示区域自适应高度
            showOfferSupDet:false,//是否显示询价报价单详情框的属性
            showEdOfferSupDet:false,//是否显示主动报价单详情框的属性
            selectpOfferSup:{},//选中要查看报价单详情的送货草稿主表标识
            isRefresh:true,//是否刷新报价单详情窗口子组件的属性
            showEnquirySupOrder:false,//是否显示供应商询价单框的属性
            isSelect:true,//是否选择询价单的属性
            isEnquiryRefresh:true,//是否强制刷新选择询价单框窗口子组件的属性
            isEdOfferRefresh:true,//是否强制刷新主动报价框窗口子组件的属性
            isEdAdd:'',//打开的改价（主动报价）详情窗口是否是新增窗口的属性
            //报价单类型下拉框
            offerTypeOpt:[
                {
                    value: 'EM',
                    label: '报价'
                },
                {
                    value: 'ED',
                    label: '调价'
                }
            ],
            //审核状态下拉框
            isExamineOpt:[
                {
                    label:'未审核',
                    value:'noExamine'
                },
                {
                    label:'供应商已审核',
                    value:'venExamine'
                },
                {
                    label:'采购方已审核',
                    value:'purExamine'
                }
            ],
            decimalLength:0,//单价小数长度
        }
    },
    watch:{
        "queryInline.inquiryDates"(newVal){//监控日期范围条件的绑定值
            if(newVal==null||newVal.length==0){//如果日期范围的绑定值为null
                this.queryInline.inquiryDates=[];//该日期范围绑定值设置为[]
                //初始化查询时间范围
                this.initDateRange();
            }
        }
    },
    created(){
        //调用设置查询中供应商的参数
        this.setVenUser();
        //初始化查询时间范围
        this.initDateRange();
        //调用向后端查询供应商报价单的方法
        this.toQueryOfferSup();
        //调用获取单价小数位数
        this.getDecimalLength();
    },
    methods:{
        /**
         * 初始化查询时间范围
         */
        initDateRange(){
            let toDay=new Date();//获取天时间
            toDay.setHours(0,0,0);//时分秒设置为零
            let startDate=new Date(toDay.getTime()-(1000*60*60*24*30));
            this.queryInline.inquiryDates.push(startDate);//设置开始时间
            this.queryInline.inquiryDates.push(toDay);//设置结束时间
            this.queryParam.inquiryDates=this.queryInline.inquiryDates;//设置向后端查询的时间范围
        },
        /**
         * 获取单价小数位数
         */
        getDecimalLength(){
            this.$axios.post("/u8/offerSup/getDecimalLength")
            .then((resp)=>{
                if(resp.data.success=="true"){
                    this.decimalLength=resp.data.data;
                }else if(resp.data.success=="false"){
                    this.$message(resp.data.msg);
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('获取单价小数长度服务器出错了');
                    this.$message('获取单价小数长度服务器出错了');
                }
            );
        },
        /**
         * 刷新报价单标签状态的方法
         */
        filterTag(value, row) {
            return row.isExamine === value;
        },
        /**
         * 设置查询中供应商的参数
         */
        setVenUser(){
            //获取上级父组件传进来的参数并赋值
            this.venUser=this.$store.state.UserToken;//路由中获取到的是json字符串，要转化为json对象
            this.queryParam.iAdmin=this.venUser.iAdmin;//供应商权限
            this.queryParam.cVenCode=this.venUser.cVenCode;//供应商编码
        },
        /**
         * 重置查询条件的方法
         */
        restQuery(){
            this.queryInline.inquiryDates=[];//日期范围数组
            this.queryInline.inquiryCode='';//单据编码
            this.queryInline.cPersonName='';//业务员名称
            this.queryInline.offerType='';//报价单类型
            this.queryInline.isExamine='';//审核状态
        },
        /**
         * 点击查询报价单的方法
         */
        queryOfferSup(){
            //查询之前清空数据
            this.offerSupData=[]
            this.queryInline.pageIndex=1;//查询前重置为查询第一页
            this.queryInline.total=0;//清空查询到的供应商报价单总数
             //设置向后端查询供应商报价单的参数
            this.queryParam.inquiryDates=this.queryInline.inquiryDates;//日期范围数组
            this.queryParam.inquiryCode=this.queryInline.inquiryCode;//单据编码
            this.queryParam.cPersonName=this.queryInline.cPersonName;//业务员名称
            this.queryParam.offerType=this.queryInline.offerType;//报价单类型
            this.queryParam.isExamine=this.queryInline.isExamine;//审核状态
            //调用向后端查询供应商报价单的方法
            this.toQueryOfferSup();
        },
        /**
         * 向后端查询供应商报价单的方法
         */
        toQueryOfferSup(){
            this.loading=true;//显示加载页面
            this.$axios.post("/u8/offerSup/getEnquiryByexa",this.queryParam)
            .then((resp)=>{
                if(resp.data.success=="false"){
                    this.$message(resp.data.msg);
                }
                if(resp.data.success=="true"){
                    this.offerSupData=resp.data.data;//设置查询到的供应商报价单集合
                    this.queryInline.total=this.offerSupData.length;//查询到的供应商报价单数量
                }
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('供应商报价单服务器出错了');
                    this.$message('供应商报价单服务器出错了');
                }
            );
        },
        //分页查询
        /**
         * 改变每页显示的数量
         */
        handleSizeChange(val) {
            this.queryInline.pageSize=val;//设置每页容量
            this.queryInline.pageIndex=1;//改总量后，初始页面改为1
        },
        /**
         * 改变开始页码
         */
        handleCurrentChange(val) {
            this.queryInline.pageIndex=val;//设置当前页码
        },
        /**
         * 查看报价单详情的方法
         */
        handleClick(row){
            if(row.offerType=='EM'){
                this.selectpOfferSup=row;//设置选中的报价单主表标识
                this.isRefresh=false;//是否刷新报价单详情窗口子组件的属性
                this.$nextTick(() => {
                    this.isRefresh= true;//强制刷新报价单详情页子组件数据
                });
                this.isAddForm=false;//标记为查看窗口
                this.showOfferSupDet=true;//打开供应商报价详情框
            }else if(row.offerType=='ED'){
                this.selectpOfferSup=row;//设置选中的报价单主表标识
                this.isEdOfferRefresh=false;//是否强制刷新主动报价单详情窗口子组件的属性
                this.$nextTick(() => {
                    this.isEdAdd=false;//设置改价详情框不是为新增窗口
                    this.isEdOfferRefresh= true;//强制刷新主动报价单详情页子组件数据
                });
                this.isAddForm=false;//标记为查看窗口
                this.showEdOfferSupDet=true;//打开供应商调价详情框
            }
        },
        /**
         * 关闭报价单详情窗口的方法
         */
        closeOfferSupDet(){
            this.showOfferSupDet=false;
            this.selectpOfferSup={};//清空要查看报价单主表
        },
        /**
         * 子组件传值关闭询价报价单详情窗口的方法
         */
        changShowOfferSupDet(data){
            this.showOfferSupDet=data;
            this.selectpOfferSup={};//清空要查看报价单主表
            //重新查询报价单
            this.toQueryOfferSup();
        },
        /**
         * 子组件传值刷新查询到的报价单主表内容
         */
        toUpdOfferSup(){
            //重新查询报价单
            this.toQueryOfferSup();
        },
        /**
         * 打开供应商询价单选择框的方法
         */
        openAddOfferSup(){
            this.isEnquiryRefresh=false;//是否刷新供应商询价单选择框子组件的属性
            this.$nextTick(() => {
                this.isEnquiryRefresh= true;//强制刷新供应商询价单选择框子组件数据
            });
            this.showEnquirySupOrder=true;
        },
        /**
         * 关闭供应商询价单选择框的方法
         */
        closeEnquirySupDet(){
            this.showEnquirySupOrder=false;
        },
        /**
         * 在供应商询价单打开报价单窗口的方法
         */
        toGenOfferSup(data){
            console.log('打开报价单参数是：',data);
            let queryOfferParam={
                inquiryCode:data.inquiryCode,//单据编码
                cVenCode:this.venUser.cVenCode//供应商编码
            }
            this.$axios.post("/u8/offerSup/checkOfferByCode",queryOfferParam)
            .then((resp)=>{
                if(resp.data.success=="false"){
                    return this.$message(resp.data.msg);
                }
                //先关闭供应商询价单选择框
                this.showEnquirySupOrder=false;
                this.selectpOfferSup=data;//设置选中的报价单主表标识
                console.log('要打开的报价单主表是：',this.selectpOfferSup);
                this.isRefresh=false;//是否刷新询价报价单详情窗口子组件的属性
                this.$nextTick(() => {
                    this.isRefresh= true;//强制刷新询价报价单详情页子组件数据
                });
                this.isAddForm=false;//标记为查看窗口
                this.showOfferSupDet=true;//打开供应商报价详情框
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('供应商报价单服务器出错了');
                    this.$message('供应商报价单服务器出错了');
                }
            );
        },
        /**
         * 打开主动报价单详情窗口
         */
        openEdAddOfferSup(){
            this.isEdOfferRefresh=false;//是否强制刷新主动报价单详情窗口子组件的属性
            this.$nextTick(() => {
                this.isEdAdd=true;//设置改价详情框为新增窗口
                this.isEdOfferRefresh= true;//强制刷新主动报价单详情页子组件数据
            });
            this.showEdOfferSupDet=true;//打开主动报价单详情页面
        },
        /**
         * 关闭主动报价单详情页面
         */
        closeEdOfferSupDet(){
            this.showEdOfferSupDet=false;//打开主动报价单详情页面
            this.selectpOfferSup={};//清空要查看报价单主表
        },
        /**
         * 子组件传回关闭改价报价单详情窗口的方法
         */
        changShowEdOfferSupDet(data){
            this.showEdOfferSupDet=data;
            this.selectpOfferSup={};//清空要查看报价单主表
            //重新查询报价单
            this.toQueryOfferSup();
        },
    },
    components:{
        //引入供应商询价单
        EnquirySupOrder:EnquirySupOrder,
        //引入供应商询价报价单详情页
        OfferSupDetails:OfferSupDetails,
        //引入供应商主动报价单详情页
        EdOfferSupOrderDetails:EdOfferSupOrderDetails,
    }
}
</script>

<style>
    /* 步骤条样式 */
    .step {
        width: 1200px;
        margin: 44px auto 0;
    }
    .el-step__icon.is-icon {
        width: 10px;
        height: 10px;
    }
    .el-step__title.is-process {
        font-weight: 400;
        color: #303133;
    }
    .el-step__head.is-finish {
        color: #fca404;
        border-color: #fca404;
    }
    .el-step__title.is-finish {
        color: #fca404;
    }
    .el-step__description.is-finish {
        color: #fca404;
        height: 60px;
    }
    .el-step__head.is-finish {
        color: #fca404;
    }
    .el-step.is-horizontal .el-step__line {
        height: 2px;
        top: 10px;
    }
    .el-step{
        height: 70px !important;
    }
</style>

<style lang="less">
    #selectEnquiry{
        .el-dialog{
            margin-top:0px !important;
            margin-bottom: 0px !important;
        }
    }
</style>