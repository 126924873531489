<template>
    <el-container>
        <!-- 查询条件区域 -->
        <el-header class="query-condition" style="height:40px;text-align:left;">
            <el-button type="primary" icon="el-icon-search" size="mini" @click="getParam()">查询</el-button>
            <el-button type="success" icon="el-icon-finished" size="mini" @click="saveParam()">保存</el-button>
        </el-header>
        <el-main class="param-data-show">
            <el-table
                :data="paramData"
                stripe
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :height="paramTableHeight"
                :stripe="true"
                border
                ref="paramTable"
                size="mini"
                style="width: 100%;margin-bottom:5px"
                class="paramShow">
                <el-table-column
                    type="index"
                    width="50px">
                </el-table-column>
                <el-table-column
                    prop="cName"
                    label="字段名称"
                    sortable
                    show-overflow-tooltip
                    min-width="100px">
                </el-table-column>
                <el-table-column
                    prop="cCaption"
                    label="字段说明"
                    sortable
                    show-overflow-tooltip
                    min-width="300px">
                </el-table-column>
                <el-table-column
                    prop="cDefault"
                    label="默认值"
                    sortable
                    show-overflow-tooltip
                    min-width="100px">
                    <template slot-scope="scope">
                        <template v-if="scope.row.cType=='Integer'">
                            <el-tag size="mini" :type="scope.row.cDefault=='0'?'warning':'success'">{{scope.row.cDefault=='0'?'否':'是'}}</el-tag>
                        </template>
                        <template v-else>
                            <el-tag size="mini" :type="scope.row.cDefault=='False'?'warning':'success'">{{scope.row.cDefault=='False'?'否':'是'}}</el-tag>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="cValue"
                    label="设置值"
                    sortable
                    show-overflow-tooltip
                    min-width="100px">
                    <template slot-scope="scope">
                        <template v-if="scope.row.cType=='Integer'">
                            <el-select size="mini" v-model="scope.row.cValue" placeholder="请选择">
                                <el-option
                                    v-for="item in valueOpt"
                                    :key="item.cValue"
                                    :label="item.label"
                                    :value="item.cValue">
                                </el-option>
                            </el-select>
                        </template>
                        <template v-else>
                            <el-select size="mini" v-model="scope.row.cValue" placeholder="请选择">
                                <el-option
                                    v-for="item in booleanOpt"
                                    :key="item.cValue"
                                    :label="item.label"
                                    :value="item.cValue">
                                </el-option>
                            </el-select>
                        </template>
                        
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
    </el-container>
</template>

<script>
export default {
    data(){
        return{
            paramData:[],//查询到参数数据
            loading:false,//是否显示加载界面的参数
            valueOpt:[
                {
                    cValue:'0',
                    label:'否'
                },
                {
                    cValue:'1',
                    label:'是'
                }
            ],
            booleanOpt:[
                {
                    cValue:'False',
                    label:'否'
                },
                {
                    cValue:'True',
                    label:'是'
                }
            ]
        }
    },
    computed:{
        //参数数据展示区域自适应高度
        paramTableHeight(){
            return window.innerHeight - 150;
        }
    },
    created(){
        //调用获取供应商平台参数的方法
        this.getParam();
    },
    methods:{
        /**
         * 获取供应商平台参数的方法
         */
        getParam(){
            let that=this;
            this.$axios.post("/u8/param/getParam")
            .then((resp)=>{
                if(resp.data.success=="true"){
                    this.paramData=resp.data.data;
                }else{
                    this.$message({
                        showClose:true,
                        type:'warning',
                        message:resp.data.msg
                    });
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('获取供应商平台参数服务器出错了');
                    that.$message.error('获取供应商平台参数服务器出错了');
                }
            );
        },
        /**
         * 保存供应商平台参数的方法
         */
        saveParam(){
            let that=this;
            if(this.paramData.length<1){
                return this.$message({
                    showClose:true,
                    type:'warning',
                    message:'没有找到要保存的参数'
                });
            }
            this.$axios.post("/u8/param/saveParam",this.paramData)
            .then((resp)=>{
                if(resp.data.success=="true"){
                    this.$message({
                        showClose:true,
                        type:'success',
                        message:resp.data.msg
                    });
                }else{
                    this.$message({
                        showClose:true,
                        type:'warning',
                        message:resp.data.msg
                    });
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('保存供应商平台参数服务器出错了');
                    that.$message.error('保存供应商平台参数服务器出错了');
                }
            );
        }
    }
}
</script>

<style>
    /* 参数页总框样式 */
    .param-data-show{
        padding-top:0px;
    }
</style>