<template>
    <!-- 报价单（采购方） -->
    <el-container>
        <!-- 查询条件区域 -->
        <el-header style="height:110px">
            <el-row style="text-align: left;margin-bottom:5px">
                <el-col :span="24">
                    <el-button type="warning" icon="el-icon-refresh" size="mini" @click="restQuery()">重置</el-button>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="queryOffer()">查询</el-button>
                </el-col>
            </el-row>
            <el-form :inline="true" :model="queryInline" class="demo-form-inline">
                <el-row class="query-row">
                    <el-col :span="8" class="query-col">
                        <el-form-item label="日期范围：">
                            <el-date-picker
                            v-model="queryInline.inquiryDates"
                            type="daterange"
                            align="right"
                            unlink-panels
                            size="mini"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width:210px"
                            :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col">
                        <el-form-item label="单据编码：">
                            <el-input size="mini" v-model.trim="queryInline.inquiryCode" clearable placeholder="单据编码" style="width:130px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col">
                        <el-form-item label="业务员：">
                            <el-input size="mini" v-model.trim="queryInline.cPersonName" clearable placeholder="业务员姓名" style="width:130px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="query-row">
                    <el-col :span="8" class="query-col">
                        <el-form-item label="供应商：">
                            <el-input size="mini" v-model.trim="queryInline.cVenName" clearable placeholder="供应商名称" style="width:130px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col">
                        <el-form-item label="报价单类型：">
                            <el-select size="mini" v-model="queryInline.offerType" clearable placeholder="请选择" style="width:115px">
                                <el-option
                                v-for="item in offerTypeOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="query-col" style="padding-top:5px">
                        <el-form-item label="审核状态：">
                            <el-select size="mini" v-model="queryInline.isExamine" clearable placeholder="请选择" style="width:115px">
                                <el-option
                                v-for="item in isExamineOpt"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <!-- 报价单数据展示区域 -->
        <el-main class="data-show">
            <el-table
                :data="offerData.slice((queryInline.pageIndex-1)*queryInline.pageSize,queryInline.pageIndex*queryInline.pageSize)"
                stripe
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :height="tableHeight"
                border
                ref="offerTable"
                size="mini"
                style="width: 100%;margin-bottom:5px"
                class="offerShow">
                <el-table-column
                type="index"
                min-width="5%">
                </el-table-column>
                <el-table-column
                prop="inquiryDate"
                label="单据日期"
                sortable
                show-overflow-tooltip
                min-width="15%">
                </el-table-column>
                <el-table-column
                prop="inquiryCode"
                label="单据编码"
                sortable
                show-overflow-tooltip
                min-width="15%">
                </el-table-column>
                <el-table-column
                prop="cPersonName"
                label="业务员"
                sortable
                show-overflow-tooltip
                min-width="10%">
                </el-table-column>
                <el-table-column
                prop="offerType"
                sortable
                label="报价单类型"
                show-overflow-tooltip
                min-width="15%">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.offerType!=null" :type="scope.row.offerType=='EM'?'success':scope.row.offerType=='ED'?'':'warning'">{{scope.row.offerType=='EM'?'报价':scope.row.offerType=='ED'?'调价':''}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                prop="isExamine"
                sortable
                label="审核状态"
                show-overflow-tooltip
                min-width="10%">
                    <template slot-scope="scope">
                        <!-- 询价单的审核状态 -->
                                <el-tag v-if="scope.row.offerType=='EM'" :type="scope.row.isExamine==1?'success':'danger'">{{scope.row.isExamine==1?'已审核':'未审核'}}</el-tag>
                                <!-- 调价单的审核状态 -->
                                <el-tag v-if="scope.row.offerType=='ED'" :type="scope.row.isExamine==1?'warning':scope.row.isExamine==2?'':'danger'">{{scope.row.isExamine==1?'未审核':scope.row.isExamine==2?'已审核':'供应商未审核'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                prop="cVenName"
                label="供应商"
                sortable
                show-overflow-tooltip
                min-width="15%">
                </el-table-column>
                <el-table-column
                label="操作"
                min-width="15%">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-s-release" @click="handleClick(scope.row)" size="mini" style="margin-right:10px">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页查询区 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="this.queryInline.pageIndex"
                :page-sizes="this.queryInline.pageSizes"
                :page-size="this.queryInline.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.queryInline.total">
            </el-pagination>
            <!-- 报价单详情框 -->
            <el-dialog :title="selectpOffer.offerType=='EM'?'报价单详情':'调价单详情'" :visible.sync="showOfferDet" @close='closeOfferDet' width="95%" class="detailsForm">
                <OfferDetails @showDetails="changShowOfferDet" @updOffer="toUpdOffer" v-if="isRefresh" :offer="selectpOffer" :venUser="venUser"></OfferDetails>
                <el-row style="margin-top:10px">
                    <el-col :span="24" style="margin-bottom:10px">
                        <el-button type="danger" icon="el-icon-circle-close" @click="closeOfferDet()">关闭</el-button>
                    </el-col>
                </el-row>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
import OfferDetails from '../offer/OfferOrderDetailsPage'
export default {
    data(){
        return{
            //日历快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            //查询参数
            queryInline:{
                inquiryDates:[],//日期范围数组
                inquiryCode:'',//单据编码
                cPersonName:'',//业务员名称
                cVenName:'',//供应商名称
                offerType:'',//报价单类型
                isExamine:'',//审核状态
                pageSizes:[10, 32, 64, 100, 200,300],//页面容量数组
                pageSize:10,//每页容量
                pageIndex:1,//当前页码
                total:0//查询结果总量
            },
            //向后端查询报价单的参数
            queryParam:{
                inquiryDates:[],//日期范围数组
                inquiryCode:'',//单据编码
                cPersonName:'',//业务员名称
                cVenName:'',//供应商名称
                offerType:'',//报价单类型
                isExamine:'',//审核状态
            },
            //查询到的报价单数据
            offerData:[],
            loading:false,//是否显示加载图标
            tableHeight:window.innerHeight - 310,//数据展示区域自适应高度
            showOfferDet:false,//是否显示报价单详情框的属性
            selectpOffer:{},//选中要查看报价单详情的送货草稿主表标识
            isRefresh:false,//是否刷新报价单详情窗口子组件的属性
            //报价单类型下拉框
            offerTypeOpt:[
                {
                    value: 'EM',
                    label: '报价'
                },
                {
                    value: 'ED',
                    label: '调价'
                }
            ],
            //审核状态下拉框
            isExamineOpt:[
                {
                    label:'已审核',
                    value:'isExamine'
                },
                {
                    label:'未审核',
                    value:'noExamine'
                }
            ],
            venUser:{},//登录的供应商对象
        }
    },
    watch:{
        "queryInline.inquiryDates"(newVal){//监控日期范围条件的绑定值
            if(newVal==null||newVal.length==0){//如果日期范围的绑定值为null
                this.queryInline.inquiryDates=[];//该日期范围绑定值设置为[]
                //初始化查询时间范围
                this.initDateRange();
            }
        }
    },
    created(){
        //调用设置查询中供应商的参数
        this.setVenUser();
        //初始化查询时间范围
        this.initDateRange();
        //调用向后端查询采购方报价单的方法
        this.toQueryOffer();
    },
    methods:{
        /**
         * 初始化查询时间范围
         */
        initDateRange(){
            let toDay=new Date();//获取天时间
            toDay.setHours(0,0,0);//时分秒设置为零
            let startDate=new Date(toDay.getTime()-(1000*60*60*24*30));
            this.queryInline.inquiryDates.push(startDate);//设置开始时间
            this.queryInline.inquiryDates.push(toDay);//设置结束时间
            this.queryParam.inquiryDates=this.queryInline.inquiryDates;//设置向后端查询的时间范围
        },
        /**
         * 设置查询中供应商的参数
         */
        setVenUser(){
            //获取上级父组件传进来的参数并赋值
            this.venUser=this.$store.state.UserToken;//路由中获取到的是json字符串，要转化为json对象
            this.queryParam.iAdmin=this.venUser.iAdmin;//供应商权限
            this.queryParam.cVenCode=this.venUser.cVenCode;//供应商编码
        },
        /**
         * 重置查询条件的方法
         */
        restQuery(){
            this.queryInline.inquiryDates=[];//日期范围数组
            this.queryInline.inquiryCode='';//单据编码
            this.queryInline.cPersonName='';//业务员名称
            this.queryInline.cVenName='';//供应商名称
            this.queryInline.offerType='';//报价单类型
            this.queryInline.isExamine='';//审核状态
        },
        /**
         * 点击查询报价单的方法
         */
        queryOffer(){
            
             //设置向后端查询报价单的参数
            this.queryParam.inquiryDates=this.queryInline.inquiryDates;//日期范围数组
            this.queryParam.inquiryCode=this.queryInline.inquiryCode;//单据编码
            this.queryParam.cPersonName=this.queryInline.cPersonName;//业务员名称
            this.queryParam.cVenName=this.queryInline.cVenName;//供应商名称
            this.queryParam.offerType=this.queryInline.offerType;//报价单类型
            this.queryParam.isExamine=this.queryInline.isExamine;//报价单类型
            //调用向后端查询报价单的方法
            this.toQueryOffer();
        },
        /**
         * 向后端查询报价单的方法
         */
        toQueryOffer(){
            //查询之前清空数据
            this.offerData=[]
            this.queryInline.pageIndex=1;//查询前重置为查询第一页
            this.queryInline.total=0;//清空查询到的报价单总数
            this.loading=true;//显示加载页面
            this.$axios.post("/u8/offer/getOffer",this.queryParam)
            .then((resp)=>{
                if(resp.data.success=="false"){
                    this.$message(resp.data.msg);
                }
                if(resp.data.success=="true"){
                    this.offerData=resp.data.data;//设置查询到的采购方报价单集合
                    this.queryInline.total=this.offerData.length;//查询到的报价单数量
                }
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('报价单服务器出错了');
                    this.$message('报价单服务器出错了');
                }
            );
        },
        //分页查询
        /**
         * 改变每页显示的数量
         */
        handleSizeChange(val) {
            this.queryInline.pageSize=val;//设置每页容量
            this.queryInline.pageIndex=1;//改总量后，初始页面改为1
        },
        /**
         * 改变开始页码
         */
        handleCurrentChange(val) {
            this.queryInline.pageIndex=val;//设置当前页码
        },
        /**
         * 查看报价单详情的方法
         */
        handleClick(row){
            this.selectpOffer=row;//设置选中的报价单主表标识
            this.isRefresh=false;//是否刷新报价单详情窗口子组件的属性
            this.$nextTick(() => {
                this.isRefresh= true;//强制刷新报价单详情页子组件数据
            });
            this.isAddForm=false;//标记为查看窗口
            this.showOfferDet=true;//打开采购方报价单详情框
        },
        /**
         * 关闭报价单详情窗口的方法
         */
        closeOfferDet(){
            this.showOfferDet=false;//关闭采购方报价单详情框
        },
        /**
         * 子组件传值关闭报价单详情窗口的方法
         */
        changShowOfferDet(data){
            this.showOfferDet=data;
            //重新查询报价单
            this.toQueryOffer();
        },
        /**
         * 子组件传值刷新查询到的报价单主表内容
         */
        toUpdOffer(){
            //重新查询报价单
            this.toQueryOffer();
            //关闭报价单详情框
            this.showOfferDet=false;
        }
    },
    components:{
        //引入报价单详情页
        OfferDetails:OfferDetails,
    }
}
</script>

<style>

</style>