<template>
    <!-- 查看采购到货单附件的窗口 -->
    <el-container>
        <el-dialog title="采购到货单附件" :visible.sync="showReportForm" :show-close="false" width="60%" class="ReportDetailsForm">
            <el-row style="margin-bottom:10px">
                <el-col :span="6">
                    <span>采购订单号:{{deliveryReport.cpocode}}</span>
                </el-col>
                <el-col :span="6">
                    <span>采购到货单号:{{deliveryReport.cCode}}</span>
                </el-col>
                <el-col :span="12">
                    <el-row>
                        <el-col :span="14" style="text-align: right;">
                            <el-input v-if="venUser.iAdmin!=1" size="mini" clearable placeholder="附件备注" v-model="showDeliveryReport.fileContent" style="width:100%;">
                                <template slot="prepend">附件备注</template>
                            </el-input>
                        </el-col>
                        <el-col :span="10" style="text-align: left;">
                            <!-- 上传 -->
                            <el-tooltip class="item" effect="dark" content="仅支持pdf、png、jpg、zip、rar格式文件上传" placement="top" style="width:100px">
                                <el-upload
                                class="upload-demo"
                                ref="upload"
                                action="/u8/report/uploadReport/"
                                :data="showDeliveryReport"
                                :on-success="returnProgressSucc"
                                :on-error="returnProgressErr"
                                :show-file-list="false">
                                    <el-button v-if="venUser.iAdmin!=1" slot="trigger" icon="el-icon-upload" size="mini" type="primary" style="margin-left:5px">上传附件</el-button>
                                </el-upload>
                            </el-tooltip>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <!-- 附件展示区 -->
            <el-table
                :data="reports"
                border
                size="mini"
                :height="reportTableHeight"
                style="width: 100%">
                <el-table-column
                type="index"
                min-width="5%">
                </el-table-column>
                </el-table-column>
                <el-table-column
                prop="fileName"
                label="文件名称"
                show-overflow-tooltip
                min-width="37%">
                </el-table-column>
                <el-table-column
                prop="suffix"
                label="文件格式"
                show-overflow-tooltip
                min-width="9%">
                </el-table-column>
                <el-table-column
                prop="fileContent"
                label="文件内容"
                show-overflow-tooltip
                min-width="19%">
                </el-table-column>
                <el-table-column
                prop="upLoadTime"
                label="上传时间"
                show-overflow-tooltip
                min-width="16%">
                </el-table-column>
                <el-table-column
                label="操作"
                min-width="14%">
                    <template slot-scope="scope" style="padding-left:0px">
                        <el-button @click="viewReport(scope.row)" size="mini" type="primary" style="padding-left:5px;padding-right:5px">{{scope.row.suffix=="zip"||scope.row.suffix=="rar"?'下载':'查看'}}</el-button>
                        <el-popconfirm title="确定删除该附件？" @confirm="delReport(scope.row)">
                            <el-button v-if="venUser.iAdmin!=1"  size="mini" type="danger" slot="reference" style="margin-left:2px;padding-left:5px;padding-right:5px">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <el-row>
                <el-col :span="24" style="margin-top:10px;margin-bottom:10px">
                    <el-button size="mini" type="danger" @click="closeReportForm()">关闭</el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </el-container>
</template>

<script>
export default {
    // props:["showReportForm","deliveryReport"],
    props:{
        showReportForm:Boolean,//是否显示采购到货单附件窗口的属性
        deliveryReport:Object,//要查看附件的采购到货单
        venUser:Object,//登录的供应商对象
    },
    data(){
        return{
            //查看附件的采购到货单对象
            showDeliveryReport:{
                id:'',//采购到货单主表标识
                cCode:'',//采购到货单号（送货单号）
                cpocode:'',//采购订单号（采购单号）
                dDate:'',//到货日期（送货日期）
                cVenCode:'',//供应商编码
                cPersonCode:'',//采购员编码
                cDefine14:'',//快递单号
                cMemo:'',//备注
                cAuditDate:'',//审核时间
                cBusType:'',//业务类型（来源单类型）
                cVenName:'',//供应商名称
                cPersonName:'',//业务员名称
                fileContent:'',//文件内容
            },
            //查询到的附件信息
            reports:[],
            ipAndPort:'/u8/',//获取到的后端ip及端口地址(跨域处理)
        }
    },
    computed:{
        //计算附件数据区域自适应高度
        reportTableHeight(){
            return document.documentElement.clientHeight-350;
        }
    },
    created(){
        //调用初始化要查看附件的采购到货单对象
        this.initDeliveryReport();
    },
    methods:{
        /**
         * 初始化要查看附件的采购到货单对象
         */
        initDeliveryReport(){
            this.showDeliveryReport.id=this.deliveryReport.id;//采购到货单主表标识
            this.showDeliveryReport.cCode=this.deliveryReport.cCode;//采购到货单号（送货单号）
            this.showDeliveryReport.cpocode=this.deliveryReport.cpocode;//采购订单号（采购单号）
            this.showDeliveryReport.dDate=this.deliveryReport.dDate;//到货日期（送货日期）
            this.showDeliveryReport.cVenCode=this.deliveryReport.cVenCode;//供应商编码
            this.showDeliveryReport.cPersonCode=this.deliveryReport.cPersonCode;//采购员编码
            this.showDeliveryReport.cDefine14=this.deliveryReport.cDefine14;//快递单号
            this.showDeliveryReport.cMemo=this.deliveryReport.cMemo;//备注
            this.showDeliveryReport.cAuditDate=this.deliveryReport.cAuditDate;//审核时间
            this.showDeliveryReport.cBusType=this.deliveryReport.cBusType;//业务类型（来源单类型）
            this.showDeliveryReport.cVenName=this.deliveryReport.cVenName;//供应商名称
            this.showDeliveryReport.cPersonName=this.deliveryReport.cPersonName;//业务员名称
            //调用根据采购到货单查询对应的附件信息
            this.getReportByCode();
        },
        /**
         * 根据采购到货单查询对应的附件信息
         */
        getReportByCode(){
            //查询前清空之前查询到的附件信息
            this.reports=[];
            let than=this;
            this.$axios.post("/u8/report/getReportByCode",this.showDeliveryReport)
            .then((resp)=>{
                if(resp.data.success=="true"){
                    this.reports=resp.data.data;
                }else{
                    this.$message(resp.data.msg);
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('附件服务器出错了');
                    than.$message('附件服务器出错了');
                }
            );
        },
        /**
         * 传递给父组件关闭采购到货单附件窗口
         */
        closeReportForm(){
            this.$emit('toCloseReportForm',false)//调用父组件刷新查询报价单主表的方法
        },
        /**
         * 上传附件返回（没报错情况下返回）
         */
        returnProgressSucc(response, file, fileList){
            if(response.success=="true"){
                this.$message(response.msg);
                this.showDeliveryReport.fileContent='';//重置上传附件的备注信息
                //重新调用根据采购到货单查询对应的附件信息
                this.getReportByCode();
            }else{
                this.$notify({
                    title: '警告',
                    message: response.msg,
                    type: 'warning'
                });
            }
        },
        /**
         * 上传附件出错时返回
         */
        returnProgressErr(err, file, fileList){
            //上传的文件名
            let fileName=file.name;
            //上传出错的报错信息
            let errStr='上传：'+fileName+'文件失败';
            this.$notify.error({
                title: '错误',
                message: errStr
            });
        },
        /**
         * 查看附件的方法
         */
        viewReport(row){
            if(row.suffix.toLowerCase()=="pdf"){//如果是pdf格式文件
                //调用浏览pdf附件的方法
                this.browsePdf(row);
            }
            if(row.suffix.toLowerCase()=="jpg"||row.suffix.toLowerCase()=="png"){
                //调用浏览图片的方法
                this.browseIicture(row);
            }
            if(row.suffix.toLowerCase()=="zip"||row.suffix.toLowerCase()=="rar"){
                this.downFile(row);
            }
        },
        /**
         * 浏览pdf附件的方法
         */
        browsePdf(row){
            //拼接要查看附件的服务器路径
            let baseurl=this.ipAndPort+row.suffix+'/'+row.fileName;
            window.open('/pdf/web/viewer.html?file=' + encodeURIComponent(baseurl));
        },
        /**
         * 浏览图片的方法
         */
        browseIicture(row){
            //拼接要查看附件的服务器路径
            let baseurl=this.ipAndPort+row.suffix+'/'+row.fileName;
            window.open(baseurl);
        },
        /**
         * 下载文件的方法
         */
        downFile(row){
            //拼接要查看附件的服务器路径
            let baseurl=this.ipAndPort+row.suffix+'/'+row.fileName;
            window.open(baseurl);
        },
        /**
         * 删除附件的方法
         */
        delReport(row){
            let than=this;
            this.$axios.post("/u8/report/delReport",row)
            .then((resp)=>{
                this.$message(resp.data.msg);
                if(resp.data.success=="true"){
                    //重新调用根据采购到货单查询对应的附件信息
                    this.getReportByCode();
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('附件服务器出错了')
                    than.$message('附件服务器出错了');
                }
            );
        }
    }
}
</script>

<style>
    .ReportDetailsForm{
        padding-top: 50px;
    }
</style>