//导入vue-router
import VueRouter from 'vue-router';
//导入登录页
import Login from '../components/LoginPage'
//导入主页
import IndexPage from '../components/IndexPage'
//导入欢迎页
import Welcome from '../components/WelcomPage'
//导入采购订单页
import PurchasePage from '../components/purchase/PurchasePage'
//导入委外订单页
import OutsourcingPage from '../components/outsourcing/OutsourcingPage'
//导入送货草稿页
import DraftPage from '../components/draft/DraftPage'
//导入到送货记录
import DeliveryOrderPage from '../components/delivery/DeliveryOrderPage'
//导入退货单
import SalesReturnPage from '../components/salesReturn/SalesReturnPage'
//导入供应商询价单
import EnquirySupOrderPage from '../components/enquirySup/EnquirySupOrderPage'
//导入供应商报价单
import OfferSupOrderPage from '../components/offerSup/OfferSupOrderPage'
//导入供应商对账单
import CheckSupOrderPage from '../components/checkSup/CheckSupOrderPage'
//导入采购方询价单
import EnquiryOrderPage from '../components/enquiry/EnquiryOrderPage'
//导入采购方报价单
import OfferOrderPage from '../components/offer/OfferOrderPage'
//导入采购方对账单
import CheckOrderPage from '../components/check/CheckOrderPage'
//导入发票对账单
import InvoiceChecksPage from '../components/invoiceChecks/InvoiceChecksPage'
//导入参数页
import ParameterPage from '../components/param/ParameterPage'



//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

//路由表
const routes=[
    {path:'/',name:'login',component:Login,props:true,meta:{title:'登录页'}},
    {path:'/index',name:'index',component:IndexPage,props:true,
    children:[
        {path:'welcome',name:'welcome',component:Welcome,meta:{title:'欢迎页'}},
        {path:'purchase',name:'purchase',component:PurchasePage,meta:{title:'采购订单'},props:true},//采购订单表
        {path:'outsourcing',name:'outsourcing',component:OutsourcingPage,meta:{title:'委外订单'},props:true},//委外订单表
        {path:'draft',name:'draft',component:DraftPage,meta:{title:'送货草稿'},props:true},//送货草稿
        {path:'deliveryOrder',name:'deliveryOrder',component:DeliveryOrderPage,meta:{title:'送货记录'},props:true},//送货记录
        {path:'salesReturn',name:'salesReturn',component:SalesReturnPage,meta:{title:'退货记录'},props:true},//退货单
        {path:'enquirySupOrder',name:'enquirySupOrder',component:EnquirySupOrderPage,meta:{title:'供应商询价单'},props:true},//供应商询价单
        {path:'offerSupOrder',name:'offerSupOrder',component:OfferSupOrderPage,meta:{title:'供应商报价'},props:true},//供应商报价单
        {path:'checkSupOrder',name:'CheckSupOrder',component:CheckSupOrderPage,meta:{title:'供应商对账'},props:true},//供应商对账单
        {path:'enquiryOrder',name:'enquiryOrder',component:EnquiryOrderPage,meta:{title:'询价单'},props:true},//采购方询价单
        {path:'offerOrder',name:'offerOrder',component:OfferOrderPage,meta:{title:'报价单'},props:true},//采购方报价单
        {path:'checkOrder',name:'checkOrder',component:CheckOrderPage,meta:{title:'对账单'},props:true},//采购方对账单
        {path:'invoiceChecks',name:'invoiceChecks',component:InvoiceChecksPage,meta:{title:'发票对账单'},props:true},//发票对账单
        {path:'parameter',name:'parameter',component:ParameterPage,meta:{title:'参数设置'},props:true},//参数设置页
    ],
    meta:{
        title:'U8供应商平台'
    }}
]

//路由器
let router=new VueRouter(
    {
        routes,
        mode: 'history'  //把Router的mode修改为history模式,VueRouter默认的模式为HASH模式
    }
);
    
//全局守卫
router.beforeEach((to,from,next)=>{
    if(to.matched){
        let r=to.matched.filter(route=>route.meta && route.meta.title);
        if(r[0]){
            window.document.title=r[0].meta.title;//给不同的页面设置不同的标题
        }
    }
    if(to.path==='/'){//如果打开的是登录页面
        next();//正常跳转
    }else{//如果打开的不是登录页面，则判断是否已经存在token
        //localStorage.getItem获取到的是json字符串，要先转为json对象
        let token=JSON.parse(localStorage.getItem('UserToken'));//从stroe仓库中获取共享数据（用户token）
        if(token===''||token===null){//如果token等于空
            next('/');//强制跳转到登录页面
        }else{//如果已经存在token了
            //全露守卫拦截生效时间
            let checkDate=new Date();
            //获取登录是的时间
            let loginDate=new Date(token.loginDate);
            //拦截生效和登录时间的时间差
            let timeDifference=checkDate.getTime()-loginDate.getTime()
            if(timeDifference>1200000){//如果时间差大于20分钟则超时需要重新登录
                localStorage.removeItem('UserToken');//移除本地存储数据
                next('/');//强制跳转到登录页面
            }else{
                token.loginDate=checkDate;//重新赋值登录时间
                localStorage.setItem('UserToken', JSON.stringify(token));//设置本地存储的 name/value
                next();//正常跳转
            }
        }
    }
});


//导出（可供其他模块使用）
export default router;