<template>
    <!-- 询价单详情（采购方） -->
    <el-container>
        <!-- 表头 -->
        <el-header class="table-header" style="height:100px">
            <el-row style="text-align: left;padding-left:10px;margin-top:5px">
                <el-col :span="24">
                    <el-popconfirm title="审核后不能修改及删除，确认审核？" v-if="!isAddf&&isExamine==0" @confirm="confirmEnquiry()">
                        <el-button size="mini" type="primary" icon="el-icon-s-claim" slot="reference">{{isExamine==1?'已经审核':isExamine==0?'确认审核':'询价完成'}}</el-button>
                    </el-popconfirm>
                    <el-popconfirm title="关闭后将不能恢复，确认关闭？" v-if="isExamine==1" @confirm="closeEnquiry()" style="margin-left:10px">
                        <el-button size="mini" type="danger" icon="el-icon-s-release" slot="reference">关闭询价单</el-button>
                    </el-popconfirm>
                </el-col>
            </el-row>
            <el-row style="margin-top:0px">
                <el-form :inline="true" :model="enquiryDet" class="demo-form-inline">
                    <el-row class="query-row">
                        <el-col :span="4" class="query-col">
                            <el-form-item label="单据编码：" style="margin-left:10px">
                                {{enquiryDet.inquiryCode}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" class="query-col">
                            <el-form-item label="单据日期：">
                                <el-date-picker
                                    v-model="enquiryDet.inquiryDate"
                                    :disabled="isExamine==1||isExamine==2"
                                    size="mini"
                                    align="right"
                                    type="date"
                                    placeholder="选择日期"
                                    :picker-options="pickerOptions"
                                    style="width:125px">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" class="query-col">
                            <el-form-item label="业务员：">
                                <el-select size="mini" v-model="enquiryDet.cPersonCode" filterable :disabled="isExamine==1||isExamine==2" placeholder="请选择" style="width:140px">
                                    <el-option
                                    v-for="item in saleOptions"
                                    :key="item.cPersonCode"
                                    :label="item.cPersonName"
                                    :value="item.cPersonCode">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" class="query-col" style="text-align: center;">
                            <el-form-item label="供应商：">
                                <el-select size="mini" :multiple-limit="3" filterable :disabled="isExamine==1||isExamine==2" v-model="enquiryDet.suppliers"  multiple placeholder="请选择" style="width:260px">
                                    <el-option
                                    v-for="item in supplierOptions"
                                    :key="item.cVenCode"
                                    :label="item.cVenName"
                                    :value="item.cVenCode">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="query-col" style="text-align: center;">
                            <el-form-item label="是否审核：">
                                <el-tag size="mini" :type="isExamine==1?'success':isExamine==0?'danger':''">{{isExamine==1?'已审核':isExamine==0?'未审核':'已完成'}}</el-tag>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="query-col" style="text-align: center;padding-top:5px">
                            <el-button v-if="isExamine==0" size="mini" icon="el-icon-folder-checked" type="primary" @click="saveEnquiry()">{{isAddf==true?'新增询价单':'修改询价单'}}</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </el-row>
        </el-header>
        <!-- 询价单详情内容 -->
        <el-main>
            <el-table
                :data="enquiryData"
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                height="200"
                border
                size="mini"
                @row-dblclick="dbOpenChang($event)"
                style="width: 100%;margin-bottom: 0px;">
                <el-table-column
                type="index"
                min-width="10%">
                </el-table-column>
                <!-- 存货名称因为下拉框绑定值的原因，需要绑定存货编码 -->
                <el-table-column
                prop="cInvName"
                label="存货名称"
                sortable
                show-overflow-tooltip
                min-width="28%">
                    <template slot-scope="scope">
                        <span v-if="scope.row.setState">
                            <el-row>
                                <el-col :span="12">
                                    {{scope.row.cInvName}}
                                </el-col>
                                <el-col :span="12" style="text-align: right;">
                                    <el-button size="mini" type="primary" icon="el-icon-search" circle @click="updInv(scope.row,scope.$index)"></el-button>
                                </el-col>
                            </el-row>
                        </span>
                        <span v-else>{{scope.row.cInvName}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                prop="cInvCode"
                label="存货编码"
                sortable
                show-overflow-tooltip
                min-width="16%">
                </el-table-column>
                <el-table-column
                prop="cInvStd"
                label="规格型号"
                sortable
                show-overflow-tooltip
                min-width="15%">
                </el-table-column>
                <el-table-column
                prop="cComUnitName"
                label="单位"
                sortable
                show-overflow-tooltip
                min-width="13%">
                </el-table-column>
                <el-table-column
                prop="plaQuantity"
                label="计划数量"
                sortable
                min-width="18%">
                    <template slot-scope="scope">
                        <el-input-number size="mini" :disabled="!scope.row.setState" v-model="scope.row.plaQuantity" :min="0" :max="1000000000" label="请输入提成比例" style="width:140px"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column
                prop="demDate"
                label="需求日期"
                sortable
                min-width="18%">
                    <template slot-scope="scope">
                        <el-date-picker
                            v-model="scope.row.demDate"
                            :disabled="!scope.row.setState"
                            size="mini"
                            align="right"
                            type="date"
                            placeholder="选择日期"
                            :picker-options="pickerOptions"
                            style="width:125px">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="25%">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" v-if="isExamine==0" @click="scope.row.setState?addChange(scope.row,scope.$index):upChange(scope.row,scope.$index)" style="margin-right:15px">
                            {{scope.row.setState?'保存':"修改"}}
                        </el-button>
                        <el-popconfirm v-if="isExamine==0" :title="scope.row.setState?'确定要取消？':'确定要删除？'" @confirm="!scope.row.setState?delChange(scope.row,scope.$index):cancelChange(scope.row,scope.$index,true)">
                            <el-button size="mini" :type="!scope.row.setState?'danger':'warning'" slot="reference">
                                {{!scope.row.setState?'删除':"取消"}}
                            </el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <div class="el-table-add-row" v-if="isExamine==0" @click="addCommodity()"><span>+ 添加</span></div>
        </el-main>
        <el-row>
            <el-col :span="24">
                <!-- 步骤条 -->
                <el-steps :active="enquiryDet.enId==''?isExamine:isExamine+1" :align-center="true" space="33.3%" style="margin-top:20px">
                    <el-step title="设置询价信息"></el-step>
                    <el-step title="审核询价单"></el-step>
                    <el-step title="关闭询价单"></el-step>
                </el-steps>
            </el-col>
        </el-row>
        <!-- 存货选择框 -->
        <el-dialog title="存货选择" append-to-body :visible.sync="showInvSelectDet" @close='closeInvSelectDet' width="60%" class="InvSelectForm">
            <InventorySelect v-if="isInvRefresh" @selInventory="selectChange" :isAddInv="isAddInv" :offerType="offerType"></InventorySelect>
            <el-row style="margin-top:20px">
                <el-col :span="24" style="margin-bottom:10px;text-align: center;">
                    <el-button type="danger" icon="el-icon-circle-close" @click="closeInvSelectDet()">关闭</el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </el-container>
</template>

<script>
import InventorySelect from '../inventory/InventorySelectPage'
export default {
    props:["isAddForm","enquiry"],
    created(){
        //调用判断是新增界面还是查看界面的方法
        this.judgeAddOrquery();
        //调用获取业务员下拉列表的方法
        this.getSalePersons();
        //调用获取供应商下拉列表的方法
        this.getVendors();
    },
    data(){
        return{
            //日历快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                    picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', date);
                    }
                }]
            },
            loading:false,//是否显示加载图标
            //选中要查看详情的询价单对象
            enquiryDet:{
                enId:'',//询价单主表标识
                inquiryCode:'',//单据编码
                inquiryDate:'',//日期
                cPersonCode:'',//业务员编码
                suppliers:[],//供应商列表
                isExamine:''//审核状态
            },
            isAddf:true,//弹框是否为新增窗的属性
            enquiryData:[],//询价单详情数据
            isExamine:0,//该询价单是否已经审核
            //业务员下拉列表
            saleOptions:[],
            //供应商下拉列表
            supplierOptions:[],
            // //查询到的存货集合
            // cInvOptions:[],
            oneSet:false,//标识是否有一行数据处在修改状态中
            //修改存货前保存的存货信息
            keepInvInfo:{
                cInvCode:'',//存货编码
                plaQuantity:'',//计划数量
                demDate:''//需求日期
            },
            showInvSelectDet:false,//是否显示存货选择框
            isInvRefresh:true,//是否强制刷新存货选择框的属性
            isAddInv:false,//是否新增存货的属性
            invIndex:'',//修改存货的询价单详情下标（存货）
            offerType:'EM',//报价单类型（询价）
        }
    },
    methods:{
        /**
         * 判断是新增界面还是查看界面
         */
        judgeAddOrquery(){
            this.isAddf=this.isAddForm;//设置是否为新增询价单详情界面
            if(this.isAddf!=true){//如果打开的不是新增窗口
                this.enquiryDet.inquiryCode=this.enquiry.inquiryCode;//设置选中的询价单对象的单据编码
                this.enquiryDet.inquiryDate=this.enquiry.inquiryDate;//设置选中的询价单对象的日期
                this.enquiryDet.cPersonCode=this.enquiry.cPersonCode;//设置选中的询价单对象的业务员名称
                this.enquiryDet.enId=this.enquiry.enId;//询价单主表标识
                this.enquiryDet.isExamine=this.enquiry.isExamine;//询价单审核状态
                this.isExamine=this.enquiry.isExamine;//全局审核状态
                //拼接供应商数组列表
                if(this.enquiry.cVenCode1!=''&&this.enquiry.cVenCode1!=null){//如果供应商1存在
                    this.enquiryDet.suppliers.push(this.enquiry.cVenCode1);
                }
                if(this.enquiry.cVenCode2!=''&&this.enquiry.cVenCode2!=null){//如果供应商2存在
                    this.enquiryDet.suppliers.push(this.enquiry.cVenCode2);
                }
                if(this.enquiry.cVenCode3!=''&&this.enquiry.cVenCode3!=null){//如果供应商3存在
                    this.enquiryDet.suppliers.push(this.enquiry.cVenCode3);
                }
                //根据询价单主表标识查找询价详情
                this.getEnquiryDet();
            }
        },
        /**
         * 根据询价单主表标识查询询价单详情
         */
        getEnquiryDet(){
            this.loading=true;//显示加载页面
            this.enquiryData=[];//查询之前清空之前查询到的询价单详情数据
            let queryEnquiryDetParam={
                enId:this.enquiryDet.enId//询价单主表标识
            }
            this.$axios.post("/u8/enquiry/getEnquiryDetails",queryEnquiryDetParam)
            .then((resp)=>{
                this.enquiryData=resp.data.data;//设置查询到的询价单详情
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('询价单详情服务器出错了');
                    this.$message('询价单详情服务器出错了');
                }
            );
        },
        /**
         * 获取业务员下拉列表的方法
         */
        getSalePersons(){
            this.$axios.post("/u8/enquiry/getSalePersons")
            .then((resp)=>{
                this.saleOptions=resp.data.data;//设置查询到的业务员集合
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('业务员服务器出错了');
                    this.$message('业务员服务器出错了');
                }
            );
        },
        /**
         * 获取供应商下拉列表的方法
         */
        getVendors(){
            this.$axios.post("/u8/enquiry/getVendors")
            .then((resp)=>{
                this.supplierOptions=resp.data.data;//设置查询到的供应商集合
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('供应商服务器出错了');
                    this.$message('供应商服务器出错了');
                }
            );
        },
        /**
         * 添加存货的方法
         */
        addCommodity() {
            if(this.oneSet==true){
                return this.$message('请保存当前编辑项');
            }
            this.isInvRefresh=false;//是否刷新存货选择窗口子组件的属性
            this.$nextTick(() => {
                this.isAddInv=true;//设置为是新增存货
                this.isInvRefresh=true;//强制刷新存货选择页子组件数据
            });
            //打开存货选择框
            this.showInvSelectDet=true;
        },
        /**
         * 保存新增存货的方法
         */
        addChange(row, index){
            if(row.cInvCode==null||row.cInvCode==''){
                return this.$message('请选择存货');
            }
            if(row.plaQuantity==''||row.plaQuantity==0||row.plaQuantity==null){
                return this.$message('请输入计划数量');
            }
            if(row.demDate==null||row.demDate==''){
                return this.$message('请选择需求日期');
            }
            if(this.isAddf!=true){//如果是查询窗口
                if(row.id==''||row.id==null){//如果要修改的行id为空则为新增存货
                    this.toAddInv(row);//调用向后端新增存货的方法
                }else{//如果要修改的行id不为空则为修改存货
                    this.toUpdInv(row);//调用向后端修改存货的方法
                }
            }
            row.setState=false;//关闭修改状态
            this.oneSet=false;//标识有一行数据处在修改状态中为关闭
            //调用清空存货暂存数据的方法
            this.clearInvInfoMethod();
        },
        /**
         * 向后端新增存货的方法
         */
        toAddInv(row){
            let toAddInvParam={
                enId:this.enquiryDet.enId,//询价单主表标识
                cInvCode:row.cInvCode,//存货编码
                cInvStd:row.cInvStd,//规格型号
                cUnitID:row.cUnitID,//计量单位编码
                plaQuantity:row.plaQuantity,//计划数量
                demDate:row.demDate,//需求日期
            }
            this.$axios.post("/u8/enquiry/toAddInv",toAddInvParam)
            .then((resp)=>{
                this.$message(resp.data.msg);
                if(resp.data.success=="true"){
                    row.id=resp.data.data;//设置新增行的id
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('询价单详情服务器出错了');
                    this.$message('询价单详情服务器出错了');
                }
            );
        },
        /**
         * 向后端修改存货的方法
         */
        toUpdInv(row){
            let toUpdInvParam={
                id:row.id,
                enId:this.enquiryDet.enId,//询价单主表标识
                cInvCode:row.cInvCode,//存货编码
                cInvStd:row.cInvStd,//规格型号
                cUnitID:row.cUnitID,//计量单位编码
                plaQuantity:row.plaQuantity,//计划数量
                demDate:row.demDate,//需求日期
            }
            this.$axios.post("/u8/enquiry/toUpdInv",toUpdInvParam)
            .then((resp)=>{
                this.$message(resp.data.msg);
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('询价单详情服务器出错了');
                    this.$message('询价单详情服务器出错了');
                }
            );
        },
        /**
         * 双击行数据打开修改状态
         */
        dbOpenChang(column){
            if(this.enquiryDet.isExamine==1||this.enquiryDet.isExamine==2){//如果该询价单已经被审核或已经完成
                return;//不做任何操作
            }
            if(this.oneSet==true){
                if( column.setState==false){
                    return this.$message('请保存当前编辑项');
                }
            }
            this.oneSet=true;//标识有一行数据处在修改状态中为打开
            column.setState=true;//打开修改状态
            //调用修改存货是把当前存货信息保存起来的方法
            this.keepInvInfoMethod(column);
        },
        /**
         * 打开存货修改状态
         */
        upChange(row, index){
            if(this.oneSet==true){
                return this.$message('请保存当前编辑项');
            }
            this.oneSet=true;//标识有一行数据处在修改状态中为打开
            row.setState=true;//打开修改状态
            //调用修改存货是把当前存货信息保存起来的方法
            this.keepInvInfoMethod(row);
        },
        /**
         * 修改存货前把当前存货信息保存起来的方法
         */
        keepInvInfoMethod(row){
            this.keepInvInfo.cInvCode=row.cInvCode;//存货编码
            this.keepInvInfo.plaQuantity=row.plaQuantity;//计划数量
            this.keepInvInfo.demDate=row.demDate;//需求日期
        },
        /**
         * 清空暂存存货信息的方法
         */
        clearInvInfoMethod(){
            this.keepInvInfo.cInvCode='';//存货编码
            this.keepInvInfo.plaQuantity='';//计划数量
            this.keepInvInfo.demDate='';//需求日期
        },
        /**
         * 恢复存货暂存信息的方法
         */
        recoveryInvInfoMethod(row){
            row.cInvCode=this.keepInvInfo.cInvCode;//存货编码
            row.plaQuantity=this.keepInvInfo.plaQuantity;//计划数量
            row.demDate=this.keepInvInfo.demDate;//需求日期
        },
        /**
         * 取消新增存货的方法
         */
        cancelChange(row, index, cg){
            if(this.keepInvInfo.cInvCode==''&&this.keepInvInfo.plaQuantity==''||this.keepInvInfo.demDate==''){
                //是否是取消操作
                if (cg) {
                    if (!this.sel.id) this.enquiryData.splice(index, 1);
                    this.oneSet=false;//标识有一行数据处在修改状态中为关闭
                    return row.setState = !row.setState;
                }
            }else{
                //调用恢复存货暂存信息的方法
                this.recoveryInvInfoMethod(row);
                row.setState=false;//关闭修改状态
                this.oneSet=false;//标识有一行数据处在修改状态中为关闭
            }
        },
        
        /**
         * 删除存货对象的方法
         */
        delChange(row, index){
            if(this.oneSet==true){
                return this.$message('请保存当前编辑项');
            }
            if(this.isAddf==false){
                if(this.enquiryData.length==1){
                    return this.$message('只剩下一个存货信息，请往上一级删除询价单');
                }
            }
            this.enquiryData.splice(index, 1);
            if(row.id!=''){
                let delEnquiryParam={
                    enId:row.enId,//询价单主表标识
                    cInvCode:row.cInvCode//存货编码
                }
                this.$axios.post("/u8/enquiry/toDelEnquiry",delEnquiryParam)
                .then((resp)=>{
                    this.$message(resp.data.msg)
                })
                .catch(
                    function(error){
                        console.log(error);
                        console.log('询价单详情服务器出错了');
                        this.$message('询价单详情服务器出错了');
                    }
                );
            }
        },
        /**
         * 审核询价单的方法
         */
        confirmEnquiry(){
            if(this.enquiryDet.inquiryDate==null||this.enquiryDet.inquiryDate==''){
                return this.$message('请选择单据日期');
            }
            if(this.enquiryDet.cPersonCode==null||this.enquiryDet.cPersonCode==''){
                return this.$message('请选择业务员');
            }
            if(this.enquiryDet.suppliers.length==0){
                return this.$message('请选择供应商');
            }
            if(this.enquiryData.length==0){
                return this.$message('请添加存货信息');
            }
            if(this.oneSet==true){
                return this.$message('请保存当前编辑项');
            }
            this.$axios.post("/u8/enquiry/toExamine",this.enquiryDet)
            .then((resp)=>{
                this.$message(resp.data.msg);
                if(resp.data.success=="true"){
                    this.$emit('updEnquiry',false)//调用父组件刷新查询询价单主表的方法
                    this.enquiryDet.isExamine=1;//询价单设置为已经审核状态
                    this.isExamine=1;//设置全局状态为已审核
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('询价单服务器出错了');
                    this.$message('询价单服务器出错了');
                }
            );
        },
        /**
         * 保存询价单的方法
         */
        saveEnquiry(){
            if(this.enquiryDet.inquiryDate==null||this.enquiryDet.inquiryDate==''){
                return this.$message('请选择单据日期');
            }
            if(this.enquiryDet.cPersonCode==null||this.enquiryDet.cPersonCode==''){
                return this.$message('请选择业务员');
            }
            if(this.enquiryDet.suppliers.length==0){
                return this.$message('请选择供应商');
            }
            if(this.enquiryData.length==0){
                return this.$message('请添加存货信息');
            }
            if(this.oneSet==true){
                return this.$message('请保存当前编辑项');
            }
            //新增或修改询价单
            if(this.isAddf==true){//如果是添加询价单
                let addEnquiryAndDet={
                    enquiry:this.enquiryDet,//询价单主表
                    enquiryDet:this.enquiryData//询价单子表（详情）
                }
                this.$axios.post("/u8/enquiry/addEnquiryAndDet",addEnquiryAndDet)
                .then((resp)=>{
                    this.$message(resp.data.msg);
                    if(resp.data.success=="true"){
                        this.enquiryDet.enId=resp.data.data.enId;//询价单主表标识
                        this.enquiryDet.inquiryCode=resp.data.data.inquiryCode;//询价单主表单据编码
                        this.enquiryDet.isExamine=resp.data.data.isExamine;//审核状态
                        //保存询价单后，如果是新增窗口，则变为了查看窗口
                        this.isAddf=false;
                        //调用根据询价单主表标识查询询价单详情的方法
                        this.getEnquiryDet();
                        this.$emit('updEnquiry',false);//调用父组件刷新查询询价单主表的方法
                    }
                })
                .catch(
                    function(error){
                        console.log(error);
                        console.log('询价单服务器出错了');
                        this.$message('询价单服务器出错了');
                    }
                );
            }else{
                this.$axios.post("/u8/enquiry/toUpdEnquiry",this.enquiryDet)
                .then((resp)=>{
                    this.$message(resp.data.msg);
                    if(resp.data.success=="true"){
                        this.$emit('updEnquiry',false)//调用父组件刷新查询询价单主表的方法
                    }
                })
                .catch(
                    function(error){
                        console.log(error);
                        console.log('询价单服务器出错了');
                        this.$message('询价单服务器出错了');
                    }
                );
            }
        },
        /**
         * 关闭询价单的方法
         */
        closeEnquiry(){
            if(this.isExamine!=1){
                return this.$message("该询价单还没有审核");
            }
            this.$axios.post("/u8/enquiry/toCloseEnquiry",this.enquiryDet)
            .then((resp)=>{
                this.$message(resp.data.msg);
                if(resp.data.success=="true"){
                    this.$emit('updEnquiry',false);//调用父组件刷新查询询价单主表的方法
                    this.enquiryDet.isExamine=2;//设置为已经完成状态
                    this.isExamine=2;//设置全局状态为已经完成
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('询价单服务器出错了');
                    this.$message('询价单服务器出错了');
                }
            );
        },
        /**
         * 关闭存货选择框的方法
         */
        closeInvSelectDet(){
            //关闭存货选择框
            this.showInvSelectDet=false;
        },
        /**
         * 子组件传回选中要询价的存货方法
         */
        selectChange(data){
            let row=data.row;//选中的存货信息
            this.isAddInv=data.isAddInv;//是新增存货还是修改存货
            //关闭存货选择框
            this.showInvSelectDet=false;
            this.oneSet=true;//标识有一行数据处在修改状态中为打开
            if(this.isAddInv){//如果是新增存货
                //设置新增存货查询一行的模板参数
                let j = { id: "",cInvName:row.cInvName,cInvCode:row.cInvCode, cInvStd: row.cInvStd, cComUnitName: row.cComUnitName,plaQuantity: "",demDate: "", setState: true };
                this.enquiryData.push(j);//追加到原本的询价单详情
                this.sel = JSON.parse(JSON.stringify(j));
                //恢复不是为新增存货
                this.isAddInv=false;
            }else{//如果是修改存货
                //设置新增存货查询一行的模板参数
                this.enquiryData[this.invIndex].cInvName=row.cInvName;//存货名称
                this.enquiryData[this.invIndex].cInvCode=row.cInvCode;//存货编码
                this.enquiryData[this.invIndex].cInvStd=row.cInvStd;//规格型号
                this.enquiryData[this.invIndex].cComUnitName=row.cComUnitName;//单位
                this.invIndex='';//清空要修改询价单详情存货的下标
            }
        },
        /**
         * 打开修改存货的方法
         */
        updInv(row,index){
            this.isInvRefresh=false;//是否刷新存货选择窗口子组件的属性
            this.$nextTick(() => {
                this.isAddInv=false;//设置为是修改存货
                this.isInvRefresh=true;//强制刷新存货选择页子组件数据
            });
            //打开存货选择框
            this.showInvSelectDet=true;
            this.invIndex=index;//设置要修改询价单详情存货的下标
        }
    },
    components:{
        //引入存货选择框
        InventorySelect:InventorySelect,
    }
}
</script>

<style>
    /* 添加按钮框样式 */
    .el-table-add-row{
        background-color: rgb(64,158,255);
        color: white;
        border-radius:5px;
        cursor:pointer;
        margin-top: 5px;
        height: 30px;
        line-height: 30px;
        border:1px dashed #000;
    }

    /* 存货选择框样式 */
    .InvSelectForm{
        margin-top: 50px;
    }

    /* 步骤条样式 */
    .step {
        width: 1200px;
        margin: 44px auto 0;
    }
    .el-step__icon.is-icon {
        width: 10px;
        height: 10px;
    }
    .el-step__title.is-process {
        font-weight: 400;
        color: #303133;
    }
    .el-step__head.is-finish {
        color: #fca404;
        border-color: #fca404;
    }
    .el-step__title.is-finish {
        color: #fca404;
    }
    .el-step__description.is-finish {
        color: #fca404;
        height: 60px;
    }
    .el-step__head.is-finish {
        color: #fca404;
    }
    .el-step.is-horizontal .el-step__line {
        height: 2px;
        top: 10px;
    }
    .el-step{
        height: 70px !important;
    }
</style>