<template>
    <el-container>
        <el-header>
            <h1>U8供应商协同门户</h1>
        </el-header>
        <el-main class="bodyFrom">
            <el-main class="loginFrom">
                <el-row><p>请登录</p></el-row>
                <el-row>
                    <el-col :span="2"><div class="grid-content"></div></el-col>
                    <el-col :span="20">
                        <el-form :model="user" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                            <el-form-item label="用户名" prop="name">
                                <el-input v-model="user.name"></el-input>
                            </el-form-item>
                            <el-form-item label="密码" prop="pass">
                                <el-input type="password" v-model="user.pass" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" :icon="loadingIcon" @click="submitForm('ruleForm')">{{loadingBtnName}}</el-button>
                                <el-button @click="resetForm('ruleForm')">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="2"><div class="grid-content"></div></el-col>
                </el-row>
            </el-main>
        </el-main>
    </el-container>
</template>

<script>
//从vuex中导入mapMutations，可以以集合的形式引入仓库中的修改器
import { mapMutations } from 'vuex';
export default {
    name: 'Login',
    data() {
        return {
            user: {
                pass: '',
                name: ''
            },
            rules: {
                pass: [
                    { required: true,message: '请输入密码', trigger: 'blur' },
                ],
                name: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                ],
            },
            loadingIcon:'',//点击了登录后按钮显示的图标
            loadingBtnName:'登录',//登录按钮名称
        };
    },
    created(){
        //调用监听按下键盘的方法
        this.keyupSubmit();
    },
    methods: {
        /**
         * 监听按下键盘的方法
         */
        keyupSubmit(){
            //键盘监听事件
            document.onkeydown=e=>{
                let _key=window.event.keyCode;
                if(_key===13){//如果监听到按下了回车键
                    this.submitForm('ruleForm');
                }
            }
        },
        //使用...mapMutatioins以集合方式获取store仓库中的修改器
        //changeLogin是store仓库中修改器的添加用户token的方法
        ...mapMutations(['changeLogin']),
        submitForm(formName) {
            console.log('直接登录');
            //以下为写死数据-----------------------测试完毕后删除
            //写死登录数据
            // let loginuser={
            //     id:1,
            //     cUserId:'000001',
            //     cUserName:'测试1',
            //     iAdmin:1,
            //     iPrice:1,
            //     cVenCode:'gy001',
            //     cVenName:'供应商1',
            // }
            // //将用户token保存到vuex中
            // this.changeLogin({ UserToken: loginuser });
            // //跳转到index页面，并把登录对象传递过去（直接跳转）
            // this.$router.push({name:'index'});
            // return true;
            //以上为写死数据-----------------------测试完毕后删除


            this.$refs[formName].validate((valid) => {
                if (valid) {//如果登录框有读取到数据
                    this.loadingIcon='el-icon-loading';//设置点击了登录后按钮显示的图标
                    this.loadingBtnName='登录中';//设置登录按钮为登录中
                    this.$axios.post("/u8/login/tologin",this.user)
                    .then((resp)=>{
                        let re=resp.data.success;
                        if(re=='true'){//如果登录成功
                            //获取登录对象
                            let loginuser=resp.data.data;//设置获取到的登录对象
                            //验证授权
                            this.checkEmpower(loginuser);
                        }else{//如果登录失败
                            console.log('登录失败');
                            this.$message(resp.data.msg);
                            this.loadingIcon='';//设置点击了登录后按钮显示的图标
                            this.loadingBtnName='登录';//设置登录按钮为登录中
                            return false;
                        }
                    })
                    .catch(function(error){
                        console.log(error);
                    });
                    
                } else {
                    console.log('参数为空');
                    return false;
                }
            });
        },
        /**
         * 验证授权的方法
         * @param loginuser 登录对象
         */
        checkEmpower(loginuser){
            let that=this;
            this.loadingBtnName='授权验证中';//设置登录按钮为登录中
            this.$axios.get("/toU8del/portal/U8key.ashx")
            .then((resp)=>{
                let msg=resp.data.split('||');//根据||切割字符串为数组
                let isPass=msg[1].split('=');//根据=切割授权结果为数组
                if(isPass[1]=="true"){//如果授权成功
                    //将用户token保存到vuex中
                    that.changeLogin({ UserToken: loginuser });
                    this.loadingIcon='';//清空登录按钮的图标
                    this.loadingBtnName='登录';//设置登录按钮为登录
                    //跳转到index页面，并把登录对象传递过去
                    this.$router.push({name:'index'});
                    return true;
                }
                if(isPass[1]=="false"){//如果授权失败
                    this.loadingIcon='';//设置点击了登录后按钮显示的图标
                    this.loadingBtnName='登录';//设置登录按钮为登录
                    let resMsg=msg[0].split(":");//根据:切割授权返回信息
                    this.$message(resMsg[1]);
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('验证授权服务器出错了');
                    that.$message.error('验证授权服务器出错了');
                }
            );
        },
        /**
         * 重置登录账号和密码
         */
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    },
    watch: {
        '$route' (to, from) {
            this.$router.go(0);
        }
    },
    /**
     * 实例销毁之前调用
     */
    beforeDestroy() {
        //取消键盘监听
        document.onkeydown = ''
    },
}
</script>
    
<style>
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    /* 登录内容框 */
    .bodyFrom{
        text-align: center;
    }
    /* 登录框样式 */
    .loginFrom{
        margin:0 auto;
        width:500px;
        border: 1px solid black;
    }
</style>