<template>
    <!-- 送货草稿详情 -->
    <el-container>
        <!-- 表头 -->
        <el-header class="table-header" style="height:100px">
            <el-row style="text-align: left;padding-left:22px;margin-top:5px">
                <el-col :span="24">
                    <el-popconfirm title="发货后不能修改及删除，确认发货？" @confirm="confirmDelivery()">
                        <el-button size="mini" type="primary" v-if="venUser.iAdmin!=1" icon="el-icon-truck" slot="reference">确认发货</el-button>
                    </el-popconfirm>
                </el-col>
            </el-row>
            <el-row style="margin-top:0px">
                <el-col :span="8" style="padding-left:22px">
                    <p style="text-align: left;height: 18px;line-height: 18px;">送货草稿号：{{draftDet.cCode}}</p>
                    <p style="text-align: left;height: 18px;line-height: 18px;">快递单号：{{draftDet.cDefine14}}</p>
                </el-col>
                <el-col :span="8" style="padding-left:22px">
                    <p style="text-align: left;height: 18px;line-height: 18px;">来源单类型：{{draftDet.cSourceType}}</p>
                    <p style="text-align: left;height: 18px;line-height: 18px;">送货备注：{{draftDet.txtcMemo}}</p>
                </el-col>
                <el-col :span="8" style="padding-left:22px">
                    <p style="text-align: left;height: 18px;line-height: 18px;">供应商：{{draftDet.cVenName}}</p>
                    <p style="text-align: left;height: 18px;line-height: 18px;">送货日期：{{draftDet.deliverTime}}</p>
                </el-col>
            </el-row>
        </el-header>
        <el-main class="table-body">
            <!-- 送货草稿详情 -->
            <el-main class="draft-order">
                <el-table
                    ref="singleTable"
                    :data="draftData"
                    v-loading="loading"
                    element-loading-text="正在加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                    :height="tableHeight"
                    border
                    size="mini"
                    style="width: 100%"
                    @row-dblclick="dbOpenChang($event)"
                    row-key="aId"
                    default-expand-all
                    :tree-props="{children: 'giveChildren', hasChildren: 'hasChildren'}">
                    <el-table-column
                    type="index"
                    fixed
                    show-overflow-tooltip
                    width="40px">
                    </el-table-column>
                    <!-- <el-table-column
                    property="cCode"
                    label="送货草稿号"
                    fixed
                    show-overflow-tooltip
                    width="100px">
                    </el-table-column> -->
                    <el-table-column
                    property="cSourceCode"
                    show-overflow-tooltip
                    fixed
                    sortable
                    :label="draftDet.cSourceType=='采购订单'?'采购单号':'委外单号'"
                    width="100px">
                    </el-table-column>
                    <el-table-column
                    property="dPODate"
                    label="单据日期"
                    fixed
                    sortable
                    show-overflow-tooltip
                    width="100px">
                    </el-table-column>
                    <el-table-column
                    property="cInvCode"
                    label="存货编码"
                    fixed
                    sortable
                    show-overflow-tooltip
                    width="100px">
                    </el-table-column>
                    <el-table-column
                    property="cInvName"
                    label="存货名称"
                    fixed
                    sortable
                    show-overflow-tooltip
                    width="100px">
                    </el-table-column>
                    <el-table-column
                    property="cInvStd"
                    show-overflow-tooltip
                    label="规格型号"
                    sortable
                    width="110px">
                    </el-table-column>
                    <el-table-column
                    property="iQuantity"
                    show-overflow-tooltip
                    label="订单数量"
                    sortable
                    width="94px">
                    </el-table-column>
                    <el-table-column
                    property="isQtyTotal"
                    show-overflow-tooltip
                    label="已发数量"
                    sortable
                    width="94px">
                        <template slot-scope="scope" v-if="scope.row.bGiveaway==false">
                            <span>{{scope.row.isQtyTotal}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    property="cComUnitName"
                    show-overflow-tooltip
                    label="单位"
                    sortable
                    width="70px">
                    </el-table-column>
                    <el-table-column
                    prop="dArriveDate"
                    label="期望交期"
                    sortable
                    show-overflow-tooltip
                    width="95px">
                    </el-table-column>
                    <el-table-column
                    property="thisiQty"
                    label="本次数量"
                    sortable
                    width="180px">
                        <template slot-scope="scope">
                            <span v-if="scope.row.setState&&scope.row.bGiveaway==false">
                                <el-input-number size="mini" :disabled="!scope.row.setState" v-model="scope.row.thisiQty" @change="listenNum(scope.row)" controls-position="right" :min="0" :max="(scope.row.iQuantity-scope.row.isQtyTotal)+originalVal.thisiQty" style="width:150px"></el-input-number>
                            </span>
                            <span v-else style="text-align: center;display:block;">{{scope.row.thisiQty}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="bGiveaway"
                        label="赠品"
                        sortable
                        show-overflow-tooltip
                        width="95px">
                        <template slot-scope="scope">
                            <el-tag size="mini" :type="scope.row.bGiveaway==true?'':'success'">{{scope.row.bGiveaway==true?'是':'否'}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                    property="cBatch"
                    show-overflow-tooltip
                    label="批号"
                    sortable
                    width="115px">
                        <template slot-scope="scope">
                            <span v-if="scope.row.setState">
                                <el-input size="mini" :disabled="scope.row.bInvBatch==0?true:false" clearable v-model="scope.row.cBatch" style="width:90px" placeholder="批号"></el-input>
                            </span>
                            <span v-else>{{scope.row.cBatch}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    property="dPDate"
                    label="生产日期"
                    sortable
                    width="150px">
                        <template slot-scope="scope">
                            <el-date-picker
                                v-model="scope.row.dPDate"
                                :disabled="scope.row.setState==false?true:scope.row.bInvQuality==0?true:false"
                                size="mini"
                                align="right"
                                type="date"
                                placeholder="选择日期"
                                style="width:125px"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </template>
                    </el-table-column>
                    <el-table-column
                    property="cbMemo"
                    show-overflow-tooltip
                    label="备注"
                    width="115px">
                        <template slot-scope="scope">
                            <span v-if="scope.row.setState">
                                <el-input size="mini" :disabled="!scope.row.setState" clearable v-model="scope.row.cbMemo" style="width:90px" placeholder="备注" ></el-input>
                            </span>
                            <span v-else>{{scope.row.cbMemo}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150px">
                        <template slot-scope="scope">
                            <el-button v-if="venUser.iAdmin!=1" type="primary" size="mini" @click="!scope.row.setState==true?edit(scope.row,scope.$index):updChange(scope.row,scope.$index)" style="margin-right:10px">
                                {{!scope.row.setState?'编辑':"修改"}}
                            </el-button>
                            <template v-if="scope.row.bGiveaway==false">
                                <el-popconfirm v-if="venUser.iAdmin!=1" :title="scope.row.setState==true?'确定要取消？':'确定要删除？'" @confirm="!scope.row.setState==true?delChange(scope.row,scope.$index):cancelChange(scope.row,scope.$index)">
                                    <el-button size="mini" :type="!scope.row.setState==true?'danger':'warning'" slot="reference">
                                        {{!scope.row.setState?'删除':"取消"}}
                                    </el-button>
                                </el-popconfirm>
                            </template>
                            <template v-else>
                                <el-popconfirm v-if="venUser.iAdmin!=1&&scope.row.setState==true" title="确定要取消" @confirm="cancelChange(scope.row,scope.$index)">
                                    <el-button size="mini" type="warning" slot="reference">
                                        取消
                                    </el-button>
                                </el-popconfirm>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </el-main>
        </el-main>
    </el-container>
</template>

<script>
export default {
    props:["draft"],
    created(){
        this.draftDet=this.draft;//设置要查询的送货草稿对象
        console.log('打开的是：',this.draftDet);
        // if (this.draft.cSourceType=="PO") {
        //     this.draftDet.cSourceType="采购订单"
        // }else{
        //     this.draftDet.cSourceType="委外订单"
        // }
        //根据采购送货单主表标识查找送货记录详情
        this.getDraftDet();
        //调用设置查询中供应商的参数
        this.setVenUser();
    },
    data(){
        return{
            //日历快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                    picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', date);
                    }
                }]
            },
            loading:false,//是否显示加载图标
            tableHeight:window.innerHeight - 445,//数据展示区域自适应高度
            draftDet:{},//要查询的送货草稿对象
            draftData:[],//送货草稿详情数据
            oneSet:false,//标识是否有一行数据处在修改状态中
            originalVal:{//修改数据时保存的原始值
                thisiQty:'',//本次数量
                cBatch:'',//批号
                dPDate:'',//生产日期
                cDefine37:'',//确认交期
                cbMemo:'',//备注
            },
            venUser:{},//登录的供应商对象
            fullscreenLoading: false,//是否显示加载页面
        }
    },
    methods:{
        /**
         * 设置查询中供应商的参数
         */
        setVenUser(){
            //获取上级父组件传进来的参数并赋值
            this.venUser=this.$store.state.UserToken;//路由中获取到的是json字符串，要转化为json对象
        },
        /**
         * 根据送货草稿主表标识和送货草稿号查询送货草稿详情
         */
        getDraftDet(){
            this.loading=true;//显示加载页面
            this.draftData=[];//查询之前清空之前查询到的送货草稿详情数据
            let queryDraftDetParam={
                arrID:this.draftDet.arrID,//采购送货草稿主表标识
                cCode:this.draftDet.cCode,//送货草稿号
                cSourceCode:this.draftDet.cSourceCode//采购(委外)单号
            }
            this.$axios.post("/u8/draft/getDraftDetails",queryDraftDetParam)
            .then((resp)=>{
                this.draftData=resp.data.data;//设置查询到的送货草稿详情集合
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('送货草稿详情服务器出错了');
                    this.$message('送货草稿详情服务器出错了');
                }
            );
        },
        /**
         * 保存原始值的方法
         */
        keepVal(row){
            this.originalVal.thisiQty=row.thisiQty;//本次数量
            this.originalVal.cBatch=row.cBatch;//批号
            this.originalVal.dPDate=row.dPDate;//生产日期
            this.originalVal.cDefine37=row.cDefine37;//确认交期
            this.originalVal.cbMemo=row.cbMemo;//备注
        },
        /**
         * 恢复原始值的方法
         */
        recoveryVal(row){
            row.thisiQty=this.originalVal.thisiQty;//本次数量
            row.cBatch=this.originalVal.cBatch;//批号
            row.dPDate=this.originalVal.dPDate;//生产日期
            row.cDefine37=this.originalVal.cDefine37;//确认交期
            row.cbMemo=this.originalVal.cbMemo;//备注
        },
        /**
         * 清空保存原始值的属性
         */
        clearKeepVal(){
            this.originalVal.thisiQty='';//本次数量
            this.originalVal.cBatch='';//批号
            this.originalVal.dPDate='';//生产日期
            this.originalVal.cDefine37='';//确认交期
            this.originalVal.cbMemo='';//备注
        },
        /**
         * 双击行数据打开修改状态
         */
        dbOpenChang(column){
            if(this.oneSet==true){
                if(column.setState==false){
                    return this.$message("请先保存当前修改项");
                }
            }else{
                column.setState=true;//打开当前行为修改状态
                this.oneSet=true;//标识已经有一行数据打开了修改状态
                //调用保存行原始值的方法
                this.keepVal(column);
            }
        },
        /**
         * 打开编辑条码的方法
         */
        edit(row){
            console.log('编辑的是：',row);
            if(this.oneSet==true){
                this.$message("请先保存当前修改项");
            }else{
                row.setState=true;//打开当前行为修改状态
                this.oneSet=true;//标识已经有一行数据打开了修改状态
                //调用保存行原始值的方法
                this.keepVal(row);
            }
        },
        /**
         * 取消修改的方法
         */
        cancelChange(row){
            //调用恢复原始值的方法
            this.recoveryVal(row);
            //调用清空保存原始值属性的方法
            this.clearKeepVal();
            row.setState=false;//关闭行的修改状态
            this.oneSet=false;//已有一行数据打开了修改状态的标识设置为关闭
        },
        /**
         * 修改行数据的方法
         */
        updChange(row){
            if(row.thisiQty==0||row.thisiQty==null||row.thisiQty==undefined){
                return this.$message('请输入本次数量');
            }
            let that=this;
            this.$axios.post("/u8/draft/updDraft",row)
            .then((resp)=>{
                row.setState=false;//关闭行的修改状态
                this.oneSet=false;//已有一行数据打开了修改状态的标识设置为关闭
                if(resp.data.success=="true"){
                    this.$message({
                        showClose:true,
                        type:'success',
                        message:resp.data.msg
                    });
                    //调用修改赠品行数据的方法（融贤专版）
                    this.updGiveaway(row);
                    //重新刷新获取送货草稿详情
                    // this.getDraftDet();
                }else{
                    this.$message({
                        showClose:true,
                        type:'warning',
                        message:resp.data.msg
                    });
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('更新送货草稿详情服务器出错了');
                    that.$message.error('更新送货草稿详情服务器出错了');
                }
            );
        },
        /**
         * 修改赠品行数据的方法（融贤专版）
         * @param row 修改的正常发货行
         */
        updGiveaway(row){
            for (let i = 0; i < this.draftData.length; i++) {
                //如果找到了当前要修改行对应的赠品行
                if((row.cInvCode==this.draftData[i].cInvCode)&&(this.draftData[i].bGiveaway==true)){
                    //获取要修改的赠品行
                    let updGiveawayRoW=this.draftData[i];
                    let that=this;
                    //修改赠品行数据
                    this.$axios.post("/u8/draft/updDraft",updGiveawayRoW)
                    .then((resp)=>{
                        if(resp.data.success=="true"){
                            //重新刷新获取送货草稿详情
                            this.getDraftDet();
                        }else{
                            this.$message({
                                showClose:true,
                                type:'warning',
                                message:'修改赠品失败'
                            });
                        }
                    })
                    .catch(
                        function(error){
                            console.log(error);
                            console.log('修改赠品行服务器出错了');
                            that.$message.error('修改赠品行服务器出错了');
                        }
                    );
                }
                
            }
        },
        /**
         * 删除发货草稿详情的方法
         */
        delChange(row){
            let draftDataTotal=0;//声明剩余不是赠品的发货草稿数量
            for (let i = 0; i < this.draftData.length; i++) {
                if ( this.draftData[i].bGiveaway==false) {//如果不是赠品
                    draftDataTotal=draftDataTotal+1;
                }
            }
            if (draftDataTotal==1) {//如果发货草稿详情明细剩下一个不是赠品的
                return this.$notify({
                    title: '提示',
                    message: '该送货草稿详情只剩一个存货记录，请往上一级把整个发货草稿删除',
                });
            }
            // if(this.draftData.length==1){
            //     return this.$notify({
            //                 title: '提示',
            //                 message: '该送货草稿详情只剩一个存货记录，请往上一级把整个发货草稿删除',
            //             });
            // }
            if(this.oneSet==true){
                this.$message('请先保存当前修改的存货');
            }else{
                let that=this;
                this.$axios.post("/u8/draft/delDraft",row)
                .then((resp)=>{
                    if(resp.data.success="true"){
                        this.$message({
                            showClose:true,
                            type:'success',
                            message:resp.data.msg
                        });
                        //调用检查删除本行发货草稿后是否存在其他相同存货数据
                        this.checkGiveaway(row);
                        // //重新读取送货草稿详情
                        // this.getDraftDet();
                    }else{
                        this.$message({
                            showClose:true,
                            type:'warning',
                            message:resp.data.msg
                        });
                    }
                })
                .catch(
                    function(error){
                        console.log(error);
                        console.log('删除送货草稿详情服务器出错了');
                        that.$message.error('删除送货草稿详情服务器出错了');
                    }
                );
            }
        },
        /**
         * 检查删除本行发货草稿后是否存在其他相同存货数据
         * @param row 要删除的发货草稿明细
         */
        checkGiveaway(row){
            let isExist=false;//标识删除后不存在相同的存货
            for (let i = 0; i < this.draftData.length; i++) {
                //如果找到了相同的存货，并且送货草稿子表id不一样，且不是赠品行
                if ((row.cInvCode==this.draftData[i].cInvCode)
                    &&(row.id!=this.draftData[i].id)
                    &&(this.draftData[i].bGiveaway==false)) {
                    isExist=true;//标识删除后还有相同存货
                }
            }
            //如果删除后不存在相同的存货
            if (isExist==false) {
                for (let i = 0; i < this.draftData.length; i++) {
                    //如果找到了删除送货草稿明细对应的赠品行
                    if ((row.cInvCode==this.draftData[i].cInvCode)&&(this.draftData[i].bGiveaway==true)) {
                        let that=this;
                        let delGiveaway=this.draftData[i];//获取要删除的赠品行
                        //删除对应的赠品行数据
                        this.$axios.post("/u8/draft/delDraft",delGiveaway)
                        .then((resp)=>{
                            this.$message(resp.data.msg);
                            if(resp.data.success="true"){
                                //重新读取送货草稿详情
                                this.getDraftDet();
                            }else{
                                this.$message({
                                    showClose:true,
                                    type:'warning',
                                    message:'删除赠品行失败'
                                });
                            }
                        })
                        .catch(
                            function(error){
                                console.log(error);
                                console.log('删除赠品行服务器出错了');
                                that.$message.error('删除赠品行服务器出错了');
                            }
                        );
                    }
                }
            }else{//如果删除后还存在相同的存货
                let thisiQtyTotal=0;//声明发货数量改变相同存货的发货总数量
                for (let i = 0; i < this.draftData.length; i++) {
                    //如果找到了发货数量变化对应的不是赠品的发货详情
                    if((row.cInvCode==this.draftData[i].cInvCode)&&(this.draftData[i].bGiveaway==false)&&(row.id!=this.draftData[i].id)){
                        //累加相同存货发货详情的本次发货数量
                        thisiQtyTotal=thisiQtyTotal+this.draftData[i].thisiQty;
                    }
                }
                for (let i = 0; i < this.draftData.length; i++) {
                    //如果找到了发货数量变化对应的赠品存货
                    if((row.cInvCode==this.draftData[i].cInvCode)&&(this.draftData[i].bGiveaway==true)){
                        //设置存货赠品数量为计算出的相同存货本次发货总数乘以赠品比例（向上取整）
                        this.draftData[i].thisiQty=Math.ceil(thisiQtyTotal*row.giveawayPro);
                        let updGiveawayRow=this.draftData[i];//获取要修改的赠品行
                        let that=this;
                        //修改赠品行数据
                        this.$axios.post("/u8/draft/updDraft",updGiveawayRow)
                        .then((resp)=>{
                            if(resp.data.success=="true"){
                                //重新刷新获取送货草稿详情
                                this.getDraftDet();
                            }else{
                                this.$message({
                                    showClose:true,
                                    type:'warning',
                                    message:'修改赠品失败'
                                });
                            }
                        })
                        .catch(
                            function(error){
                                console.log(error);
                                console.log('修改赠品行服务器出错了');
                                that.$message.error('修改赠品行服务器出错了');
                            }
                        );
                    }
                }
            }
        },
        /**
         * 确认发货的方法
         */
        confirmDelivery(){
            //检验是否有发货数量或批号或生产日期没填写
            let check=this.checkBatchAndDate();
            let than=this;
            if(check==true){
                //打开加载页面
                const loading = this.$loading({
                    lock: true,
                    text: '生成到货单中，请稍后....',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$axios.get("/toU8del/portal/U8co.ashx",{params:{arrid:this.draftDet.arrID}})
                .then((resp)=>{
                    let msg=resp.data.split('||');//根据||切割字符串为数组
                    let arrVouchId=parseInt(msg[1].slice(3));//返回的到货单主表id
                    console.log('返回信息是：',msg[0]);
                    if(arrVouchId!=0){
                        //关闭加载页面
                        loading.close();
                        //调用发货成功删除送货草稿的方法
                        this.toDelArrival(this.draftDet,arrVouchId);
                    }
                    if(arrVouchId==0){
                        this.$message.error('确认发货失败，错误信息是：',msg[0]);
                        //关闭加载页面
                        loading.close();
                    }
                })
                .catch(
                    function(error){
                        console.log(error);
                        console.log('U8新建送货记录服务器出错了');
                        than.$message('U8新建送货记录服务器出错了');
                    }
                );
            }else{
                let that=this;
                for (let i = 0; i < check.length; i++) {
                    setTimeout(function(){
                        that.$notify({
                            title: '注意',
                            message: check[i],
                            position: 'top-left',
                            type: 'warning'
                        });
                    },500);
                }
            }
        },
        //检验是否有发货数量或批号或者生产日期没有填写
        checkBatchAndDate(){
            let errStr=[];//保存报错信息的数组
            for (let i = 0; i < this.draftData.length; i++) {
                if(this.draftData[i].thisiQty==0||this.draftData[i].thisiQty==''||this.draftData[i].thisiQty==null){
                    errStr.push('存货编码为：'+this.draftData[i].cInvCode+'；序号为：'+(i+1)+'的存货送货数量不能为0');
                }
                //如果要设置批号
                if(this.draftData[i].bInvBatch==1&&this.draftData[i].bGiveaway==false){
                    if(this.draftData[i].cBatch==''||this.draftData[i].cBatch==null){//如果没有填写批号
                        //要返回的内容
                        let restStr="";
                        restStr=restStr+"存货编码为："+this.draftData[i].cInvCode+"；\n序号为："+(i+1)+"的存货需要设置批号";
                        //如果除了要设置批号之外还要设置生产日期
                        if(this.draftData[i].bInvQuality==1&&(this.draftData[i].dPDate==''||this.draftData[i].dPDate==null)){
                            restStr=restStr+"和生产日期";
                        }
                        errStr.push(restStr);
                    }else if(this.draftData[i].cBatch!=''&&this.draftData[i].cBatch!=null){//如果已经填写了批号
                        //如果除了要设置批号之外还要设置生产日期
                        if(this.draftData[i].bInvQuality==1&&(this.draftData[i].dPDate==''||this.draftData[i].dPDate==null)){
                            errStr.push("存货编码为："+this.draftData[i].cInvCode+"；\n序号为："+(i+1)+"的存货需要设置生产日期");
                        }
                    }
                }
                //如果不用设置批号
                if(this.draftData[i].bInvBatch==0){
                    //如果要设置生产日期
                    if(this.draftData[i].bInvQuality==1&&(this.draftData[i].dPDate==''||this.draftData[i].dPDate==null)){
                        errStr.push('存货编码为：'+this.draftData[i].cInvCode+'；\n序号为：'+(i+1)+'的存货需要设置生产日期');
                    }
                }
            }
            if(errStr.length>0){
                return errStr;
            }
            return true;
        },
        /**
         * 发货成功删除送货草稿的方法
         */
        toDelArrival(draftDet,arrVouchId){
            let delArrivalParam={
                arrID:draftDet.arrID,//采购送货草稿主表标识
                cCode:draftDet.cCode//送货草稿号
            }
            this.$axios.post("/u8/draft/toDelArrival",delArrivalParam)
            .then((resp)=>{
                this.$message(resp.data.msg);
                let retDate={
                    showDraftDet:false,//关闭送货草稿详情窗口
                    arrVouchId:arrVouchId//新增的到货单主表id
                }
                if(resp.data.success=="true"){
                    this.$emit('showDetails',retDate)//向父组件传值关闭送货草稿详情窗口
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('删除送货草稿详情服务器出错了');
                    this.$message('删除送货草稿详情服务器出错了');
                }
            )
        },
        /**
         * 监听数量变化
         * @param row 改变发货数量的行数据
         */
        listenNum(row){
            let thisiQtyTotal=0;//声明发货数量改变相同存货的发货总数量
            for (let i = 0; i < this.draftData.length; i++) {
                //如果找到了发货数量变化对应的不是赠品的发货详情
                if((row.cInvCode==this.draftData[i].cInvCode)&&(this.draftData[i].bGiveaway==false)){
                    //累加相同存货发货详情的本次发货数量
                    thisiQtyTotal=thisiQtyTotal+this.draftData[i].thisiQty;
                }
            }
            for (let i = 0; i < this.draftData.length; i++) {
                //如果找到了发货数量变化对应的赠品存货
                if((row.cInvCode==this.draftData[i].cInvCode)&&(this.draftData[i].bGiveaway==true)){
                    //设置存货赠品数量为计算出的相同存货本次发货总数乘以赠品比例（向上取整）
                    this.draftData[i].thisiQty=Math.ceil(thisiQtyTotal*row.giveawayPro);
                }
            }
        }
    }

}
</script>

<style>
    /* 页面外框整体样式 */
    .table-parent{
        height: 100%;
    }

    /* 窗口内框的样式 */
    .el-dialog__body{
        padding:0px 20px;
    }

    /* 表头样式 */
    .table-header{
        height: 25%;
        padding:0px;
        /* background-color: blue; */
    }

    /* 表体样式 */
    .table-body{
        height: 75%;
        margin: 5px;
        padding: 0px;
        /* background-color: yellow; */
    }

    /* 送货草稿详情 */
    .draft-order{
        padding: 10px 20px;
        /* background-color: pink; */
    }

    /* 解决标题和内容单元格错位 */
    body .el-table th.gutter {
        display: table-cell!important;
    }
    
    /* 解决表格固定列后覆盖滚动条 */
    /* 解决左列固定 */
    .el-table .el-table__fixed {
        height:auto !important;
        bottom:0px;
    }
    /* 解决右列固定 */
    .el-table .el-table__fixed-right {
        height:auto !important;
        bottom:0px;
    }

    /*滚动条覆盖*/
    .el-table__body-wrapper{
        z-index: 2;
    }
</style>