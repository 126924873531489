<template>
    <!-- 存货选择页 -->
    <el-container>
        <!-- 表头 -->
        <el-header class="table-header" style="height:50px">
            <el-row style="margin-top:0px">
                <el-form :inline="true" :model="queryInvInline" class="demo-form-inline">
                    <el-row style="margin-top:10px">
                        <el-col :span="6">
                            <el-form-item label="存货名称：">
                                <el-input size="mini" v-model.trim="queryInvInline.cInvName" clearable placeholder="存货名称" style="width:120px"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="存货编码：">
                                <el-input size="mini" v-model.trim="queryInvInline.cInvCode" clearable placeholder="单据编码" style="width:120px"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="规格型号：">
                                <el-input size="mini" v-model.trim="queryInvInline.cInvStd" clearable placeholder="规格型号" style="width:120px"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-button type="warning" icon="el-icon-refresh" size="mini" @click="restQuery()" style="margin-top:6px">重置</el-button>
                            <el-button type="primary" icon="el-icon-search" size="mini" @click="queryInv()">查询</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </el-row>
        </el-header>
        <!-- 存货数据展示区 -->
        <el-main>
            <el-table
                :data="cInvOptions.slice((queryInvInline.pageIndex-1)*queryInvInline.pageSize,queryInvInline.pageIndex*queryInvInline.pageSize)"
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                height="300"
                border
                size="mini"
                style="width: 100%;margin-bottom: 0px;">
                <el-table-column
                type="index"
                width="auto"
                min-width="10%">
                </el-table-column>
                <el-table-column
                prop="cInvName"
                label="存货名称"
                sortable
                show-overflow-tooltip
                min-width="23%">
                </el-table-column>
                <el-table-column
                prop="cInvCode"
                label="存货编码"
                sortable
                show-overflow-tooltip
                min-width="16%">
                </el-table-column>
                <el-table-column
                prop="cInvStd"
                label="规格型号"
                sortable
                show-overflow-tooltip
                min-width="20%">
                </el-table-column>
                <el-table-column
                prop="cComUnitName"
                label="单位"
                sortable
                show-overflow-tooltip
                min-width="9%">
                </el-table-column>
                <el-table-column
                prop="stopUsing"
                label="是否停用"
                sortable
                show-overflow-tooltip
                min-width="11%">
                    <template slot-scope="scope">
                        <span>{{scope.row.stopUsing==true?'是':'否'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="11%">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" v-if="scope.row.stopUsing==false" @click="selectChange(scope.row,scope.$index)" style="margin-right:15px">
                            选择
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-row style="text-align: center;margin-top:10px">
                <el-col :span="24">
                    <!-- 分页查询区 -->
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="this.queryInvInline.pageIndex"
                        :page-sizes="this.queryInvInline.pageSizes"
                        :page-size="this.queryInvInline.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="this.queryInvInline.total">
                    </el-pagination>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>

<script>
export default {
    props:["isAddInv","offerType","venUser","offerSupDet"],
    data(){
        return{
            //查询到的存货集合
            cInvOptions:[],
            loading:false,//是否显示加载图标
            //查询存货的参数
            queryInvInline:{
                cInvName:'',//存货名称
                cInvCode:'',//存货编码
                cInvStd:'',//规格型号
                pageSizes:[10, 32, 64, 100, 200,300],//页面容量数组
                pageSize:10,//每页容量
                pageIndex:1,//当前页码
                total:0//查询结果总量
            },
            //供应商报价查询存货的参数(查询对应询价单的存货)
            queryEnquiryInv:{
                enId:'',//询价单主表标识
                cInvName:'',//存货名称
                cInvCode:'',//存货编码
                cInvStd:''//规格型号
            },
            isAddInventory:'',//是否新增存货的属性
            invIndex:'',//修改存货的询价单详情下标（存货）
            page:1,
        }
    },
    created(){
        if(this.offerType=='EM'){//如果是采购方设置询价单（获取全部采购和委外存货集合）
            //调用获取全部外购存货集合的方法
            this.getInventorys();
        }else if(this.offerType=='ED'){//如果是供应商调价（获取全部采购和委外存货集合）
            //调用获取供应商对应存货集合的方法
            this.getVenInventorys();
        }else if(this.offerType=='OF'){//如果是供应商报价（获取询价单对应的存货集合信息）
            //调动获取询价单对应的存货集合信息
            this.getOfferInventorys();
        }
        //初始化父组件传入参数
        this.setInit();
    },
    methods:{

        /**
         * 初始化父组件传入的参数
         */
        setInit(){
            this.isAddInventory=this.isAddInv;//获取是新增存货还是修改存货的属性
        },
        /**
         * 查询存货的方法
         */
        queryInv(){
            if(this.offerType=='EM'){//如果是采购方设置询价单（获取全部采购和委外存货集合）
                //调用获取全部采购及外购存货集合的方法
                this.getInventorys();
            }else if(this.offerType=='ED'){//如果是供应商调价（获取全部采购和委外存货集合）
                //调用获取供应商对应存货集合的方法
                this.getVenInventorys();
            }else if(this.offerType=='OF'){//如果是供应商报价（获取询价单对应的存货集合信息）
                //调动获取询价单对应的存货集合信息
                this.getOfferInventorys();
            }
        },
        /**
         * 重置查询存货条件
         */
        restQuery(){
            this.queryInvInline.cInvName='';//存货名称
            this.queryInvInline.cInvCode='';//存货编码
            this.queryInvInline.cInvStd='';//存货规格
        },
        /**
         * 获取全部采购及外购存货集合的方法（采购方设置询价单）
         */
        getInventorys(){
            this.cInvOptions=[];//清空之前查询到的存货信息
            this.queryInvInline.total=0;//查询到的存货数量恢复初始值
            this.loading=true;//显示加载页面
            this.$axios.post("/u8/enquiry/getInventorys",this.queryInvInline)
            .then((resp)=>{
                // this.cInvOptions=resp.data.data;//设置查询到的全部采购及外购存货集合
                //懒加载
                let tableData = resp.data.data;//查询到的存货放在临时存货数组中
                if(resp.data.success=="true"){
                    var _this = this;
                    if(tableData.length > 10){
                        this.cInvOptions = tableData .slice(0,10);
                        setTimeout(function(){
                            for(let i=11;i<tableData.length;i++){
                                _this.cInvOptions.push(tableData[i]);
                            }
                        })       
                    }else{
                        this.cInvOptions =  tableData ;
                    }
                    this.queryInvInline.total=resp.data.data.length;//查询到的存货总数
                }else{
                    this.$message(resp.data.msg);
                }
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('存货服务器出错了');
                    this.$message('存货服务器出错了');
                }
            );
        },
        /**
         * 获取供应商对应存货集合的方法（供应商调价）
         */
        getVenInventorys(){
            this.cInvOptions=[];//清空之前查询到的存货信息
            this.queryInvInline.total=0;//查询到的存货数量恢复初始值
            this.loading=true;//显示加载页面
            this.$axios.post("/u8/offerSup/getVenInventorys",this.queryInvInline)
            .then((resp)=>{
                // this.cInvOptions=resp.data.data;//设置查询到供应商对应的采购及外购存货集合
                //懒加载
                let tableData = resp.data.data;//查询到的存货放在临时存货数组中
                if(resp.data.success=="true"){
                    var _this = this;
                    if(tableData.length > 10){
                        this.cInvOptions = tableData .slice(0,10);
                        setTimeout(function(){
                            for(let i=11;i<tableData.length;i++){
                                _this.cInvOptions.push(tableData[i]);
                            }
                        })       
                    }else{
                        this.cInvOptions =  tableData ;
                    }
                    this.queryInvInline.total=resp.data.data.length;//查询到的存货总数
                }else{
                    this.$message(resp.data.msg);
                }
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('存货服务器出错了');
                    this.$message('存货服务器出错了');
                }
            );
        },
        /**
         * 获取询价单对应的存货集合信息（供应商报价）
         */
        getOfferInventorys(){
            this.queryEnquiryInv.enId=this.offerSupDet.enId,//询价单主表标识
            this.queryEnquiryInv.cInvName=this.queryInvInline.cInvName,//存货名称
            this.queryEnquiryInv.cInvCode=this.queryInvInline.cInvCode,//存货编码
            this.queryEnquiryInv.cInvStd=this.queryInvInline.cInvStd//规格型号
            this.cInvOptions=[];//清空之前查询到的存货信息
            this.queryInvInline.total=0;//查询到的存货数量恢复初始值
            this.loading=true;//显示加载页面
            this.$axios.post("/u8/enquiry/getEnquiryIvn",this.queryEnquiryInv)
            .then((resp)=>{
                // this.cInvOptions=resp.data.data;//设置查询到的询价单对应的存货集合
                //懒加载
                let tableData = resp.data.data;//查询到的存货放在临时存货数组中
                if(resp.data.success=="true"){
                    var _this = this;
                    if(tableData.length > 10){
                        this.cInvOptions = tableData .slice(0,10);
                        setTimeout(function(){
                            for(let i=11;i<tableData.length;i++){
                                _this.cInvOptions.push(tableData[i]);
                            }
                        })
                    }else{
                        this.cInvOptions =  tableData 
                    }
                    this.queryInvInline.total=resp.data.data.length;//查询到的存货总数
                }else{
                    this.$message(resp.data.msg);
                }
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('询价单子表服务器出错了');
                    this.$message('询价单子表服务器出错了');
                }
            );
        },
        /**
         * 选中要询价的存货方法
         */
        selectChange(row){
            //关闭存货选择框
            this.showInvSelectDet=false;
            this.oneSet=true;//标识有一行数据处在修改状态中为打开
            let restParam={
                row:row,//选中的存货信息
                isAddInv:this.isAddInventory//是新增存货还是修改存货
            }
            this.$emit('selInventory',restParam)//调用父组件刷新查询询价单主表的方法
        },
        //分页查询
        /**
         * 改变每页显示的数量
         */
        handleSizeChange(val) {
            this.queryInvInline.pageSize=val;//设置每页容量
            this.queryInvInline.pageIndex=1;//改总量后，初始页面改为1
        },
        /**
         * 改变开始页码
         */
        handleCurrentChange(val) {
            this.queryInvInline.pageIndex=val;//设置当前页码
        },
    }
}
</script>

<style>

</style>