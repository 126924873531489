<template>
    <!-- 采购订单详情页 -->
    <el-container id="purchase-table">
        <!-- 打开的采购订单内容 -->
        <el-header class="header-purchase" style="height:30px">
            <el-form :inline="true" :model="purchaseDet" class="demo-form-inline" id="header-table">
                <el-row class="query-row">
                    <el-col :span="4" class="query-col">
                        <el-form-item label="采购单号：">
                            {{purchaseDet.cPOID}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" class="query-col">
                        <el-form-item label="单据日期：">
                            {{purchaseDet.dPODate}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="7" class="query-col">
                        <el-form-item label="供应商：">
                            {{purchaseDet.cVenName}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="query-col">
                        <el-form-item label="是否发完货：">
                            <el-tag :type="comp==true?'success':'danger'">{{comp==true?'是':'否'}}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="query-col">
                        <el-input size="mini" placeholder="当前页excel表格名称" v-model="excelName" style="width:285px;margin-top:6px">
                            <el-button size="mini" type="primary" slot="append" icon="el-icon-download" @click="toExcel()">导出当前页excel</el-button>
                        </el-input>
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <!-- 采购订单详情内容 -->
        <el-main>
            <el-table
                :data="purchaseDetData"
                id="purDetExportExcel-table"
                v-loading="loading"
                element-loading-text="正在加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :height="purchaseDetTableHeight"
                border
                :stripe="true"
                row-key="parentId"
                :default-expand-all="isOpen"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                size="mini"
                style="width: 100%;">
                <el-table-column
                type="index"
                fixed
                show-overflow-tooltip
                width="48.8px">
                </el-table-column>
                <el-table-column
                prop="cInvCode"
                label="存货编码"
                sortable
                fixed
                show-overflow-tooltip
                width="140px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.level==1">
                            {{scope.row.cInvCode}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                prop="cInvName"
                label="存货名称"
                sortable
                fixed
                show-overflow-tooltip
                width="100px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.level==1">
                            {{scope.row.cInvName}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                prop="cInvStd"
                label="规格型号"
                sortable
                fixed
                show-overflow-tooltip
                width="205px">
                </el-table-column>
                <el-table-column
                prop="iQuantity"
                label="订单数量"
                sortable
                fixed
                show-overflow-tooltip
                width="94px">
                </el-table-column>
                <el-table-column
                prop="iTaxPrice"
                label="含税单价"
                v-if="showPrice"
                sortable
                show-overflow-tooltip
                width="94px">
                </el-table-column>
                <el-table-column
                prop="iPerTaxRate"
                label="税率"
                v-if="showPrice"
                sortable
                show-overflow-tooltip
                width="70px">
                </el-table-column>
                <el-table-column
                prop="iSum"
                label="原币价税合计"
                v-if="showPrice"
                sortable
                show-overflow-tooltip
                width="120px">
                </el-table-column>
                <el-table-column
                prop="iArrQty"
                label="累计送货"
                sortable
                show-overflow-tooltip
                width="94px">
                </el-table-column>
                <el-table-column
                prop="fPoRetQuantity"
                label="累计退货"
                sortable
                show-overflow-tooltip
                width="94px">
                </el-table-column>
                <el-table-column
                prop="cComUnitName"
                label="单位"
                sortable
                show-overflow-tooltip
                width="70px">
                </el-table-column>
                <!-- <el-table-column
                prop="thisiQty"
                label="本次数量"
                sortable
                show-overflow-tooltip
                width="130px">
                    <template slot-scope="scope">
                        <el-input-number size="mini" :disabled="!scope.row.setState" v-model="scope.row.thisiQty" controls-position="right" :min="0" :max="scope.row.iQuantity-scope.row.iArrQty+scope.row.fPoRetQuantity" style="width:100px"></el-input-number>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column
                prop="cBatch"
                label="批号"
                sortable
                show-overflow-tooltip
                width="115px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.setState">
                            <el-input size="mini" :disabled="scope.row.bInvBatch==0?true:false"  clearable v-model="scope.row.cBatch" style="width:90px" placeholder="批号"></el-input>
                        </span>
                        <span v-else>{{scope.row.cBatch}}</span>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column
                prop="dPDate"
                label="生产日期"
                sortable
                width="140px">
                    <template slot-scope="scope">
                        <el-date-picker
                            v-model="scope.row.dPDate"
                            :disabled="scope.row.setState==false?true:scope.row.bInvQuality==0?true:false"
                            size="mini"
                            align="right"
                            type="date"
                            placeholder="选择日期"
                            style="width:125px"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </template>
                </el-table-column> -->
                <el-table-column
                prop="dArriveDate"
                label="期望交期"
                sortable
                show-overflow-tooltip
                width="94px">
                </el-table-column>
                <el-table-column
                prop="cDefine37"
                label="确认交期"
                sortable
                width="146px">
                    <template slot-scope="scope">
                        <el-date-picker
                            v-model="scope.row.cDefine37"
                            :disabled="scope.row.setState==false"
                            v-if="scope.row.level==1"
                            size="mini"
                            align="right"
                            type="date"
                            placeholder="选择日期"
                            style="width:125px;"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                prop="cbMemo"
                label="备注"
                sortable
                show-overflow-tooltip
                width="115px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.setState">
                            <el-input size="mini" :disabled="!scope.row.setState" clearable v-model="scope.row.cbMemo" style="width:90px" placeholder="备注" ></el-input>
                        </span>
                        <span v-else>{{scope.row.cbMemo}}</span>
                    </template>
                </el-table-column> -->
                <el-table-column label="操作" width="270px">
                    <template slot-scope="scope">
                        <el-button type="primary" v-if="scope.row.cDefine37==null&&scope.row.setState==false&&venUser.iAdmin!=1&&(scope.row.iArrQty<scope.row.iQuantity)" icon="el-icon-edit" size="mini" @click="upChange(scope.row,scope.$index)" style="margin-right:0px">
                            修改
                        </el-button>
                        <el-tooltip v-if="scope.row.setState==true" class="item" effect="dark" content="只能修改一次，修改后无法再修改" placement="top">
                            <el-button type="primary" v-if="scope.row.setState==true" icon="el-icon-finished" size="mini" @click="confirmChange(scope.row,scope.$index)" style="margin-right:0px">
                                确认
                            </el-button>
                        </el-tooltip>
                        <el-button size="mini" type="danger" icon="el-icon-refresh" v-if="scope.row.setState" slot="reference" @click="restChange(scope.row,scope.$index)">
                            取消
                        </el-button>
                        <!-- 融贤专版只有采购方可以拆分 -->
                        <el-button size="mini" type="success" icon="el-icon-tickets" v-if="venUser.iAdmin==1&&scope.row.level==1" slot="reference" @click="openSplitPurchase(scope.row,scope.$index)">
                            拆分
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 供货计划页 -->
            <SplitPurchase @restQueryPurchaseDet="restQueryPurchaseDet" @closeSplitPurchaseDet="closeSplitPurchaseDet" :showSplitPurchaseDet="showSplitPurchaseDet" :activeSplitcInv="activeSplitcInv" v-if="isRefreshSplit"></SplitPurchase>
        </el-main>
    </el-container>
</template>

<script>
import SplitPurchase from './SplitPurchasePage.vue';//引入供货计划页
export default {
    props:["purchase"],
    data(){
        return{
            purchaseDet:{},//要查询采购订单详情的采购订单
            txtcDefine14:'',//快递单号
            txtcMemo:'',//送货备注
            deliverTime:'',//送货日期
            purchaseDetData:[],//采购订单的详情数据
            oneSet:false,//是否有一行存货数据正在被修改的属性
            //日历快捷键
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                    picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', date);
                    }
                }]
            },
            loading:false,//是否显示加载界面的属性
            excelName:'',//导出excel表格的名称
            venUser:{},//登录的供应商对象
            showSplitPurchaseDet:false,//是否显示供货计划页的属性
            isRefreshSplit:false,//是否强制刷新供货计划页的属性
            activeSplitcInv:{},//选中要拆分的采购订单存货
            isOpen:false,//是否展开所有二级明细的属性
            comp:false,//标识是否已经发完货
            showPrice:true,//是否显示单价的属性
        }
    },
    computed:{
        //计算附件数据区域自适应高度
        purchaseDetTableHeight(){
            return document.documentElement.clientHeight-350;
        }
    },
    watch:{
    
    },
    created(){
        this.purchaseDet=this.purchase;//设置要查询采购订单详情的采购订单
        //调用设置查询中供应商的参数
        this.setVenUser();
        //根据采购订单主表标识查找采购订单详情
        this.getPurchaseDet();
    },
    methods:{
        /**
         * 检查是否已经发完货
         */
        checkComplete(){
            let isComplete=true;//标识已经发完货
            for (let i = 0; i < this.purchaseDetData.length; i++) {
                if(this.purchaseDetData[i].iArrQty<this.purchaseDetData[i].iQuantity){//如果累计发货数量小于订单数量
                    isComplete=false;//标识没有发完货
                }
            }
            this.comp=isComplete;
        },
        /**
         * 设置查询中供应商的参数
         */
        setVenUser(){
            //获取上级父组件传进来的参数并赋值
            this.venUser=this.$store.state.UserToken;//路由中获取到的是json字符串，要转化为json对象
            console.log('登录用户是：',this.venUser);
            if (this.venUser.iPrice==1) {//如果有价格权限
                this.showPrice=true;//显示价格
            }else{//如果没有价格权限
                this.showPrice=false;//不显示价格
            }
            console.log('最终价格权限是：',this.showPrice);
        },
        /**
         * 根据采购订单主表标识查找采购订单详情
         */
        getPurchaseDet(){
            //查询前清空之前已经查询到的数据
            this.purchaseDetData=[];
            let queryPurchaseDetParam={
                poId:this.purchaseDet.poId//采购订单主表标识
            }
            this.loading=true;//打开加载界面
            this.$axios.post("/u8/purchase/getPurchaseDet",queryPurchaseDetParam)
            .then((resp)=>{
                if(resp.data.success=="true"){
                    this.purchaseDetData=resp.data.data;//设置查询到的采购订单详情
                    //调用检查是否发完货
                    this.checkComplete();
                }else{
                    this.$message({
                        showClose:true,
                        message:resp.data.msg,
                        type:'warning',
                    });
                }
                this.loading=false;//关闭加载界面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('采购订单服务器出错了');
                    this.$message('采购订单服务器出错了');
                }
            );
        },
        /**
         * 设置该行为修改状态
         */
        upChange(row, index){
            if(this.oneSet==true){
                this.$message('请先确认当前编辑项');
            }else{
                row.setState=true;
                this.oneSet=true;//设置有一行存货在处于修改状态
            }
        },
        /**
         * 重置当前行采购订单详情行数据
         */
        restChange(row, index){
            row.cDefine37=null;//确认交期
            row.setState=false;//设置当前行修改状态为关闭
            this.oneSet=false;//设置有一行存货在处于修改状态为关闭
        },
        /**
         * 确认修改当行采购订单详情行数据
         */
        confirmChange(row,index){
            if(row.cDefine37==null){
                return this.$message('请选择确认交期日期');
            }
            //修改确认交期
            this.$axios.post("/u8/purchase/updPurcDefine37",row)
            .then((resp)=>{
                this.$message(resp.data.msg);
                if(resp.data.success=="true"){
                    row.setState=false;//设置当前行的修改状态为关闭
                    this.oneSet=false;//设置存货处在修改状态为关闭
                    //根据采购订单主表标识查找采购订单详情
                    this.getPurchaseDet();
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('发货订单服务器出错了');
                    this.$message('发货订单服务器出错了');
                }
            );
        },
        /**
         * 发货的方法
         */
        deliverGoods(){
            if(this.oneSet==true){
                this.$message('请先确认当前编辑项');
            }else{
                if(this.deliverTime==null||this.deliverTime==''){
                    return this.$message('请选择发货日期');
                }
                let total='';//所有存货的发货总量
                for (let i = 0; i < this.purchaseDetData.length; i++) {
                    total=total+this.purchaseDetData[i].thisiQty;//计算所有存货的本次数量合计数
                }
                if(total==0){//如果合计数为0，即都没有设置本次数量
                    return this.$message('所有存货的本次数量都为空');
                }
                for (let j = 0; j < this.purchaseDetData.length; j++) {
                    if((this.purchaseDetData[j].thisiQty!=0||this.purchaseDetData[j].thisiQty!='')&&this.purchaseDetData[j].firmArriveDate==null){
                        return this.$message('有本次数量的请确认交期');
                    }
                }
                let deliverParam={
                    txtcDefine14:this.txtcDefine14,//快递单号
                    txtcMemo:this.txtcMemo,//送货备注
                    deliverTime:this.deliverTime,//发货日期
                    purchaseDet:this.purchaseDet,//采购订单主表内容
                    deliverDetData:this.purchaseDetData//发货详情数据
                }
                this.$axios.post("/u8/purchase/deliverGoods",deliverParam)
                .then((resp)=>{
                    this.$message(resp.data.msg);
                    if(resp.data.success=="true"){//如果发货成功
                        this.$emit('showDetails',false)//向父组件传值关闭采购订单详情窗口
                    }
                })
                .catch(
                    function(error){
                        console.log(error);
                        console.log('发货订单服务器出错了');
                        this.$message('发货订单服务器出错了');
                    }
                );
            }
        },
        /**
         * 当前页导出excel文档
         */
        toExcel(){
            if(this.excelName==''){
                this.$message('请输入要导出excel表格的名字');
            }else{
                let fix = document.querySelector('.el-table__fixed');
                let tables = document.getElementById("purDetExportExcel-table");
                let table_book;
                if(fix){//判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
                    table_book = this.$XLSX.utils.table_to_book(tables.removeChild(fix),{raw:true});
                    tables.appendChild(fix);
                }else{
                    table_book = this.$XLSX.utils.table_to_book(tables,{raw:true});
                }
                var table_write = this.$XLSX.write(table_book, {
                    bookType: "xlsx",
                    bookSST: true,
                    type: "array"
                });
                let excelNameStr=this.excelName+'.xlsx'
                try {
                    this.$FileSaver.saveAs(
                    new Blob([table_write], { type: "application/octet-stream" }),
                    excelNameStr
                    );
                } catch (e) {
                    if (typeof console !== "undefined") console.log(e, table_write);
                }
                this.excelName='';//表格名设置为空
                return table_write;
            }
        },
        /**
         * 打开供货计划页的方法
         */
        openSplitPurchase(row){
            this.isRefreshSplit=false;
            this.$nextTick(()=>{
                this.isRefreshSplit=true;
            });
            this.showSplitPurchaseDet=true;//打开供货计划页
            this.activeSplitcInv=row;//设置要供货计划的采购订单存货
        },
        /**
         * 关闭供货计划页
         */
        closeSplitPurchaseDet(data){
            this.showSplitPurchaseDet=data;//关闭供货计划页
        },
        /**
         * 子组件调用父组件重新查询采购订单详情的方法
         */
        restQueryPurchaseDet(){
            //重新调用根据采购订单主表标识查找采购订单详情
            this.getPurchaseDet();
        },
    },
    components:{
        SplitPurchase,//拆分采购订单页
    }
}
</script>

<style>
    .header-purchase{
        /* background-color: chartreuse; */
    }

    /* 解决标题和内容单元格错位 */
    body .el-table th.gutter {
        display: table-cell!important;
    }
    
    /* 解决表格固定列后覆盖滚动条 */
    /* 解决左列固定 */
    .el-table .el-table__fixed {
        height:auto !important;
        bottom:0px;
    }
    /* 解决右列固定 */
    .el-table .el-table__fixed-right {
        height:auto !important;
        bottom:0px;
    }

    /*滚动条覆盖*/
    .el-table__body-wrapper{
        z-index: 2;
    }

    


</style>