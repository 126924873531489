<template>
    <!-- 报价单详情（采购方） -->
    <el-container>
        <!-- 表头 -->
        <el-header class="table-header" style="height:50px">
            <el-row style="text-align: left;padding-left:17px">
                <el-col :span="24">
                    <el-popconfirm v-if="venUser.iAdmin==1&&offerDet.offerType=='ED'&&offerDet.isExamine==1" title="审核后不能修改，确认审核？" @confirm="confirmOffer()">
                        <el-button size="mini" type="primary" icon="el-icon-s-claim" slot="reference" >确认审核</el-button>
                    </el-popconfirm>
                    <el-popconfirm v-if="venUser.iAdmin==1&&offerDet.offerType=='ED'&&offerDet.isExamine==1" title="确认拒绝调价？" @confirm="refuseConfirm()">
                        <el-button size="mini" type="danger" icon="el-icon-document-delete" slot="reference" style="margin-left:10px">拒绝调价</el-button>
                    </el-popconfirm>
                </el-col>
            </el-row>
            <el-row style="margin-top:0px">
                <el-form :inline="true" :model="offerDet" class="demo-form-inline">
                    <el-row class="query-row">
                        <el-col :span="4" class="query-col">
                            <el-form-item label="单据编码：" style="margin-left:10px">
                                {{offerDet.inquiryCode}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="query-col">
                            <el-form-item label="单据日期：">
                                {{offerDet.inquiryDate}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="query-col">
                            <el-form-item v-if="offerDet.offerType=='EM'" label="业务员：">
                                {{offerDet.cPersonName}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="query-col">
                            <el-form-item label="报价单类型：">
                                <el-tag v-if="offerDet.offerType!=null" :type="offerDet.offerType=='EM'?'success':offerDet.offerType=='ED'?'':'warning'">{{offerDet.offerType=='EM'?'报价':offerDet.offerType=='ED'?'调价':''}}</el-tag>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3" class="query-col">
                            <el-form-item label="审核状态：">
                                <!-- 询价单的审核状态 -->
                                <el-tag v-if="offerDet.offerType=='EM'" :type="offerDet.isExamine==1?'success':'danger'">{{offerDet.isExamine==1?'已审核':'未审核'}}</el-tag>
                                <!-- 调价单的审核状态 -->
                                <el-tag v-if="offerDet.offerType=='ED'" :type="offerDet.isExamine==1?'warning':offerDet.isExamine==2?'':'danger'">{{offerDet.isExamine==1?'未审核':offerDet.isExamine==2?'已审核':'供应商未审核'}}</el-tag>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" class="query-col" style="text-align: center;">
                            <el-form-item label="供应商：">
                                {{offerDet.cVenName}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-row>
            <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                width="30%"
                append-to-body
                style="margin-top:100px">
                <span>有报价已经存在供应商存货价格表中，是否需要覆盖？</span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confirmCoverage()">确 定</el-button>
                </span>
            </el-dialog>
        </el-header>
        <!-- 报价单详情内容 -->
        <el-main>
            <el-table
                :data="offerData"
                height="250"
                border
                size="mini"
                style="width: 100%;margin-bottom: 0px;">
                <el-table-column
                type="index"
                min-width="5%">
                </el-table-column>
                <el-table-column
                prop="cInvName"
                label="存货名称"
                sortable
                show-overflow-tooltip
                min-width="9%">
                </el-table-column>
                <el-table-column
                prop="cInvCode"
                label="存货编码"
                sortable
                show-overflow-tooltip
                min-width="9%">
                </el-table-column>
                <el-table-column
                prop="cInvStd"
                label="规格型号"
                sortable
                show-overflow-tooltip
                min-width="9%">
                </el-table-column>
                <el-table-column
                prop="cComUnitName"
                label="单位"
                sortable
                show-overflow-tooltip
                min-width="4%">
                </el-table-column>
                <el-table-column
                prop="plaQuantity"
                label="计划数量"
                sortable
                show-overflow-tooltip
                min-width="8%">
                </el-table-column>
                <el-table-column
                prop="demDate"
                label="需求日期"
                sortable
                show-overflow-tooltip
                min-width="8%">
                </el-table-column>
                <el-table-column
                prop="lowQty"
                label="数量下限"
                sortable
                show-overflow-tooltip
                min-width="8%">
                </el-table-column>
                <el-table-column
                prop="ceiQty"
                label="数量上限"
                sortable
                show-overflow-tooltip
                min-width="8%">
                </el-table-column><el-table-column
                prop="iPerTaxRate"
                label="税率"
                sortable
                show-overflow-tooltip
                min-width="4%">
                </el-table-column>
                <el-table-column
                prop="iTaxPrice"
                label="含税单价"
                sortable
                show-overflow-tooltip
                min-width="6%">
                </el-table-column>
                <el-table-column
                prop="noiTaxPrice"
                label="不含税单价"
                sortable
                show-overflow-tooltip
                min-width="6%">
                </el-table-column>
                <el-table-column
                prop="dEnableDate"
                label="生效日期"
                sortable
                show-overflow-tooltip
                min-width="8%">
                </el-table-column>
                <el-table-column
                prop="deadline"
                label="失效日期"
                sortable
                show-overflow-tooltip
                min-width="8%">
                </el-table-column>
            </el-table>
        </el-main>
    </el-container>
</template>

<script>
export default {
    props:["offer","venUser"],
    created(){
        this.offerDet.id=this.offer.id;//报价单主表id
        this.offerDet.offerId=this.offer.offerId;//报价单主表标识
        this.offerDet.inquiryCode=this.offer.inquiryCode;//设置选中的报价单对象的单据编码
        this.offerDet.inquiryDate=this.offer.inquiryDate;//设置选中的报价单对象的日期
        this.offerDet.cPersonCode=this.offer.cPersonCode;//设置选中的报价单对象的业务员编码
        this.offerDet.cPersonName=this.offer.cPersonName;//设置选中的报价单对象的业务员名称
        this.offerDet.offerType=this.offer.offerType;//设置选中的报价单对象的报价单类型
        this.offerDet.cVenCode=this.offer.cVenCode;//设置选中的报价单对象的供应商编码
        this.offerDet.cVenName=this.offer.cVenName;//设置选中的报价单对象的供应商
        this.offerDet.isExamine=this.offer.isExamine;//设置选中的报价单对象的审核状态
        //根据报价单主表标识查找报价详情
        this.getOfferDet();
    },
    data(){
        return{
            loading:false,//是否显示加载图标
            //选中要查看详情的报价单对象
            offerDet:{
                id:'',//报价单主表id
                offerId:'',//报价单主表标识
                inquiryCode:'',//单据编码
                inquiryDate:'',//日期
                cPersonCode:'',//业务员编码
                cPersonName:'',//业务员名称
                offerType:'',//报价单类型
                cVenCode:'',//供应商编码
                cVenName:'',//供应商
                isExamine:'',//审核状态
            },
            //报价单详情数据
            offerData:[],
            dialogVisible:false,//提示报价已经存在价格本中是否覆盖的提示框显示属性
        }
    },
    methods:{
        /**
         * 根据报价单主表标识和报价单号查询报价单详情
         */
        getOfferDet(){
            this.loading=true;//显示加载页面
            this.offerData=[];//查询之前清空之前查询到的报价单详情数据
            let queryOfferDetParam={
                offerId:this.offerDet.offerId,//报价单主表标识
            }
            this.$axios.post("/u8/offer/getOfferDetails",queryOfferDetParam)
            .then((resp)=>{
                this.offerData=resp.data.data;//设置查询到的报价单详情
                this.loading=false;//关闭加载页面
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('报价单详情服务器出错了');
                    this.$message('报价单详情服务器出错了');
                }
            );
        },
        /**
         * 审核询价单的方法
         */
        confirmOffer(){
            //获取是否有报价已经存在供应商存货价格表中
            this.checkInvPrice(this.offerDet);
        },
        /**
         * 检查供应商存货价格表是否有重复报价的方法
         */
        checkInvPrice(offerDet){
            //打开检查是否有存货存在价格本的加载页面
            const loading = this.$loading({
                lock: true,
                text: '正在检查是否有存货存在价格本中，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$axios.post("/u8/venInvPrice/checkInvPrice",offerDet)
            .then((resp)=>{
                //关闭检查存货是否存在价格本中的加载页面
                loading.close();
                let isRepeat=resp.data.success;//获取价格本是否有重复的标识
                if(isRepeat=='false'){//如果没有报价单重复
                    //调用改变调价单状态未审核的方法
                    this.updOffer(offerDet,isRepeat);
                }else{//如果有调价单已经重复了
                    this.dialogVisible=true;//打开提示调价已经存在价格本中的提示框
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('供应商价格本服务器出错了');
                    this.$message('供应商价格本服务器出错了');
                }
            );
        },
        /**
         * 改变调价单状态为审核并加入到价格本中的方法
         */
        updOffer(offerDet,isRepeat){
            let examineOfferParam={//审核调价单的参数
                offer:offerDet,//要审核的调价单主表对象
                isRepeat:isRepeat//价格本是否有重复的标识
            }
            this.$axios.post("/u8/offer/toExaOffer",examineOfferParam)
            .then((resp)=>{
                this.$message(resp.data.msg);
                if(resp.data.success=="true"){
                    this.offerDet.isExamine=2;//该调价单设置为已经审核状态
                    if(this.dialogVisible==true){//如果提示已经有报价存在价格本中的提示框是打开状态
                        this.dialogVisible=false;//关闭提示已经有报价存在价格本中的提示框
                    }
                    this.$emit('updOffer')//调用父组件刷新查询报价单主表的方法
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('供应商调价单服务器出错了');
                    this.$message('供应商调价单服务器出错了');
                }
            );
        },
        /**
         * 确认覆盖价格本的方法
         */
        confirmCoverage(){
            let offerDet=this.offerDet;//要审核的调价单主表对象
            let isRepeat='true';//价格本是否有重复的标识
            //调用改变调价单状态未审核的方法
            this.updOffer(offerDet,isRepeat);
        },
        /**
         * 拒绝调价的方法
         */
        refuseConfirm(){
            console.log('拒绝调价的是：',this.offerDet);
            this.$axios.post("/u8/offer/refuseConfirm",this.offerDet)
            .then((resp)=>{
                this.$message(resp.data.msg);
                if(resp.data.success=="true"){
                    this.$emit('updOffer')//调用父组件刷新查询报价单主表的方法
                }
            })
            .catch(
                function(error){
                    console.log(error);
                    console.log('供应商调价单服务器出错了');
                    this.$message('供应商调价单服务器出错了');
                }
            );
        }
    }
}
</script>

<style>

</style>